import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import {} from "../utils/apis/all";

import { NavLink } from "react-router-dom";

const Folder = ({ data, modalView, onEdit, loanHandler }: any) => {
	const [totalProducts, setTotalProducts] = useState(data.Products.length);

	const [loanStatus, setLoanStatus] = useState(false);

	let loanRef: any;

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<div className="products-container">
			<Row className="products-container-header">
				<Col sm={10} className="left">
					<img
						src={process.env.PUBLIC_URL + "/imgs/folder_simple.png"}
						alt=""
					/>

					<NavLink className="bold" to={"/folder/" + data.id}>
						<span>{data.title}</span>
					</NavLink>
				</Col>
				<Col sm={2} className="right">
					<img
						src={process.env.PUBLIC_URL + "/imgs/edit.png"}
						alt=""
						style={{ marginRight: 10 }}
						data-for="default"
						data-tip="Edit"
						onClick={() => onEdit(data.id)}
					/>
					<span
						ref={(ref) => (loanRef = ref)}
						data-tip
						data-for="loans"
						data-event="blur"
					>
						<img
							src={process.env.PUBLIC_URL + "/imgs/share.png"}
							alt=""
							data-for="default"
							data-tip="Share"
							style={{ width: 20, marginRight: 15, cursor: "pointer" }}
							onClick={() => {
								//ReactTooltip.show(loanRef);

								loanHandler(loanRef, data.id, loanStatus);
								setLoanStatus(!loanStatus);
							}}
						/>
					</span>
				</Col>
			</Row>
			<Row className="products-container-body">
				{data.Products.map((product: any, index: any) => {
					if (index < 11)
						return (
							<Col key={product.id} sm={1} className="container-product">
								<img
									src={product.Medias?.length ? product.Medias[0].url : ""}
									alt=""
									onClick={() => modalView(product.id)}
								/>
							</Col>
						);
					else if (index == 11)
						return (
							<Col sm={1} key={0} className="container-product-other">
								<div>
									<span>{"+ " + (totalProducts - index)}</span>
								</div>
							</Col>
						);
				})}
			</Row>
		</div>
	);
};

export default Folder;
