import { useState } from "react";
import AuthService from "../utils/auth.service";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { NavLink } from "react-router-dom";

import Input from "./common/input";
import Button from "./common/button";
import Select from "./common/select";

import Navbar from "./navbar";

interface Props {
	setSignup: any;
}

const Login = ({ setSignup }: Props) => {
	const [user, setUser] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		companyString: "",
		departmentString: "",
	});
	const [message, setMessage] = useState("");
	const history = useHistory();

	const [emailClasses, setEmailClasses] = useState("");

	function ValidateEmail(mail: string) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}

	const handleSignup = async (e: any) => {
		if (!ValidateEmail(user.email)) {
			setEmailClasses("error");
			return;
		} else {
			setEmailClasses("");
		}

		AuthService.signup({ user }).then(
			(res) => {
				setSignup(false);
				history.push("/login");
			},
			(error) => {
				setMessage(
					"Signup failed! Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character."
				);
				//console.log("errore: ", error.stack);
				console.table(error);
			}
		);
	};

	const handleInput = (e: any) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	//console.log(user);

	return (
		<div className="login">
			<Navbar />
			<div className="login-container">
				<img
					src={process.env.PUBLIC_URL + "/slides/b_16.jpg"}
					id="login-background"
				/>
				<Row>
					<Col sm={8}></Col>
					<Col sm={4} className="login-form-column">
						<div className="signup-form">
							<span className="text-extra-large">Signup</span>
							{message !== "" && <p className="login-error">{message}</p>}
							<Input
								name="firstName"
								placeholder="Name"
								onChange={handleInput}
								icon="user"
							/>
							<Input
								name="lastName"
								placeholder="Surname"
								onChange={handleInput}
								icon="user"
							/>
							<Input
								type="email"
								name="email"
								placeholder="Email"
								onChange={handleInput}
								icon="user"
								classes={emailClasses}
							/>
							<Input
								name="password"
								placeholder="Password"
								type="password"
								onChange={handleInput}
								icon="key"
							/>
							<Input
								name="companyString"
								placeholder="Company"
								onChange={handleInput}
								icon="home"
							/>
							<Input
								name="departmentString"
								placeholder="Department"
								onChange={handleInput}
								icon="tag"
							/>

							<Button
								name="confirm"
								label="SIGNUP"
								classes="btn-empty btn-full"
								onClick={handleSignup}
							/>

							<span className="text-small">
								If you already have an account, please{" "}
								<NavLink
									className="bold"
									to="/login"
									onClick={() => setSignup(false)}
								>
									LOGIN
								</NavLink>
							</span>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Login;
