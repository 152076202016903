import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Slide } from "react-slideshow-image";
import AuthService from "../utils/auth.service";

import defaultData from "../data.json";
import {
	getSubCategoriesByCategory,
	getProductsByCategory,
	getProductsBySubcategory,
} from "../utils/apis/all";

import MyFolders2 from "./myFolders2";
import MyLoans2 from "./myLoans2";
import Settings from "./settings";

import MainFilter from "./filters/mainFilter";
import TagFilter from "./filters/tagFilter";
import Breadcrumbs from "./breadcrumbs";
import Users from "./users";
import BackendProducts from "./backendProducts";
import Pagination from "./pagination";

import translations from "../translations.json";

const slideImages = [
	{
		url: process.env.PUBLIC_URL + "/slides/b_10.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_11.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_12.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_13.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_14.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_15.jpg",
		caption: "",
	},
	{
		url: process.env.PUBLIC_URL + "/slides/b_16.jpg",
		caption: "",
	},
];

const HomeBackend = () => {
	const history = useHistory();
	const { idActiveFilter, idActiveTag }: any = useParams();

	const [language, setLanguage]: any = useState("en");

	const [user, setUser] = useState({
		id: 0,
		role: 0,
	});

	const [filters, setFilters] = useState([
		{
			id: 1,
			label:
				translations["cat1Backend"][
					language as keyof typeof translations["cat1Backend"]
				],
			image: "shoes/users.png",
		},
		{
			id: 2,
			label:
				translations["cat2Backend"][
					language as keyof typeof translations["cat2Backend"]
				],
			image: "shoes/records.png",
		},
		{
			id: 3,
			label:
				translations["cat3Backend"][
					language as keyof typeof translations["cat3Backend"]
				],
			image: "shoes/folders.png",
		},
		{
			id: 4,
			label:
				translations["cat4Backend"][
					language as keyof typeof translations["cat4Backend"]
				],
			image: "shoes/loans.png",
		},
		{
			id: 5,
			label:
				translations["cat5Backend"][
					language as keyof typeof translations["cat5Backend"]
				],
			image: "shoes/settings.png",
		},
	]);

	const [products, setProducts] = useState();
	const [activeFilter, setActiveFilter] = useState(
		idActiveFilter ? idActiveFilter : 0
	);
	const [tags, setTags] = useState([
		{
			id: 1,
			label:
				translations["brand"][language as keyof typeof translations["brand"]],
			label_it:
				translations["brand"][language as keyof typeof translations["brand"]],
		},
		{
			id: 21,
			label:
				translations["year"][language as keyof typeof translations["year"]],
			label_it:
				translations["year"][language as keyof typeof translations["year"]],
		},
		{
			id: 2,
			label:
				translations["season"][language as keyof typeof translations["season"]],
			label_it:
				translations["season"][language as keyof typeof translations["season"]],
		},
		{
			id: 3,
			label:
				translations["collection"][
					language as keyof typeof translations["collection"]
				],
			labe_it:
				translations["collection"][
					language as keyof typeof translations["collection"]
				],
		},
		{
			id: 10,
			label:
				translations["gender"][language as keyof typeof translations["gender"]],
			label_it:
				translations["gender"][language as keyof typeof translations["gender"]],
		},
		{
			id: 17,
			label:
				translations["subCategory"][
					language as keyof typeof translations["subCategory"]
				],
			label_it:
				translations["subCategory"][
					language as keyof typeof translations["subCategory"]
				],
		},
		{
			id: 22,
			label:
				translations["modelDetail"][
					language as keyof typeof translations["modelDetail"]
				],
			label_it:
				translations["modelDetail"][
					language as keyof typeof translations["modelDetail"]
				],
		},
		{
			id: 8,
			label:
				translations["lineTheme"][
					language as keyof typeof translations["lineTheme"]
				],
			label_it:
				translations["lineTheme"][
					language as keyof typeof translations["lineTheme"]
				],
		},
		{
			id: 14,
			label:
				translations["material"][
					language as keyof typeof translations["material"]
				],
			label_it:
				translations["material"][
					language as keyof typeof translations["material"]
				],
		},
		{
			id: 5,
			label:
				translations["color"][language as keyof typeof translations["color"]],
			label_it:
				translations["color"][language as keyof typeof translations["color"]],
		},
		{
			id: 13,
			label:
				translations["processing"][
					language as keyof typeof translations["processing"]
				],
			label_it:
				translations["processing"][
					language as keyof typeof translations["processing"]
				],
		},
	]);

	const [tags2, setTags2] = useState([
		{
			id: 19,
			label:
				translations["decorativeMotif"][
					language as keyof typeof translations["decorativeMotif"]
				],
			label_it:
				translations["decorativeMotif"][
					language as keyof typeof translations["decorativeMotif"]
				],
		},
		{
			id: 9,
			label:
				translations["embellishment"][
					language as keyof typeof translations["embellishment"]
				],
			label_it:
				translations["embellishment"][
					language as keyof typeof translations["embellishment"]
				],
		},
		{
			id: 11,
			label:
				translations["heelHeight"][
					language as keyof typeof translations["heelHeight"]
				],
			label_it:
				translations["heelHeight"][
					language as keyof typeof translations["heelHeight"]
				],
		},
		{
			id: 12,
			label:
				translations["heelType"][
					language as keyof typeof translations["heelType"]
				],
			label_it:
				translations["heelType"][
					language as keyof typeof translations["heelType"]
				],
		},
		{
			id: 16,
			label:
				translations["size"][language as keyof typeof translations["size"]],
			label_it:
				translations["size"][language as keyof typeof translations["size"]],
		},
		{
			id: 18,
			label:
				translations["quantity"][
					language as keyof typeof translations["quantity"]
				],
			label_it:
				translations["quantity"][
					language as keyof typeof translations["quantity"]
				],
		},
		{
			id: 4,
			label:
				translations["acquisitionType"][
					language as keyof typeof translations["acquisitionType"]
				],
			label_it:
				translations["acquisitionType"][
					language as keyof typeof translations["acquisitionType"]
				],
		},
		{
			id: 7,
			label:
				translations["condition"][
					language as keyof typeof translations["condition"]
				],
			label_it:
				translations["condition"][
					language as keyof typeof translations["condition"]
				],
		},
		{
			id: 6,
			label:
				translations["collocation"][
					language as keyof typeof translations["collocation"]
				],
			label_it:
				translations["collocation"][
					language as keyof typeof translations["collocation"]
				],
		},
		{
			id: 20,
			label:
				translations["codiceArticolo"][
					language as keyof typeof translations["codiceArticolo"]
				],
			label_it:
				translations["codiceArticolo"][
					language as keyof typeof translations["codiceArticolo"]
				],
		},
		/* { id: 0, label: "CODICE VARIANTE" }, */
	]);

	const [activeTag, setActiveTag] = useState(0);
	const [crumbs, setCrumbs] = useState([{ name: "", path: "" }]);

	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState({
		path: "productCode",
		order: "ASC",
	});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		//console.log(user.role);
		if (user.role != 3)
			setFilters([
				{
					id: 1,
					label:
						translations["cat1Backend"][
							language as keyof typeof translations["cat1Backend"]
						],
					image: "shoes/users.png",
				},
				{
					id: 2,
					label:
						translations["cat2Backend"][
							language as keyof typeof translations["cat2Backend"]
						],
					image: "shoes/records.png",
				},
				{
					id: 3,
					label:
						translations["cat3Backend"][
							language as keyof typeof translations["cat3Backend"]
						],
					image: "shoes/folders.png",
				},
				{
					id: 4,
					label:
						translations["cat4Backend"][
							language as keyof typeof translations["cat4Backend"]
						],
					image: "shoes/loans.png",
				},
				{
					id: 5,
					label:
						translations["cat5Backend"][
							language as keyof typeof translations["cat5Backend"]
						],
					image: "shoes/settings.png",
				},
			]);
		else
			setFilters([
				{
					id: 2,
					label:
						translations["cat2Backend"][
							language as keyof typeof translations["cat2Backend"]
						],
					image: "shoes/records.png",
				},
				{
					id: 3,
					label:
						translations["cat3Backend"][
							language as keyof typeof translations["cat3Backend"]
						],
					image: "shoes/folders.png",
				},
				{
					id: 4,
					label:
						translations["cat4Backend"][
							language as keyof typeof translations["cat4Backend"]
						],
					image: "shoes/loans.png",
				},
				{
					id: 5,
					label:
						translations["cat5Backend"][
							language as keyof typeof translations["cat5Backend"]
						],
					image: "shoes/settings.png",
				},
			]);

		setTags([
			{
				id: 1,
				label:
					translations["brand"][language as keyof typeof translations["brand"]],
				label_it:
					translations["brand"][language as keyof typeof translations["brand"]],
			},
			{
				id: 21,
				label:
					translations["year"][language as keyof typeof translations["year"]],
				label_it:
					translations["year"][language as keyof typeof translations["year"]],
			},
			{
				id: 2,
				label:
					translations["season"][
						language as keyof typeof translations["season"]
					],
				label_it:
					translations["season"][
						language as keyof typeof translations["season"]
					],
			},
			{
				id: 3,
				label:
					translations["collection"][
						language as keyof typeof translations["collection"]
					],
				label_it:
					translations["collection"][
						language as keyof typeof translations["collection"]
					],
			},
			{
				id: 10,
				label:
					translations["gender"][
						language as keyof typeof translations["gender"]
					],
				label_it:
					translations["gender"][
						language as keyof typeof translations["gender"]
					],
			},
			{
				id: 17,
				label:
					translations["subCategory"][
						language as keyof typeof translations["subCategory"]
					],
				label_it:
					translations["subCategory"][
						language as keyof typeof translations["subCategory"]
					],
			},
			{
				id: 22,
				label:
					translations["modelDetail"][
						language as keyof typeof translations["modelDetail"]
					],
				label_it:
					translations["modelDetail"][
						language as keyof typeof translations["modelDetail"]
					],
			},
			{
				id: 8,
				label:
					translations["lineTheme"][
						language as keyof typeof translations["lineTheme"]
					],
				label_it:
					translations["lineTheme"][
						language as keyof typeof translations["lineTheme"]
					],
			},
			{
				id: 14,
				label:
					translations["material"][
						language as keyof typeof translations["material"]
					],
				label_it:
					translations["material"][
						language as keyof typeof translations["material"]
					],
			},
			{
				id: 5,
				label:
					translations["color"][language as keyof typeof translations["color"]],
				label_it:
					translations["color"][language as keyof typeof translations["color"]],
			},
			{
				id: 13,
				label:
					translations["processing"][
						language as keyof typeof translations["processing"]
					],
				label_it:
					translations["processing"][
						language as keyof typeof translations["processing"]
					],
			},
		]);

		setTags2([
			{
				id: 19,
				label:
					translations["decorativeMotif"][
						language as keyof typeof translations["decorativeMotif"]
					],
				label_it:
					translations["decorativeMotif"][
						language as keyof typeof translations["decorativeMotif"]
					],
			},
			{
				id: 9,
				label:
					translations["embellishment"][
						language as keyof typeof translations["embellishment"]
					],
				label_it:
					translations["embellishment"][
						language as keyof typeof translations["embellishment"]
					],
			},
			{
				id: 11,
				label:
					translations["heelHeight"][
						language as keyof typeof translations["heelHeight"]
					],
				label_it:
					translations["heelHeight"][
						language as keyof typeof translations["heelHeight"]
					],
			},
			{
				id: 12,
				label:
					translations["heelType"][
						language as keyof typeof translations["heelType"]
					],
				label_it:
					translations["heelType"][
						language as keyof typeof translations["heelType"]
					],
			},
			{
				id: 16,
				label:
					translations["size"][language as keyof typeof translations["size"]],
				label_it:
					translations["size"][language as keyof typeof translations["size"]],
			},
			{
				id: 18,
				label:
					translations["quantity"][
						language as keyof typeof translations["quantity"]
					],
				label_it:
					translations["quantity"][
						language as keyof typeof translations["quantity"]
					],
			},
			{
				id: 4,
				label:
					translations["acquisitionType"][
						language as keyof typeof translations["acquisitionType"]
					],
				label_it:
					translations["acquisitionType"][
						language as keyof typeof translations["acquisitionType"]
					],
			},
			{
				id: 7,
				label:
					translations["condition"][
						language as keyof typeof translations["condition"]
					],
				label_it:
					translations["condition"][
						language as keyof typeof translations["condition"]
					],
			},
			{
				id: 6,
				label:
					translations["collocation"][
						language as keyof typeof translations["collocation"]
					],
				label_it:
					translations["collocation"][
						language as keyof typeof translations["collocation"]
					],
			},
			{
				id: 20,
				label:
					translations["codiceArticolo"][
						language as keyof typeof translations["codiceArticolo"]
					],
				label_it:
					translations["codiceArticolo"][
						language as keyof typeof translations["codiceArticolo"]
					],
			},
			/* { id: 0, label: "CODICE VARIANTE" }, */
		]);
	}, [language]);

	useEffect(() => {
		if (activeFilter !== 0) {
			const breadcrumbs: any = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filters
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "",
			};
			breadcrumbs.push(filterCrumb);
			setCrumbs(breadcrumbs);
			setActiveTag(0);
		}
	}, [activeFilter]);

	useEffect(() => {
		if (activeFilter !== 0) {
			const breadcrumbs: any = [{ name: "HOME", path: "/" }];
			const filterCrumb = {
				name: filters
					.find((x: any) => x.id == activeFilter)!
					.label.toUpperCase(),
				path: "",
			};
			breadcrumbs.push(filterCrumb);
			setCrumbs(breadcrumbs);
			setActiveTag(0);
		}
	}, [language]);

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);
	}, []);

	useEffect(() => {
		if (user.role === 3) setActiveFilter(2);

		if (user.role == 3)
			setFilters([
				{
					id: 2,
					label:
						translations["cat2Backend"][
							language as keyof typeof translations["cat2Backend"]
						],
					image: "shoes/records.png",
				},
				{
					id: 3,
					label:
						translations["cat3Backend"][
							language as keyof typeof translations["cat3Backend"]
						],
					image: "shoes/folders.png",
				},
				{
					id: 4,
					label:
						translations["cat4Backend"][
							language as keyof typeof translations["cat4Backend"]
						],
					image: "shoes/loans.png",
				},
				{
					id: 5,
					label:
						translations["cat5Backend"][
							language as keyof typeof translations["cat5Backend"]
						],
					image: "shoes/settings.png",
				},
			]);
		else if (user.role == 4)
			setFilters([
				{
					id: 2,
					label:
						translations["cat2Backend"][
							language as keyof typeof translations["cat2Backend"]
						],
					image: "shoes/records.png",
				},
				{
					id: 3,
					label:
						translations["cat3Backend"][
							language as keyof typeof translations["cat3Backend"]
						],
					image: "shoes/folders.png",
				},
			]);
		else
			setFilters([
				{
					id: 1,
					label:
						translations["cat1Backend"][
							language as keyof typeof translations["cat1Backend"]
						],
					image: "shoes/users.png",
				},
				{
					id: 2,
					label:
						translations["cat2Backend"][
							language as keyof typeof translations["cat2Backend"]
						],
					image: "shoes/records.png",
				},
				{
					id: 3,
					label:
						translations["cat3Backend"][
							language as keyof typeof translations["cat3Backend"]
						],
					image: "shoes/folders.png",
				},
				{
					id: 4,
					label:
						translations["cat4Backend"][
							language as keyof typeof translations["cat4Backend"]
						],
					image: "shoes/loans.png",
				},
				{
					id: 5,
					label:
						translations["cat5Backend"][
							language as keyof typeof translations["cat5Backend"]
						],
					image: "shoes/settings.png",
				},
			]);
	}, [user.id]);

	const handleActiveFilter = (activeFilter: any) => {
		history.push("/backend/home/" + activeFilter);
		setActiveFilter(activeFilter);
	};

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = currentPage * pageSize;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={handleActiveFilter}
							activeFilter={activeFilter}
						/>
						{activeFilter == 5 && (
							<div>
								<TagFilter
									setActiveTag={setActiveTag}
									activeTag={activeTag}
									tags={tags}
									uppercase={true}
								/>
								<TagFilter
									setActiveTag={setActiveTag}
									activeTag={activeTag}
									tags={tags2}
									uppercase={true}
								/>
							</div>
						)}
					</Col>
				</Row>
				{activeFilter == 0 && (
					<Row>
						<Col sm={12} style={{ marginBottom: 50 }}>
							<div className="slide-container" style={{ height: "70vh" }}>
								<Slide>
									{slideImages.map((slideImage, index) => (
										<div
											className="each-slide"
											key={index}
											style={{ height: "70vh" }}
										>
											<div
												style={{
													backgroundImage: `url(${slideImage.url})`,
													height: "70vh",
												}}
											>
												<span>{slideImage.caption}</span>
											</div>
										</div>
									))}
								</Slide>
							</div>
						</Col>
					</Row>
				)}

				{activeFilter != 0 && (
					<Row className="breacrumbs-row">
						<Col sm={10} style={{ paddingLeft: 0 }}>
							<Breadcrumbs crumbs={crumbs} />
						</Col>
					</Row>
				)}

				{activeFilter == 1 && (
					<Row className="table">
						<Col sm={12}>
							<Users />
						</Col>
					</Row>
				)}

				{activeFilter == 2 && (
					<Row className="table">
						<Col sm={12}>
							<BackendProducts />
						</Col>
					</Row>
				)}

				{activeFilter == 3 && (
					<Row className="table">
						<Col sm={12}>
							<MyFolders2 />
						</Col>
					</Row>
				)}

				{activeFilter == 4 && (
					<Row className="table">
						<Col sm={12}>
							<MyLoans2 />
						</Col>
					</Row>
				)}

				{activeFilter == 5 && (
					<Row className="table">
						<Col sm={12}>
							<Settings activeTag={activeTag} />
						</Col>
					</Row>
				)}
			</Container>
		</div>
	);
};

export default HomeBackend;
