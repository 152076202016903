import { property } from "lodash";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import AuthService from "../utils/auth.service";

const Product = (props: any) => {
	const [loanStatus, setLoanStatus] = useState(false);
	const [folderStatus, setFolderStatus] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState(false);
	const [user, setUser] = useState({
		id: 0,
		role: 0,
		loanRequestAllowed: false,
	});
	const [hideLoan2, setHideLoan2] = useState(false);

	let loanRef: any;
	let folderRef: any;
	let deleteRef: any;

	const product = props.product;

	/*
	if (product?.Items[0]?.type == 1) {
		props.hideLoan = true;
	}
	*/

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);

		if (!currentUser.loanRequestAllowed) {
			setHideLoan2(true);
		}

		if (props.hideLoan) {
			setHideLoan2(true);
		}

		if (!product?.isLoanable) {
			setHideLoan2(true);
		}

		setLoanStatus(false);
		setFolderStatus(false);
		setDeleteStatus(false);
		props.loanHandler(loanRef, folderRef, 0, true);
		props.folderHandler(loanRef, folderRef, 0, true);
		if (props.deleteHandler) {
			props.deleteHandler(deleteRef, 0, true);
		}
	}, [props.generalHandler]);

	let productName = "";
	if (product.Brand?.label) {
		productName += product.Brand?.label + ", ";
	}
	if (product.Season?.label) {
		productName += product.Season?.label + " ";
	}
	if (product.Years[0]?.label) {
		productName += product.Years[0]?.label;
	}

	let productDetail = "";
	if (product.productCode) {
		productDetail += product.productCode + ", ";
	}
	if (product.Category?.label) {
		productDetail += product.Category?.label + ", ";
	}
	if (product.Subcategory?.label) {
		productDetail += product.Subcategory?.label + ", ";
	}
	if (product.Materials[0]?.label) {
		productDetail += product.Materials[0]?.label + ", ";
	}
	if (product.Colors[0]?.label) {
		productDetail += product.Colors[0]?.label;
	}

	return (
		<div className="product">
			<NavLink
				className=""
				to={"/product/" + product.id}
				target={props.target ? "_blank" : "_self"}
			>
				<img
					src={
						product.Medias?.length
							? product.Medias[0].url
							: process.env.PUBLIC_URL + "/imgs/no_image.png"
					}
					alt=""
					className="product-image"
				/>
			</NavLink>
			<div className="product-infos">
				<span className="product-name">{productName}</span>
				<span className="product-detail">{productDetail}</span>
			</div>
			{!props.hideActions && (
				<div className="product-actions">
					{!hideLoan2 && (
						<span
							ref={(ref) => (loanRef = ref)}
							data-tip
							data-for="loans"
							data-event="blur"
						>
							<img
								src={process.env.PUBLIC_URL + "/new_icons/ADD_TO_BOX.svg"}
								alt=""
								data-for="default"
								data-tip="Add To Loan Request"
								onClick={() => {
									props.loanHandler(loanRef, folderRef, product.id, loanStatus);
									setLoanStatus(!loanStatus);
									setFolderStatus(false);
								}}
							/>
						</span>
					)}

					{!props.hideFolder && (
						<span
							ref={(ref) => (folderRef = ref)}
							data-tip
							data-for="folders"
							data-event="blur"
						>
							<img
								src={process.env.PUBLIC_URL + "/new_icons/FOLDER_ADD.svg"}
								alt=""
								style={{ marginTop: 5 }}
								data-for="default"
								data-tip="Add To Folder"
								onClick={() => {
									props.folderHandler(
										loanRef,
										folderRef,
										product.id,
										folderStatus
									);
									setFolderStatus(!folderStatus);
									setLoanStatus(false);
								}}
							/>
						</span>
					)}
					{props.deleteHandler && (
						<span
							ref={(ref) => (deleteRef = ref)}
							data-tip
							data-for="delete"
							data-event="blur"
						>
							<img
								src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
								alt=""
								data-for="default"
								data-tip={props.deleteText}
								onClick={() => {
									props?.deleteHandler(deleteRef, product.id, deleteStatus);
									setDeleteStatus(!deleteStatus);
								}}
							/>
						</span>
					)}
				</div>
			)}
		</div>
	);
};

export default Product;
