import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import { getUsers, deleteUser } from "../utils/apis/all";
import UsersTable from "./usersTable";
import Pagination from "./pagination";
import Button from "./common/button";
import Input from "./common/input";
import Modal from "react-modal";
import UserEditModal from "./userEditModal";
import { Store } from "react-notifications-component";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "520px",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const Users = () => {
	const { idActiveFilter, idActiveTag }: any = useParams();

	const [modalEditIsOpen, setEditIsOpen] = useState(false);
	const [editId, setEditId] = useState(0);

	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortColumn, setSortColumn] = useState({
		path: "firstName",
		order: "asc",
	});
	const [total, setTotal] = useState(0);
	const [search, setSearch] = useState("");

	useEffect(() => {});

	useEffect(() => {
		getUsers({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: search,
		}).then((res: any) => {
			setUsers(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}, [idActiveFilter]);

	useEffect(() => {
		getUsers({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: search,
		}).then((res: any) => {
			setUsers(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}, [search]);

	function handlePageChange(page: any) {
		setCurrentPage(page);
		getUsers({
			page: page - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: search,
		}).then((res: any) => {
			setUsers(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}

	function handleSort(sort: any) {
		getUsers({
			page: 0,
			perPage: pageSize,
			sortBy: sort["path"],
			sortDirection: sort["order"],
			textSearch: search,
		}).then((res: any) => {
			setUsers(res[0]);
			setTotal(res[1]);
			setCurrentPage(1);
		});

		setSortColumn(sort);
	}

	function updateUsers() {
		getUsers({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: search,
		}).then((res: any) => {
			setUsers(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}

	function handleEdit(id: any) {
		setEditId(id);
		setEditIsOpen(true);
	}

	function handleDelete(id: any) {
		if (window.confirm("Eliminare l'utente selezionato?")) {
			deleteUser(id).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Utente eliminato correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				updateUsers();
			});
		}
	}

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = currentPage * pageSize;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Row>
				<Col sm={4}>
					{/*<Input
						name="search"
						placeholder="Search..."
						onChange={(e: any) => setSearch(e.target.value)}
						value={search}
	/>*/}
				</Col>
				<Col sm={4}></Col>
				<Col sm={4} style={{ textAlign: "right" }}>
					<Button
						name="confirm"
						label={
							localStorage.getItem("language") === "it"
								? "Aggiungi Utente"
								: "Add User"
						}
						classes="btn-empty"
						onClick={() => {
							setEditId(0);
							setEditIsOpen(true);
						}}
					/>
				</Col>
			</Row>
			<UsersTable
				users={users}
				sortColumn={sortColumn}
				onEdit={handleEdit}
				onDelete={handleDelete}
				onSort={handleSort}
			/>
			<div className="tableControls">
				<p className="tableInfo">
					<b>{tableInfo1}</b> to <b>{tableInfo2}</b> of <b>{total}</b>
				</p>
				<Pagination
					itemsCount={total}
					pageSize={pageSize}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>
			<Modal
				isOpen={modalEditIsOpen}
				onRequestClose={() => setEditIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setEditIsOpen(false)}
					/>
				</div>
				<UserEditModal
					userId={editId}
					onClose={() => {
						setEditIsOpen(false);
						updateUsers();
					}}
				/>
			</Modal>
		</div>
	);
};

export default Users;
