import Home from "./components/home";
import HomeBackend from "./components/homeBackend";
import MyFolders from "./components/myFolders";
import MyLoans from "./components/myLoans";
import ProductPage from "./components/productPage";
import LoanPage from "./components/loanPage";
import FolderPage from "./components/folderPage";
import FolderPageBackend from "./components/folderPageBackend";
import LoanPageBackend from "./components/loanPageBackend";
import Filters from "./components/filters";
import ForgotPassword from "./components/forgotPassword";

export default [
	{
		path: "/home",
		name: "Home",
		Component: Home,
	},
	{
		path: "/backend/home",
		name: "Home",
		Component: HomeBackend,
	},
	{
		path: "/backend/home/:idActiveFilter",
		name: "Home",
		Component: HomeBackend,
	},
	{
		path: "/backend/folder/:folderId",
		name: "FolderPage",
		Component: FolderPageBackend,
	},
	{
		path: "/backend/loan/:loanId",
		name: "LoanPage",
		Component: LoanPageBackend,
	},
	{
		path: "/home/:idActiveFilter",
		name: "Home",
		Component: Home,
	},
	{
		path: "/home/:idActiveFilter/:idActiveTag",
		name: "Home",
		Component: Home,
	},
	{
		path: "/myFolders",
		name: "My Folders",
		Component: MyFolders,
	},
	{
		path: "/myLoans",
		name: "My Loans",
		Component: MyLoans,
	},
	{
		path: "/product/:productId",
		name: "ProductPage",
		Component: ProductPage,
	},
	{
		path: "/loan/:loanId",
		name: "LoanPage",
		Component: LoanPage,
	},
	{
		path: "/folder/:folderId",
		name: "FolderPage",
		Component: FolderPage,
	},
	{
		path: "/filters",
		name: "Filters",
		Component: Filters,
	},
	{
		path: "/forgotPassword",
		name: "ForgotPassword",
		Component: ForgotPassword,
	},
];
