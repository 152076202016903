import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import {
	addColor,
	getColor,
	editColor,
	getAllColors,
} from "../../utils/apis/all";

const EditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addColor;
	const getRow = getColor;
	const editRow = editColor;

	const [selectedColors, setSelectedColors]: any = useState([]);
	const [colors, setColors]: any = useState([]);

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);
			});
		}

		getAllColors({}).then((res: any) => {
			res[0] = res[0].map((item: any) => {
				if (item.id === editId) return null;

				if (!item.isParent) return item;
				else return null;
			});
			res[0] = res[0].filter((n: any) => n);
			//console.log(res[0]);
			setColors(res[0]);
		});
	}, [editId]);

	const [row, setRow] = useState({
		label: "",
		label_it: "",
		code: "",
		isParent: false,
		parentId: 0,
		children: [],
	});

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		if (editId == 0) {
			//console.log("test");
			addRow(row)
				.then((res: any) => {
					//console.log(res);
					if (res?.status === 201) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità creata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la creazione dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la creazione dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		} else {
			editRow(editId, { ...row, childrenIds: selectedColors })
				.then((res: any) => {
					if (res == 200) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità modificata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la modifica dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		}
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{editId
								? localStorage.getItem("language") === "it"
									? "Modifica record"
									: "Edit record"
								: localStorage.getItem("language") === "it"
								? "Nuovo record"
								: "New record"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Name (EN)
							</span>
							<Input
								name="label"
								placeholder=""
								onChange={handleInput}
								value={row.label}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Nome (IT)
							</span>
							<Input
								name="label_it"
								placeholder=""
								onChange={handleInput}
								value={row.label_it}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Is Parent
							</span>
							<Select
								name="isParent"
								options={[
									{ id: 1, name: "No" },
									{ id: 2, name: "Yes" },
								]}
								selectedItem={+row.isParent + 1}
								placeholder=""
								onChange={(e: any) => {
									//console.log(Boolean(parseInt(e.target.value) - 1));
									setRow({
										...row,
										isParent: Boolean(parseInt(e.target.value) - 1),
									});
								}}
							/>
						</div>
						{row.isParent && (
							<div>
								<span
									className="text-small"
									style={{ display: "block", marginTop: 15, marginBottom: 3 }}
								>
									Color Code
								</span>
								<Input
									name="code"
									placeholder=""
									onChange={handleInput}
									value={row.code}
								/>
							</div>
						)}
						{row.isParent && (
							<div>
								<span
									className="text-small"
									style={{
										display: "block",
										marginTop: 15,
										marginBottom: 3,
										fontWeight: 600,
									}}
								>
									Childs
								</span>
								<Row
									style={{
										gap: "10px 15px",
										maxHeight: 100,
										height: 100,
										marginLeft: 0,
										marginRight: 0,

										overflowY: "scroll",
									}}
								>
									{colors?.map((color: any) => {
										return (
											<div key={color.id}>
												<div
													className={
														selectedColors.includes(color.id)
															? "tag active"
															: "tag"
													}
													onClick={() => {
														if (selectedColors.includes(color.id)) {
															setSelectedColors(
																selectedColors.filter(
																	(item: any) => item !== color.id
																)
															);
														} else {
															setSelectedColors([...selectedColors, color.id]);
														}
													}}
												>
													<span className="text-small">
														{color.label ? color.label : color.id}
													</span>
												</div>
											</div>
										);
									})}
								</Row>
							</div>
						)}

						<div style={{ marginTop: 15 }}>
							<Button
								name="confirm"
								label={
									localStorage.getItem("language") === "it" ? "SALVA" : "SAVE"
								}
								classes="btn-empty btn-full"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default EditModal;
