export function handleParams(params) {
	const resultParams = new URLSearchParams();

	Object.keys(params).forEach((key) => {
		if (params[key] !== undefined && params[key] !== null) {
			resultParams.append(key, params[key]);
		}
	});

	return resultParams;
}

export function handleSuccess(functionName, response) {
	console.log("%c🐝🟢 " + functionName, "color: #42ba96");
	console.log(response);
}

export function handleError(functionName, error) {
	console.log("%c🐝🔴 " + functionName, "color: #FF5733");

	if (error.response.status === 401) {
		//window.location.href = "login";
		window.location.replace("/logout");
	}

	console.log(error);
}

export function humanFileSize(bytes, si = true, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	);

	return bytes.toFixed(dp) + " " + units[u];
}
