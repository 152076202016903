import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";
import FsLightbox from "fslightbox-react";
import { useReactToPrint } from "react-to-print";
import AuthService from "../utils/auth.service";

import translations from "../translations.json";

import {
	getProduct,
	getLoansNotSentVerification,
	getFolders,
	addProductToLoan,
	addProductToFolder,
	getProductsByCategory,
	getRelatedProducts,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import ProductTag from "./common/productTag";
import Product from "./product";

import ProductPagePrint from "./productPagePrint";

const ProductPage = () => {
	const [language, setLanguage]: any = useState("en");

	const history = useHistory();

	const { productId }: any = useParams();

	const componentRef: any = useRef(null);

	const [loanStatus, setLoanStatus] = useState(false);
	const [folderStatus, setFolderStatus] = useState(false);

	const [toggler, setToggler] = useState(false);

	const [user, setUser] = useState({ id: 0, role: 0 });
	const [hideLoan2, setHideLoan2] = useState(false);

	let loanRef: any;
	let folderRef: any;

	const [loans, setLoans] = useState([]);
	const [folders, setFolders] = useState([]);

	useEffect(() => {
		let filtersApp = [
			{
				id: 1,
				label:
					translations["cat1Frontend"][
						language as keyof typeof translations["cat1Frontend"]
					],
				image: "shoes/flat_shoes.png",
			},
			{
				id: 2,
				label:
					translations["cat2Frontend"][
						language as keyof typeof translations["cat2Frontend"]
					],
				image: "shoes/high_heel.png",
			},
			{
				id: 3,
				label:
					translations["cat3Frontend"][
						language as keyof typeof translations["cat3Frontend"]
					],
				image: "shoes/sandals.png",
			},
			{
				id: 4,
				label:
					translations["cat4Frontend"][
						language as keyof typeof translations["cat4Frontend"]
					],
				image: "shoes/boots.png",
			},
			{
				id: 6,
				label:
					translations["cat6Frontend"][
						language as keyof typeof translations["cat6Frontend"]
					],
				image: "shoes/sneakers.png",
			},
		];
		setFilters(filtersApp);

		////console.log(language);
	}, [language]);

	const [filters, setFilters]: any = useState([
		{
			id: 1,
			label:
				translations["cat1Frontend"][
					language as keyof typeof translations["cat1Frontend"]
				],
			image: "shoes/flat_shoes.png",
		},
		{
			id: 2,
			label:
				translations["cat2Frontend"][
					language as keyof typeof translations["cat2Frontend"]
				],
			image: "shoes/high_heel.png",
		},
		{
			id: 3,
			label:
				translations["cat3Frontend"][
					language as keyof typeof translations["cat3Frontend"]
				],
			image: "shoes/sandals.png",
		},
		{
			id: 4,
			label:
				translations["cat4Frontend"][
					language as keyof typeof translations["cat4Frontend"]
				],
			image: "shoes/boots.png",
		},
		{
			id: 6,
			label:
				translations["cat6Frontend"][
					language as keyof typeof translations["cat6Frontend"]
				],
			image: "shoes/sneakers.png",
		},
	]);

	const [selectedImg, setSelectedImg] = useState("");

	const [relatedItems, setRelatedItems] = useState([]);

	const [product, setProduct] = useState({
		productCode: "",
		Subcategory: { label: "" },
		Category: { label: "" },
		Brand: { label: "" },
		generalTags: [],
		technicalTags: [],
		managementTags: [],
		Medias: [],
		Items: [
			{
				InStock: {
					label: "",
				},
				type: 0,
			},
		],
		isLoanable: false,
		descriptionNotes: "",
	});

	const [crumbs, setCrumbs] = useState([{ name: "Home", path: "/home" }]);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	const prevAmount = usePrevious({ productId });

	useEffect(() => {
		if (productId !== prevAmount) {
			const currentUser = AuthService.getCurrentUser();
			if (currentUser && currentUser.id !== user.id) setUser(currentUser);

			if (!currentUser?.loanRequestAllowed) {
				setHideLoan2(true);
			}

			getProduct({ productId: productId }).then((res: any) => {
				/*
				if (res?.Items[0]?.type == 1) {
					console.log("pippo");
					setHideLoan2(true);
				}
				*/

				if (!res.isLoanable) {
					setHideLoan2(true);
				}

				if (res.generalTags.length > 0) {
					let generalTags: any = [
						{
							type: "Codes",
							tags: [],
						},
					];
					res.generalTags.forEach((tag: any) => {
						if (
							tag.type === "productCode" ||
							tag.type === "modelName" ||
							tag.type === "modelTypeId" ||
							tag.type === "variantId"
						) {
							generalTags.find((o: any) => o.type === "Codes").tags.push(tag);
						} else {
							if (generalTags.find((o: any) => o.type === tag.type)) {
								generalTags
									.find((o: any) => o.type === tag.type)
									.tags.push(tag);
							} else {
								generalTags.push({ type: tag.type, tags: [tag] });
							}
						}
					});

					res.generalTags = generalTags;
				}

				if (res.managementTags.length > 0) {
					let managementTags: any = [];
					res.managementTags.forEach((tag: any) => {
						if (managementTags.find((o: any) => o.type === tag.type)) {
							managementTags
								.find((o: any) => o.type === tag.type)
								.tags.push(tag);
						} else {
							managementTags.push({ type: tag.type, tags: [tag] });
						}
					});
					res.managementTags = managementTags;
				}

				if (res.technicalTags.length > 0) {
					let technicalTags: any = [];
					res.technicalTags.forEach((tag: any) => {
						if (technicalTags.find((o: any) => o.type === tag.type)) {
							technicalTags
								.find((o: any) => o.type === tag.type)
								.tags.push(tag);
						} else {
							technicalTags.push({ type: tag.type, tags: [tag] });
						}
					});
					res.technicalTags = technicalTags;
				}

				setProduct(res);
				if (res.Medias[0]) setSelectedImg(res.Medias[0].url);

				const breadcrumbs: any = [{ name: "HOME", path: "/" }];

				if (res.Category) {
					breadcrumbs.push({
						name: res.Category.label,
						path: "/home/" + res.Category.id,
					});
					if (res.Subcategory) {
						breadcrumbs.push({
							name: res.Subcategory.label,
							path: "/home/" + res.Category.id + "/" + res.Subcategory.id,
						});
						if (res.ModelType)
							//breadcrumbs.push({ name: res.Subcategory.label, path: "" });
							breadcrumbs.push({ name: res.ModelType.label, path: "" });
					}
				}

				getLoansNotSentVerification({}).then((res: any) => {
					setLoans(res[0]);
				});
				getFolders({}).then((res: any) => {
					setFolders(res[0]);
				});

				getRelatedProducts({ productId, limit: 12 }).then((res: any) => {
					setRelatedItems(res);
				});

				setCrumbs(breadcrumbs);
			});

			setLoanStatus(false);
			setFolderStatus(false);

			window.scrollTo(0, 0);
		}
	}, [productId]);

	function usePrevious(value: any) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	useEffect(() => {}, []);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const addToLoan = (loanId: number) => {
		addProductToLoan(loanId, parseInt(productId)).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message:
						"L'item è stato aggiunto alla richiesta di prestito correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item al prestito",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		ReactTooltip.hide(loanRef);
		setLoanStatus(false);
	};

	const addToFolder = (folderId: number) => {
		addProductToFolder(folderId, parseInt(productId)).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message: "L'item è stato aggiunto alla cartella correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item alla cartella",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		ReactTooltip.hide(folderRef);
		setFolderStatus(false);
	};

	const reactToPrintContent = useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Product Page",
		removeAfterPrint: true,
	});

	let productName = product.Brand?.label;

	if (product.Category) {
		productName += " / " + product.Category?.label;
	}

	if (product.Subcategory) {
		if (product.Subcategory?.label)
			productName += " / " + product.Subcategory?.label;
	}

	const handleSetComponentRef = (ref: any) => {
		componentRef.current = ref;
	};

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={setActiveFilter}
							activeFilter={0}
						/>
					</Col>
				</Row>
				<Row className="breacrumbs-row-np">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
					</Col>
				</Row>
				<Row className="">
					<Col sm={6}>
						<Row>
							<Col sm={3} className="single-product-images">
								{product.Medias.map((media: any) => {
									return (
										<img
											src={media.url}
											key={media.id}
											alt=""
											onClick={() => setSelectedImg(media.url)}
										/>
									);
								})}

								<FsLightbox
									toggler={toggler}
									sources={product.Medias.map((media: any) => media.url)}
									types={[...new Array(product.Medias?.length).fill("image")]}
								/>
							</Col>
							<Col sm={8} className="single-product-main-image">
								<img
									src={selectedImg}
									alt=""
									onClick={() => setToggler(true)}
								/>
							</Col>
						</Row>
					</Col>
					<Col sm={6}>
						<Row>
							<Col sm={8} style={{ paddingRight: 0 }}>
								<div className="other-tags" style={{ marginTop: 0 }}>
									<span>
										{language == "it" ? "DESCRIZIONE" : "MAIN DESCRIPTION"}
									</span>
								</div>
							</Col>
							<Col sm={4} className="single-product-actions">
								<span>
									<img
										src={process.env.PUBLIC_URL + "/imgs/print.png"}
										alt=""
										data-tip="Print"
										data-for="default"
										style={{ width: 26 }}
										onClick={() => {
											let content = document.getElementById("productToPrint");
											if (content) content.style.display = "block";
											handlePrint();
											if (content) content.style.display = "none";
										}}
									/>
								</span>
								<span
									ref={(ref) => (folderRef = ref)}
									data-tip
									data-for="folders"
									data-event="blur"
								>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/FOLDER_ADD.svg"}
										alt=""
										data-tip="Add to Folder"
										data-for="default"
										style={{ width: 26 }}
										onClick={() => {
											if (folderStatus) {
												ReactTooltip.hide(folderRef);
											} else {
												ReactTooltip.hide(loanRef);
												ReactTooltip.show(folderRef);
											}

											setFolderStatus(!folderStatus);
											setLoanStatus(false);
										}}
									/>
								</span>
								{!hideLoan2 && (
									<span
										ref={(ref) => (loanRef = ref)}
										data-tip
										data-for="loans"
										data-event="blur"
									>
										<img
											src={process.env.PUBLIC_URL + "/new_icons/ADD_TO_BOX.svg"}
											alt=""
											data-tip="Add To Loan Request"
											data-for="default"
											onClick={() => {
												if (loanStatus) {
													ReactTooltip.hide(loanRef);
												} else {
													ReactTooltip.hide(folderRef);
													ReactTooltip.show(loanRef);
												}

												setLoanStatus(!loanStatus);
												setFolderStatus(false);
											}}
										/>
									</span>
								)}
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.generalTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row style={{ marginRight: 0 }}>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col sm={12} style={{ paddingRight: 0 }}>
								<div className="other-tags">
									<span>
										{language == "it"
											? "DESCRIZIONE FISICA"
											: "PHYSICAL DESCRIPTION"}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.technicalTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row style={{ marginRight: 0 }} key={tag.type}>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col sm={12} style={{ paddingRight: 0 }}>
								<div className="other-tags">
									<span>{language == "it" ? "NOTE" : "MANAGEMENT NOTES"}</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.managementTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row style={{ marginRight: 0 }} key={tag.type}>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="related-items">
					<Col sm={12}>
						<span>
							{language == "it" ? "PRODOTTI CORRELATI" : "RELATED ITEMS"}
						</span>
					</Col>
				</Row>
				<div style={{ overflow: "hidden" }}>
					<Row className="related-items-content">
						{relatedItems?.map((product: any) => {
							if (!product) return null;
							return (
								<Col key={product.id} sm={3} className="product-container">
									<Product
										product={product}
										loans={loans}
										folders={folders}
										hideActions={true}
										loanHandler={() => {}}
										folderHandler={() => {}}
										generalHandler={() => {}}
									/>
								</Col>
							);
						})}
					</Row>
				</div>
			</Container>
			<ReactTooltip
				id="loans"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{loans.map((loan: any) => {
						return (
							<li key={loan.id} onClick={() => addToLoan(loan.id)}>
								{loan.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
			<ReactTooltip
				id="folders"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{folders.map((folder: any) => {
						return (
							<li key={folder.id} onClick={() => addToFolder(folder.id)}>
								{folder.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
			<ProductPagePrint
				propsProduct={product}
				setComponentRef={handleSetComponentRef}
				style={{ display: "none" }}
			/>
		</div>
	);
};

export default ProductPage;
