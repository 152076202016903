import { useState, useEffect } from "react";
import Product from "./product";
import { Container, Row, Col } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import {
	getLoansNotSentVerification,
	getFolders,
	addProductToLoan,
	addProductToFolder,
} from "../utils/apis/all";

const Products = (props: any) => {
	const [loans, setLoans] = useState([]);
	const [folders, setFolders] = useState([]);

	const [selectedProductId, setSelectedProductId] = useState(0);
	const [generalHandlerStatus, setGeneralHandlerStatus] = useState(false);

	useEffect(() => {
		getLoansNotSentVerification({}).then((res: any) => {
			setLoans(res[0]);
		});
		getFolders({}).then((res: any) => {
			setFolders(res[0]);
		});
	}, [JSON.stringify(props)]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const loanHandler = (
		loanRef: any,
		folderRef: any,
		productId: number,
		status: boolean
	) => {
		if (status) {
			ReactTooltip.hide(loanRef);
			setSelectedProductId(0);
		} else {
			ReactTooltip.hide(folderRef);
			ReactTooltip.show(loanRef);
			setSelectedProductId(productId);
		}
	};

	const folderHandler = (
		loanRef: any,
		folderRef: any,
		productId: number,
		status: boolean
	) => {
		if (status) {
			ReactTooltip.hide(folderRef);
			setSelectedProductId(0);
		} else {
			ReactTooltip.hide(loanRef);
			ReactTooltip.show(folderRef);
			setSelectedProductId(productId);
		}
	};

	const addToLoan = (loanId: number) => {
		addProductToLoan(loanId, selectedProductId).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message:
						"L'item è stato aggiunto alla richiesta di prestito correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item al prestito",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		setGeneralHandlerStatus(!generalHandlerStatus);
	};

	const addToFolder = (folderId: number) => {
		addProductToFolder(folderId, selectedProductId).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message: "L'item è stato aggiunto alla cartella correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item alla cartella",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		setGeneralHandlerStatus(!generalHandlerStatus);
	};

	return (
		<div className="products-container">
			<Row>
				{props.products.map((product: any) => {
					return (
						<Col key={product.id} sm={3} className="product-container">
							<Product
								product={product}
								loans={loans}
								folders={folders}
								loanHandler={loanHandler}
								folderHandler={folderHandler}
								generalHandler={generalHandlerStatus}
							/>
						</Col>
					);
				})}
			</Row>
			<ReactTooltip
				id="loans"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{loans.map((loan: any) => {
						return (
							<li key={loan.id} onClick={() => addToLoan(loan.id)}>
								{loan.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
			<ReactTooltip
				id="folders"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{folders.map((folder: any) => {
						return (
							<li key={folder.id} onClick={() => addToFolder(folder.id)}>
								{folder.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default Products;
