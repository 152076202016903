import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "./common/input";
import Button from "./common/button";
import Select from "./common/select";

import { getLoan, getAllUsers } from "../utils/apis/all";

import AuthService from "../utils/auth.service";

const LoanEditModal = ({ loanId, onSave }: any) => {
	const history = useHistory();
	const [user, setUser] = useState({ id: 0, firstName: "", role: 0 });

	const [emailClasses, setEmailClasses] = useState("");
	const [phoneClasses, setPhoneClasses] = useState("");

	function ValidateEmail(mail: string) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}

	function ValidatePhone(phone: string) {
		if (/^\+\d{1,3}\d{7,}$/.test(phone)) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);
	});

	useEffect(() => {
		if (loanId != 0) {
			getLoan({ loanId }).then((res: any) => {
				setLoan(res);
			});
		}

		getAllUsers({}).then((res: any) => {
			res[0] = res[0].map((user: any) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			});
			setUsers(res[0]);
		});
	}, [loanId]);

	const [users, setUsers] = useState([]);

	const [loan, setLoan] = useState({
		title: "",
		referencePersonId: 0,
		department: "",
		company: "",
		deliveryAddress: "",
		email: "",
		phone: "",
		notes: "",
	});

	const handleInput = (e: any) => {
		setLoan((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{loanId ? "EDIT LOAN REQUEST" : "CREATE A LOAN REQUEST"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Title
							</span>
							<Input
								name="title"
								placeholder=""
								onChange={handleInput}
								value={loan.title}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Reference Person
							</span>
							<Select
								name="referencePersonId"
								options={users}
								selectedItem={user.id}
								placeholder="select reference person..."
								onChange={() => {}}
								disabled={true}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Department
							</span>
							<Input
								name="department"
								placeholder=""
								onChange={handleInput}
								value={loan.department}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Company
							</span>
							<Input
								name="company"
								placeholder=""
								onChange={handleInput}
								value={loan.company}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Delivery Address
							</span>
							<Input
								name="deliveryAddress"
								placeholder=""
								onChange={handleInput}
								value={loan.deliveryAddress}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Email
							</span>
							<Input
								name="email"
								placeholder=""
								onChange={handleInput}
								value={loan.email}
								classes={emailClasses}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Phone
							</span>
							<Input
								name="phone"
								placeholder=""
								onChange={handleInput}
								value={loan.phone}
								classes={phoneClasses}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Notes
							</span>
							<textarea
								onChange={handleInput}
								name="notes"
								style={{
									padding: 12,
									backgroundColor: "var(--grey)",
									border: "none",
									width: "100%",
									borderRadius: 12,
									resize: "vertical",
									fontSize: "var(--text-medium)",
								}}
								rows={2}
								value={loan.notes}
							></textarea>
						</div>
						<div style={{ marginTop: 15 }}>
							<Button
								name="confirm"
								label="SAVE"
								classes="btn-empty btn-full"
								onClick={() => {
									if (!ValidateEmail(loan.email)) {
										setEmailClasses("error");
										return;
									} else {
										setEmailClasses("");
									}

									if (!ValidatePhone(loan.phone)) {
										setPhoneClasses("error");
										return;
									} else {
										setPhoneClasses("");
									}

									onSave(loanId, loan);
								}}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default LoanEditModal;
