import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import Brands from "./brands/brands";
import Seasons from "./seasons/main";
import Collections from "./collections/collections";
import Acquisitions from "./acquisitions/main";
import Collocations from "./collocations/main";
import Colors from "./colors/main";
import ConservationStates from "./conservationStates/main";
import DecorativeThemes from "./decorativeThemes/main";
import Gaskets from "./gaskets/main";
import Genders from "./genders/main";
import HeelHeights from "./heelHeights/main";
import HeelTypes from "./heelTypes/main";
import MaterialProcessing from "./materialProcessings/main";
import Materials from "./materials/main";
import MaterialTypes from "./materialTypes/main";
import Sizes from "./sizes/main";
import SubCategories from "./subCategories/main";
import Quantities from "./quantities/main";
import DecorativeMotives from "./decorativeMotives/main";
import ModelTypes from "./modelTypes/main";
import Years from "./years/main";
import SpecificModels from "./specificModels/main";

const Settings = ({ activeTag }: any) => {
	switch (activeTag) {
		case 1:
			return <Brands />;
		case 2:
			return <Seasons />;
		case 3:
			return <Collections />;
		case 4:
			return <Acquisitions />;
		case 5:
			return <Colors />;
		case 6:
			return <Collocations />;
		case 7:
			return <ConservationStates />;
		case 8:
			return <DecorativeThemes />;
		case 9:
			return <Gaskets />;
		case 10:
			return <Genders />;
		case 11:
			return <HeelHeights />;
		case 12:
			return <HeelTypes />;
		case 13:
			return <MaterialProcessing />;
		case 14:
			return <Materials />;
		case 15:
			return <MaterialTypes />;
		case 16:
			return <Sizes />;
		case 17:
			return <SubCategories />;
		case 18:
			return <Quantities />;
		case 19:
			return <DecorativeMotives />;
		case 20:
			return <ModelTypes />;
		case 21:
			return <Years />;
		case 22:
			return <SpecificModels />;
		default:
			return <div></div>;
	}
};

export default Settings;
