import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { useHistory, useParams } from "react-router-dom";
import {
	getProducts,
	deleteProduct,
	editProduct,
	publishBulkProducts,
	deleteMultipleProducts,
	getFilteredProductsAdmin,
	getProductFilters,
	duplicateProduct,
	getFolders,
	addProductToFolder,
	addProduct,
} from "../utils/apis/all";
import ProductsTable from "./productsTable";
import Pagination from "./pagination";
import Button from "./common/button";
import Input from "./common/input";
import Modal from "react-modal";
import ProductEditModal from "./productEditModal";
import { Store } from "react-notifications-component";
import * as XLSX from "xlsx";
import { useReactToPrint } from "react-to-print";
import ProductPagePrint from "./productPagePrint";
import SearchFiltersModal from "./searchFiltersModal";
import AuthService from "../utils/auth.service";

import translations from "../translations.json";

const customStyles2 = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "1080px",
		minWidth: "1080px",
		width: "1080px",
		maxHeight: "800px",
		overflow: "visible",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "540px",
		maxHeight: "800px",
		overflow: "visible",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const Products = () => {
	const { idActiveFilter, idActiveTag }: any = useParams();

	const componentRef: any = useRef(null);
	let productsRef: any;
	let folderRef: any;

	const [modalEditIsOpen, setEditIsOpen] = useState(false);
	const [editId, setEditId] = useState(0);

	const [products, setProducts] = useState([]);
	const [checkedProducts, setCheckedProducts]: any = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortColumn, setSortColumn] = useState({
		path: "productCode",
		order: "asc",
	});
	const [total, setTotal] = useState(0);

	const [search, setSearch] = useState("");

	const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);

	const [searchText, setSearchText] = useState("");
	const [searchTextApp, setSearchTextApp] = useState("");

	const [folderStatus, setFolderStatus] = useState(false);
	const [folders, setFolders] = useState([]);

	const [user, setUser] = useState({
		id: 0,
		role: 0,
		loanRequestAllowed: false,
	});

	const [selectedFilters, setSelectedFilters]: any = useState({
		brandId: [],
		brandThemeId: [],
		categoryId: [],
		collectionId: [],
		genderId: [],
		heelHeightId: [],
		heelTypeId: [],
		seasonId: [],
		subcategoryId: [],
		specificModelId: [],
		years: [],
		materials: [],
		colors: [],
	});

	const [selectedFiltersApp, setSelectedFiltersApp]: any = useState({
		brandId: [],
		brandThemeId: [],
		categoryId: [],
		collectionId: [],
		genderId: [],
		heelHeightId: [],
		heelTypeId: [],
		seasonId: [],
		subcategoryId: [],
		specificModelId: [],
		years: [],
		materials: [],
		colors: [],
	});

	const [defaultFilters, setDefaultFilters]: any = useState({});

	const resettedFilters = {
		brandId: [],
		brandThemeId: [],
		categoryId: [],
		collectionId: [],
		genderId: [],
		heelHeightId: [],
		heelTypeId: [],
		seasonId: [],
		subcategoryId: [],
		years: [],
		materials: [],
		colors: [],
	};

	const [language, setLanguage]: any = useState("en");

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);

		getProductFilters().then((res) => {
			setDefaultFilters(res);
		});

		getFolders({}).then((res: any) => {
			setFolders(res[0]);
		});
		setFolderStatus(false);
	}, []);

	const addToFolder = (folderId: number, productId: any) => {
		addProductToFolder(folderId, productId).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message: "L'item  aggiunti alla cartella correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta degli item alla cartella",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		setFolderStatus(false);
	};

	useEffect(() => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		if ((obj && Object.keys(obj).length > 0) || searchText) {
			setSearchModalIsOpen(false);
			getFilteredProductsAdmin({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: 0,
				textSearch: searchText,
				filters: obj,
				showable: "",
			}).then((res: any) => {
				setProducts(res[0]);
				setTotal(res[1]);
			});
		} else {
			setProducts([]);
			setTotal(0);
			setCurrentPage(1);
		}
	}, [JSON.stringify(selectedFilters)]);

	/*
	useEffect(() => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		setSearchModalIsOpen(false);
		getProductsFiltered({
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			perPage: pageSize,
			page: currentPage - 1,
			textSearch: searchText,
			filters: obj,
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
		});
	}, [searchText]);
	*/

	const handleSearch = () => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		setSearchModalIsOpen(false);
		getFilteredProductsAdmin({
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			perPage: pageSize,
			page: 0,
			textSearch: searchText,
			filters: obj,
			showable: "",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
			setCurrentPage(1);
		});
	};

	useEffect(() => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		getFilteredProductsAdmin({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: searchText,
			filters: obj,
			showable: "",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
		});

		handleSetComponentRef(productsRef);
	}, [idActiveFilter]);

	const handlePageChange = (page: any) => {
		setCurrentPage(page);

		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		getFilteredProductsAdmin({
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			perPage: pageSize,
			page: page - 1,
			filters: obj,
			textSearch: searchText,
			showable: "",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
		});
	};

	function handleSort(sort: any) {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		getFilteredProductsAdmin({
			page: 0,
			perPage: pageSize,
			sortBy: sort["path"],
			sortDirection: sort["order"],
			sortValue: sort["sortValue"],
			textSearch: searchText,
			filters: obj,
			showable: "",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
			setCurrentPage(1);
		});

		setSortColumn(sort);
	}

	function updateProducts() {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		getFilteredProductsAdmin({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			textSearch: searchText,
			filters: obj,
			showable: "",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
		});
	}

	function handleEdit(id: any) {
		setEditId(id);
		setEditIsOpen(true);
	}

	function handleEditClose(product: any, formData: any) {
		//setEditIsOpen(false);

		if (product.id == 0) {
			addProduct(product).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Prodotto aggiunto correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				editProduct(res.id, formData).then((res: any) => {
					////console.log("immagini aggiornate con successo");
					updateProducts();
				});

				updateProducts();
			});
		} else {
			editProduct(editId, product).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Prodotto modificato correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				updateProducts();
			});

			editProduct(editId, formData).then((res: any) => {
				////console.log("immagini aggiornate con successo");

				updateProducts();
			});
		}
	}

	function handleDelete(id: any) {
		if (window.confirm("Eliminare il prodotto selezionato?")) {
			deleteProduct(id).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Prodotto eliminato correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				updateProducts();
			});
		}
	}

	function handleMultipleDelete() {
		if (window.confirm("Eliminare i prodotti selezionati?")) {
			deleteMultipleProducts(checkedProducts).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Prodotti eliminati correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				updateProducts();
			});
		}
	}

	function downloadExcel() {
		var arr: any = products.filter(
			(item: any) => checkedProducts.indexOf(item.id) !== -1
		);

		arr = arr.map((item: any) => {
			return {
				id: item.id,
				Immagini: item.Medias.map((media: any) => media.url).join(", "),
				"Codice Articolo": item.ModelType?.label,
				"Codice Variante": "",
				"Codice Articolo Brand": item.productBrandCode,
				//"In Archivio": item.Items[0]?.InStock?.label,
				"In Archivio Type": "",
				Status:
					item.statusId === 1
						? "Da Normalizzare"
						: item.statusId === 2
						? "Normalizzato"
						: "Pubblicato",
				Brand: item.Brand?.label,
				Anno: "",
				Stagione: item.Season?.label,
				Collezione: item.Collection?.label,
				"Linea/Tema Brand": "",
				"Modello Calzatura": item.Category?.label,
				Classe: item.Subcategory?.label,
				"Specifica Modello": item.specificModels?.label,
				Genere: item.Gender?.label,
				//Quantità: item.Items[0]?.Quantity?.label,
				//"Misura/Taglia": item.Items[0]?.Size?.label,
				Materiale: item.Materials.map((item: any) => item.label).join(", "),
				Colore: item.Colors.map((item: any) => item.label).join(", "),
				Guarnizione: item.Gaskets?.label,
				"Motivo Decorativo": "",
				Lavorazione: item.MaterialProcessings.map(
					(item: any) => item.label
				).join(", "),
				"Tipologia Tacco": item.HeelType?.label,
				"Altezza Tacco": item.HeelHeight?.label,
				//"Stato Conservazione": item.Items[0]?.ConservationState?.label,
				//"Note Conservazione": item.Items[0]?.conservationNotes,
				//Collocazione: item.Items[0]?.Collocation?.label,
				//"Tipologia Acquisizione": item.Items[0]?.Aquisition?.label,
				//Provenienza: item.Items[0]?.aquisitionSource,
				//"Data Acquisizione": item.Items[0]?.aquisitionDate,
				//"Costo di Acquisizione": item.Items[0]?.aquisitionPrice,
			};
		});

		const worksheet = XLSX.utils.json_to_sheet(arr);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, "products.xlsx");
	}

	function handleCheck(id: any) {
		////console.log("checkedProducts", checkedProducts);
		if (checkedProducts.includes(id)) {
			setCheckedProducts(checkedProducts.filter((i: any) => i !== id));
		} else {
			setCheckedProducts([...checkedProducts, id]);
		}
	}

	const reactToPrintContent = useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Product Page",
		removeAfterPrint: true,
	});

	const handleSetComponentRef = (ref: any) => {
		componentRef.current = ref;
	};

	const handlePublish = (showable: any) => {
		let text = "";
		if (showable) text = "Prodotti pubblicati correttamente";
		else text = "Prodotti spubblicati correttamente";

		publishBulkProducts({
			productIds: checkedProducts,
			showable: showable,
		}).then((res: any) => {
			Store.addNotification({
				title: "Success",
				message: text,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});

			updateProducts();
		});
	};

	const handleDuplicate = (product: any) => {
		setEditIsOpen(false);
		duplicateProduct({ productId: product.id }).then((res: any) => {
			Store.addNotification({
				title: "Success",
				message: "Prodotto duplicato correttamente",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});

			setEditId(res.id);
			setEditIsOpen(true);

			Store.addNotification({
				title: "Attenzione!",
				message:
					"Il prodotto attualmente aperto è una copia del prodotto selezionato precedentemente",
				type: "warning",
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});

			updateProducts();
		});
	};

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = tableInfo1 + products.length;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Row>
				<Col sm={4}>
					<Input
						name="search"
						placeholder="Search..."
						onChange={(e: any) => setSearchText(e.target.value)}
						onKeyDown={(e: any) => {
							if (e.key === "Enter") {
								handleSearch();
							}
						}}
						value={searchText}
					/>
				</Col>
				<Col sm={4}></Col>
				<Col sm={4} style={{ textAlign: "right" }}>
					<Button
						name="confirm"
						label={
							translations["addProduct"][
								language as keyof typeof translations["addProduct"]
							]
						}
						classes="btn-empty"
						style={{ marginRight: "10px" }}
						onClick={() => {
							setEditId(-1);
							setEditIsOpen(true);
						}}
					/>
					<Button
						name="confirm"
						label={
							translations["filters"][
								language as keyof typeof translations["filters"]
							]
						}
						classes="btn-empty"
						onClick={() => setSearchModalIsOpen(true)}
					/>
				</Col>
			</Row>
			<Row>
				{checkedProducts.length > 0 && (
					<Col sm={12} style={{ textAlign: "center" }}>
						<div className="multiple-actions">
							<span>
								<img
									src={process.env.PUBLIC_URL + "/imgs/print.png"}
									alt=""
									data-tip="Print"
									data-for="default"
									onClick={() => {
										const content = document.getElementsByClassName(
											"productToPrint"
										) as HTMLCollectionOf<HTMLElement>;
										for (let i = 0; i < content.length; i++) {
											content[i].style.display = "block";
										}
										handlePrint();
										if (content.length > 0) {
											for (let i = 0; i < content.length; i++) {
												content[i].style.display = "none";
											}
										}
									}}
								/>
							</span>
							<span>
								<img
									src={process.env.PUBLIC_URL + "/imgs/download.png"}
									alt=""
									data-tip="Download"
									data-for="default"
									onClick={downloadExcel}
								/>
							</span>
							<span
								ref={(ref) => (folderRef = ref)}
								data-tip
								data-for="folders"
								data-event="blur"
							>
								<img
									src={process.env.PUBLIC_URL + "/new_icons/FOLDER_ADD.svg"}
									alt=""
									style={{ marginTop: 5 }}
									data-for="default"
									data-tip="Add To Folder"
									onClick={() => {
										if (!folderStatus) ReactTooltip.show(folderRef);
										else ReactTooltip.hide(folderRef);
										setFolderStatus(!folderStatus);
									}}
								/>
							</span>
							<span>
								<img
									src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
									alt=""
									style={{ marginTop: 5 }}
									data-for="default"
									data-tip="Delete"
									onClick={handleMultipleDelete}
								/>
							</span>
							{user.loanRequestAllowed && (
								<span>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/pubblica.png"}
										alt=""
										style={{ marginTop: 5 }}
										data-for="default"
										data-tip="Pubblica"
										onClick={() => handlePublish(true)}
									/>
								</span>
							)}
							{user.loanRequestAllowed && (
								<span>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/spubblica.png"}
										alt=""
										style={{ marginTop: 5 }}
										data-for="default"
										data-tip="Nascondi"
										onClick={() => handlePublish(false)}
									/>
								</span>
							)}
						</div>
					</Col>
				)}
			</Row>
			<Row>
				<Col sm={8}></Col>
				<Col sm={4} style={{ textAlign: "right" }}>
					{/* <Button
						name="confirm"
						label="Add Product"
						classes="btn-empty"
						onClick={() => {
							setEditId(0);
							setEditIsOpen(true);
						}}
					/> */}
				</Col>
			</Row>
			<ProductsTable
				products={products}
				sortColumn={sortColumn}
				onEdit={handleEdit}
				onDelete={handleDelete}
				onSort={handleSort}
				onCheck={handleCheck}
			/>
			<div className="tableControls">
				<p className="tableInfo">
					<b>{products.length}</b> records out of <b>{total}</b>
				</p>
				<Pagination
					itemsCount={total}
					pageSize={pageSize}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>
			<Modal
				isOpen={modalEditIsOpen}
				onRequestClose={() => setEditIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 20, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setEditIsOpen(false)}
					/>
				</div>
				<ProductEditModal
					productId={editId}
					onClose={() => {
						setEditIsOpen(false);
						updateProducts();
					}}
					onEdit={(product: any, formData: any) => {
						handleEditClose(product, formData);
					}}
					onDuplicate={(product: any) => {
						handleDuplicate(product);
					}}
				/>
			</Modal>
			<div ref={(ref: any) => (productsRef = ref)}>
				{checkedProducts.length > 0 &&
					checkedProducts.map((product: any) => {
						return (
							<div key={product}>
								<ProductPagePrint
									setComponentRef={() => {}}
									style={{ display: "none" }}
									propsProductId={product}
								/>
							</div>
						);
					})}
			</div>
			<Modal
				isOpen={searchModalIsOpen}
				onRequestClose={() => setSearchModalIsOpen(false)}
				ariaHideApp={false}
				style={customStyles2}
			>
				<div
					style={{
						height: 30,
						textAlign: "right",
						marginBottom: 50,
					}}
				>
					<span
						className="text-large"
						style={{ float: "left", fontWeight: 700, paddingLeft: 15 }}
					>
						{localStorage.getItem("language") === "it" ? "Filtri" : "Filters"}
					</span>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setSearchModalIsOpen(false)}
					/>
				</div>
				<SearchFiltersModal
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
					defaultFilters={defaultFilters}
					selectedFiltersApp={selectedFiltersApp}
					setSelectedFiltersApp={setSelectedFiltersApp}
					searchText={searchText}
					setSearchText={setSearchText}
					searchTextApp={searchTextApp}
					setSearchTextApp={setSearchTextApp}
					hideSearchBar={true}
				/>
				<div style={{ textAlign: "right", marginTop: 150 }}>
					<button
						className="btn btn-empty btn-sm"
						style={{ marginTop: 15, marginRight: 15 }}
						onClick={() => setSelectedFiltersApp(resettedFilters)}
					>
						{localStorage.getItem("language") === "it"
							? "RESETTA FILTRI"
							: "RESET FILTERS"}
					</button>
					<button
						type="button"
						className="btn btn-empty "
						style={{ minWidth: 100, marginTop: 15, marginRight: 15 }}
						onClick={() => {
							setSearchModalIsOpen(false);
							setCurrentPage(1);
							setSelectedFilters(selectedFiltersApp);
							setSearchText(searchTextApp);
						}}
					>
						{localStorage.getItem("language") === "it" ? "APPLICA" : "APPLY"}
					</button>
				</div>
			</Modal>
			<ReactTooltip
				id="folders"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{folders.map((folder: any) => {
						return (
							<li
								key={folder.id}
								onClick={() => addToFolder(folder.id, checkedProducts)}
							>
								{folder.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default Products;
