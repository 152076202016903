import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { Store } from "react-notifications-component";

import { getFolders, addFolder, editFolder } from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import Folder from "./folder2";
import ProductModal from "./productModal";
import FolderEditModal from "./folderEditModal";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const MyFolders = () => {
	const history = useHistory();

	const [filters] = useState(defaultData.filters);
	const [modalViewIsOpen, setViewIsOpen] = useState(false);
	const [modalEditIsOpen, setEditIsOpen] = useState(false);

	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedEditFolder, setSelectedEditFolder] = useState(0);

	const [crumbs, setCrumbs]: any = useState([]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		getFolders({}).then((res: any) => {
			setFolders(res[0]);
		});

		if (localStorage.getItem("language") === "it") {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MIE CARTELLE", path: "/myFolders" },
			]);
		} else {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MY FOLDERS", path: "/myFolders" },
			]);
		}
	}, []);

	const [folders, setFolders] = useState([]);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const handleOpenViewModal = (productId: number) => {
		setSelectedViewProduct(productId);
		setViewIsOpen(true);
	};

	const handleOpenEditModal = (folderId: number) => {
		setSelectedEditFolder(folderId);
		setEditIsOpen(true);
	};

	const handleModalOnSave = (folderId: any, folder: any) => {
		setEditIsOpen(false);

		if (folderId) {
			editFolder(folderId, folder).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La cartella è stata modificata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getFolders({}).then((res: any) => {
					setFolders(res[0]);
				});
			});
		} else {
			addFolder(folder).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La cartella è stata creata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getFolders({}).then((res: any) => {
					setFolders(res[0]);
				});
			});
		}
	};

	return (
		<div>
			<Row className="page-header">
				<Col sm={10}>
					<span className="page-title">
						{localStorage.getItem("language") === "it"
							? "MIEI FOLDERS"
							: "MY FOLDERS"}
					</span>
				</Col>
				<Col sm={2} style={{ textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/add.png"}
						alt=""
						data-for="default"
						data-tip="Create new folder"
						style={{ width: 30, cursor: "pointer" }}
						onClick={() => handleOpenEditModal(0)}
					/>
				</Col>
			</Row>
			<Row className="page-content">
				<Col sm={12}>
					{folders.map((folder: any) => {
						return (
							<Folder
								key={folder.id}
								data={folder}
								modalView={handleOpenViewModal}
								onEdit={handleOpenEditModal}
							/>
						);
					})}
				</Col>
			</Row>
			<Modal
				isOpen={modalViewIsOpen}
				onRequestClose={() => setViewIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setViewIsOpen(false)}
					/>
				</div>
				<ProductModal productId={selectedViewProduct} />
				<div style={{ height: 40 }}></div>
			</Modal>
			<Modal
				isOpen={modalEditIsOpen}
				onRequestClose={() => setEditIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setEditIsOpen(false)}
					/>
				</div>
				<FolderEditModal
					folderId={selectedEditFolder}
					onSave={handleModalOnSave}
				/>
			</Modal>
		</div>
	);
};

export default MyFolders;
