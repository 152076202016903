import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import { getUsersFromCompany, shareFolder } from "../utils/apis/all";

import { NavLink } from "react-router-dom";

const Folder = ({ data, modalView, onEdit }: any) => {
	const [totalProducts, setTotalProducts] = useState(data.Products.length);
	const [users, setUsers] = useState([]);
	let loanRef: any;

	useEffect(() => {
		getUsersFromCompany({}).then((res: any) => {
			res = res.map((user: any) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			});
			setUsers(res);
		});
	}, []);

	const handleShare = (id: any) => {
		shareFolder({ folderId: data.id, userId: id }).then((res: any) => {
			////console.log(res);
			ReactTooltip.hide(loanRef);
			Store.addNotification({
				title: "Success",
				message: "Folder shared successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});
		});
	};

	return (
		<div className="products-container">
			<Row className="products-container-header">
				<Col sm={10} className="left">
					<img
						src={process.env.PUBLIC_URL + "/imgs/folder_simple.png"}
						alt=""
					/>

					<NavLink className="bold" to={"/backend/folder/" + data.id}>
						<span>{data.title}</span>
					</NavLink>
				</Col>
				<Col sm={2} className="right">
					<img
						src={process.env.PUBLIC_URL + "/imgs/edit.png"}
						alt=""
						style={{ marginRight: 10 }}
						data-for="default"
						data-tip="Edit"
						onClick={() => onEdit(data.id)}
					/>
					<span
						ref={(ref) => (loanRef = ref)}
						data-tip
						data-for="loans"
						data-event="blur"
					>
						<img
							src={process.env.PUBLIC_URL + "/imgs/share.png"}
							alt=""
							data-for="default"
							data-tip="Share"
							style={{ width: 20, marginRight: 15, cursor: "pointer" }}
							onClick={() => {
								ReactTooltip.show(loanRef);
							}}
						/>
					</span>
				</Col>
			</Row>
			<Row className="products-container-body">
				{data.Products.map((product: any, index: any) => {
					if (index < 11)
						return (
							<Col key={product.id} sm={1} className="container-product">
								<img
									src={product.Medias?.length ? product.Medias[0].url : ""}
									alt=""
									onClick={() => {
										const w = window.open(
											window.location.href.split("backend/")[0] +
												"product/" +
												product.id,
											"_blank"
										);
										if (w) {
											w.focus(); // okay now
										}
									}}
								/>
							</Col>
						);
					else if (index == 11)
						return (
							<Col sm={1} key={0} className="container-product-other">
								<div>
									<span>{"+ " + (totalProducts - index)}</span>
								</div>
							</Col>
						);
				})}
			</Row>
			<ReactTooltip
				id="loans"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{users.map((user: any) => {
						return (
							<li key={user.id} onClick={() => handleShare(user.id)}>
								{user.name}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default Folder;
