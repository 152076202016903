import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { Store } from "react-notifications-component";

import {
	getFolders,
	addFolder,
	editFolder,
	shareFolder,
	getUsersFromCompany,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import Folder from "./folder";
import ProductModal from "./productModal";
import FolderEditModal from "./folderEditModal";

import translations from "../translations.json";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const MyFolders = () => {
	const history = useHistory();

	const [language, setLanguage]: any = useState("en");

	const [users, setUsers] = useState([]);

	const [selectedFolderId, setSelectedFolderId] = useState(0);

	let loanRef: any;

	const [filters, setFilters]: any = useState([
		{
			id: 1,
			label:
				translations["cat1Frontend"][
					language as keyof typeof translations["cat1Frontend"]
				],
			image: "shoes/flat_shoes.png",
		},
		{
			id: 2,
			label:
				translations["cat2Frontend"][
					language as keyof typeof translations["cat2Frontend"]
				],
			image: "shoes/high_heel.png",
		},
		{
			id: 3,
			label:
				translations["cat3Frontend"][
					language as keyof typeof translations["cat3Frontend"]
				],
			image: "shoes/sandals.png",
		},
		{
			id: 4,
			label:
				translations["cat4Frontend"][
					language as keyof typeof translations["cat4Frontend"]
				],
			image: "shoes/boots.png",
		},
		{
			id: 6,
			label:
				translations["cat6Frontend"][
					language as keyof typeof translations["cat6Frontend"]
				],
			image: "shoes/sneakers.png",
		},
	]);

	const [modalViewIsOpen, setViewIsOpen] = useState(false);
	const [modalEditIsOpen, setEditIsOpen] = useState(false);

	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedEditFolder, setSelectedEditFolder] = useState(0);

	const [crumbs, setCrumbs]: any = useState([]);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		getFolders({}).then((res: any) => {
			setFolders(res[0]);

			ReactTooltip.rebuild();
		});

		getUsersFromCompany({}).then((res: any) => {
			res = res.map((user: any) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			});
			setUsers(res);

			ReactTooltip.rebuild();
		});

		if (localStorage.getItem("language") === "it") {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MIEI FOLDERS", path: "/myFolders" },
			]);
		} else {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MY FOLDERS", path: "/myFolders" },
			]);
		}
	}, []);

	const loanHandler = (loanRef: any, folderId: number, status: boolean) => {
		if (status) {
			ReactTooltip.hide(loanRef);
			setSelectedFolderId(0);
		} else {
			ReactTooltip.show(loanRef);
			setSelectedFolderId(folderId);
		}
	};

	const [folders, setFolders] = useState([]);

	useEffect(() => {
		setFilters([
			{
				id: 1,
				label:
					translations["cat1Frontend"][
						language as keyof typeof translations["cat1Frontend"]
					],
				image: "shoes/flat_shoes.png",
			},
			{
				id: 2,
				label:
					translations["cat2Frontend"][
						language as keyof typeof translations["cat2Frontend"]
					],
				image: "shoes/high_heel.png",
			},
			{
				id: 3,
				label:
					translations["cat3Frontend"][
						language as keyof typeof translations["cat3Frontend"]
					],
				image: "shoes/sandals.png",
			},
			{
				id: 4,
				label:
					translations["cat4Frontend"][
						language as keyof typeof translations["cat4Frontend"]
					],
				image: "shoes/boots.png",
			},
			{
				id: 6,
				label:
					translations["cat6Frontend"][
						language as keyof typeof translations["cat6Frontend"]
					],
				image: "shoes/sneakers.png",
			},
		]);
	}, [language]);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const handleOpenViewModal = (productId: number) => {
		//setSelectedViewProduct(productId);
		//setViewIsOpen(true);
	};

	const handleOpenEditModal = (folderId: number) => {
		setSelectedEditFolder(folderId);
		setEditIsOpen(true);
	};

	const handleShare = (id: any) => {
		shareFolder({ folderId: selectedFolderId, userId: id }).then((res: any) => {
			//console.log(res);
			ReactTooltip.hide(loanRef);
			Store.addNotification({
				title: "Success",
				message: "Folder shared successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});
		});
	};

	const handleModalOnSave = (folderId: any, folder: any) => {
		setEditIsOpen(false);

		if (folderId) {
			editFolder(folderId, folder).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La cartella è stata modificata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getFolders({}).then((res: any) => {
					setFolders(res[0]);
				});
			});
		} else {
			addFolder(folder).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La cartella è stata creata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getFolders({}).then((res: any) => {
					setFolders(res[0]);
				});
			});
		}
	};

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={setActiveFilter}
							activeFilter={0}
						/>
					</Col>
				</Row>
				<Row className="breacrumbs-row-np">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
					</Col>
				</Row>
				<Row className="page-header">
					<Col sm={10}>
						<span className="page-title">
							{localStorage.getItem("language") === "it"
								? "MIEI FOLDERS"
								: "MY FOLDERS"}
						</span>
					</Col>
					<Col sm={2} style={{ textAlign: "right" }}>
						<img
							src={process.env.PUBLIC_URL + "/imgs/add.png"}
							alt=""
							data-for="default"
							data-tip="Create new folder"
							style={{ width: 30, cursor: "pointer" }}
							onClick={() => handleOpenEditModal(0)}
						/>
					</Col>
				</Row>
				<Row className="page-content">
					<Col sm={12}>
						{folders.map((folder: any) => {
							return (
								<Folder
									key={folder.id}
									data={folder}
									modalView={handleOpenViewModal}
									onEdit={handleOpenEditModal}
									loanHandler={loanHandler}
								/>
							);
						})}
					</Col>
				</Row>
				<Modal
					isOpen={modalViewIsOpen}
					onRequestClose={() => setViewIsOpen(false)}
					ariaHideApp={false}
					style={customStyles}
				>
					<div style={{ height: 40, textAlign: "right" }}>
						<img
							src={process.env.PUBLIC_URL + "/imgs/close.png"}
							alt=""
							style={{ width: 20, cursor: "pointer" }}
							onClick={() => setViewIsOpen(false)}
						/>
					</div>
					<ProductModal productId={selectedViewProduct} />
					<div style={{ height: 40 }}></div>
				</Modal>
				<Modal
					isOpen={modalEditIsOpen}
					onRequestClose={() => setEditIsOpen(false)}
					ariaHideApp={false}
					style={customStyles}
				>
					<div style={{ height: 40, textAlign: "right" }}>
						<img
							src={process.env.PUBLIC_URL + "/imgs/close.png"}
							alt=""
							style={{ width: 20, cursor: "pointer" }}
							onClick={() => setEditIsOpen(false)}
						/>
					</div>
					<FolderEditModal
						folderId={selectedEditFolder}
						onSave={handleModalOnSave}
					/>
				</Modal>
			</Container>
			<ReactTooltip
				id="loans"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{users.map((user: any) => {
						return (
							<li key={user.id} onClick={() => handleShare(user.id)}>
								{user.name}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default MyFolders;
