import { useState } from "react";
import AuthService from "../utils/auth.service";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { NavLink } from "react-router-dom";

import Input from "./common/input";
import Button from "./common/button";

import Navbar from "./navbar";

interface Props {
	setForgotPassword: any;
}

const ForgotPassword = ({ setForgotPassword }: Props) => {
	const [email, setEmail] = useState();
	const [code, setCode] = useState();
	const [password, setPassword] = useState();
	const [step, setStep] = useState(0);
	const [message, setMessage] = useState("");
	const history = useHistory();

	const handleLogin = async (e: any) => {
		if(step === 0) {
			AuthService.forgotPassword(email).then(
				(res) => {
					//history.push("/home");
					setMessage(
						"Enter the 6 digit code sent to your email to reset your password."
					);
					setStep(1);
				},
				(error) => {
					setMessage(
						"Sorry, we couldn't find an account with that email."
					);
				}
			);
		} else if(step === 1) {
			AuthService.checkResetPasswordToken(code, email).then(
				(res) => {
					//history.push("/home");
					setMessage(
						"Enter the new password for your account."
					);
					setStep(2);
				},
				(error) => {
					setMessage(
						"Sorry, the code you entered is incorrect."
					);
				}
			);
		} else if(step === 2) {
			AuthService.resetPassword(code, email, password).then(
				(res) => {
					//history.push("/home");
					setMessage(
						"Your password has been reset. You can now login with your new password."
					);
					setStep(3);
				},
				(error) => {
					setMessage(
						"Sorry, we couldn't reset your password."
					);
				}
			);
		}
		
	};

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleLogin(event);
		}
	};

	return (
		<div className="login">
			<Navbar />
			<div className="login-container">
				<img
					src={process.env.PUBLIC_URL + "/slides/b_17.jpg"}
					id="login-background"
					alt=""
				/>
				<Row>
					<Col sm={8}></Col>
					<Col sm={4} className="login-form-column">
						<div className="login-form" style={{ height: 200 }}>
							<span className="text-extra-large">Forgot Password</span>
							{message !== "" && <p className="login-error">{message}</p>}

							{step === 0 && <Input
								name="username"
								placeholder="Email"
								onChange={(evt: any) => setEmail(evt.target.value)}
								onKeyDown={handleKeyDown}
								icon="mail"
							/>}

							{step === 1 && <Input
								name="code"
								placeholder="Code"
								onChange={(evt: any) => setCode(evt.target.value)}
								onKeyDown={handleKeyDown}
							/>}

							{step === 2 && <Input
								name="password"
								placeholder=""
								type="password"
								onChange={(evt: any) => setPassword(evt.target.value)}
								onKeyDown={handleKeyDown}
							/>}
							
							{step !== 3 && <Button
								name="confirm"
								label="RESET PASSWORD"
								classes="btn-empty btn-full"
								style={{marginTop: 20}}
								onClick={handleLogin}
							/>}

							{step === 3 && <NavLink to="/login">
								<Button
									name="confirm"
									label="LOGIN"
									classes="btn-empty btn-full"
									style={{marginTop: 20}}
									onClick={() => setForgotPassword(false)}
								/>
							</NavLink>}
							
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ForgotPassword;
