import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import Product from "./product";
import { Store } from "react-notifications-component";

import {
	getLoan,
	removeProductFromLoan,
	deleteLoan,
	editLoan,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import Loan from "./loan";
import Button from "./common/button";
import ProductModal from "./productModal";

import translations from "../translations.json";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const LoanPage = () => {
	const history = useHistory();

	const { loanId }: any = useParams();

	const [language, setLanguage]: any = useState("en");

	const [filters, setFilters] = useState([
		{
			id: 1,
			label:
				translations["cat1Backend"][
					language as keyof typeof translations["cat1Backend"]
				],
			image: "shoes/users.png",
		},
		{
			id: 2,
			label:
				translations["cat2Backend"][
					language as keyof typeof translations["cat2Backend"]
				],
			image: "shoes/records.png",
		},
		{
			id: 3,
			label:
				translations["cat3Backend"][
					language as keyof typeof translations["cat3Backend"]
				],
			image: "shoes/folders.png",
		},
		{
			id: 4,
			label:
				translations["cat4Backend"][
					language as keyof typeof translations["cat4Backend"]
				],
			image: "shoes/loans.png",
		},
		{
			id: 5,
			label:
				translations["cat5Backend"][
					language as keyof typeof translations["cat5Backend"]
				],
			image: "shoes/settings.png",
		},
	]);

	const [modalViewIsOpen, setViewIsOpen] = useState(false);

	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedDeleteProduct, setSelectedDeleteProduct] = useState(0);

	const [generalHandlerStatus, setGeneralHandlerStatus] = useState(false);

	const [loan, setLoan] = useState({
		id: 0,
		title: "",
		referencePersonId: 0,
		referencePerson: {
			id: 0,
			firstName: "",
			lastName: "",
		},
		department: "",
		company: "",
		deliveryAddress: "",
		email: "",
		phone: "",
		notes: "",
		shippingDate: null,
		returnDate: "",
		expectedReturnDate: "",
		trackingCode: "",
		userId: 0,
		Products: [],
		accepted: true,
	});

	const [crumbs, setCrumbs] = useState([
		{ name: "HOME", path: "/home" },
		{ name: "LOAN REQUESTS", path: "/backend/home/4" },
	]);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		setFilters([
			{
				id: 1,
				label:
					translations["cat1Backend"][
						language as keyof typeof translations["cat1Backend"]
					],
				image: "shoes/users.png",
			},
			{
				id: 2,
				label:
					translations["cat2Backend"][
						language as keyof typeof translations["cat2Backend"]
					],
				image: "shoes/records.png",
			},
			{
				id: 3,
				label:
					translations["cat3Backend"][
						language as keyof typeof translations["cat3Backend"]
					],
				image: "shoes/folders.png",
			},
			{
				id: 4,
				label:
					translations["cat4Backend"][
						language as keyof typeof translations["cat4Backend"]
					],
				image: "shoes/loans.png",
			},
			{
				id: 5,
				label:
					translations["cat5Backend"][
						language as keyof typeof translations["cat5Backend"]
					],
				image: "shoes/settings.png",
			},
		]);
	}, [language]);

	useEffect(() => {
		getLoan({ loanId }).then((res: any) => {
			setLoan(res);

			const breadcrumbs: any = [
				{ name: "HOME", path: "/" },
				{ name: "LOAN REQUESTS", path: "/backend/home/4" },
			];
			const loanCrumb = {
				name: res.title.toUpperCase(),
				path: "",
			};
			breadcrumbs.push(loanCrumb);
			setCrumbs(breadcrumbs);
		});
	}, []);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/backend/home/" + activeFilter);
	};

	const handleOpenModal = (productId: number) => {
		setSelectedViewProduct(productId);
		setViewIsOpen(true);
	};

	const deleteHandler = (
		deleteRef: any,
		productId: number,
		status: boolean
	) => {
		//console.log(status);
		if (status) {
			ReactTooltip.hide(deleteRef);
			setSelectedDeleteProduct(0);
		} else {
			ReactTooltip.show(deleteRef);
			setSelectedDeleteProduct(productId);
		}
	};

	const removeProduct = (status: boolean) => {
		if (status) {
			removeProductFromLoan(loanId, selectedDeleteProduct).then((res: any) => {
				if (res === 200) {
					Store.addNotification({
						title: "Successo!",
						message:
							"L'item è stato rimosso dalla richiesta di prestito correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});

					setTimeout(function () {
						history.go(0);
					}, 1000);
				} else {
					Store.addNotification({
						title: "Errore!",
						message:
							"C'è stato un errore durante la rimozione dell'item dal prestito",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				}

				setGeneralHandlerStatus(!generalHandlerStatus);
			});
		} else {
			setGeneralHandlerStatus(!generalHandlerStatus);
		}
	};

	function handleDelete() {
		if (window.confirm("Eliminare la loan request selezionata?")) {
			deleteLoan(loanId).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Entità eliminata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
				history.push("/myLoans");
			});
		}
	}

	const handleAccept = () => {
		editLoan(loanId, { accepted: true })
			.then((res: any) => {
				if (!res) {
					Store.addNotification({
						title: "Errore!",
						message:
							"C'è stato un errore nell'accettazione della richiesta di prestito",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				} else {
					Store.addNotification({
						title: "Successo!",
						message: "La richiesta di prestito è stata accettata correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});

					history.push("/backend/home/4");
				}
			})
			.catch((err: any) => {
				//console.log(err);
				Store.addNotification({
					title: "Errore!",
					message: err.response.data.message,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			});
	};

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={setActiveFilter}
							activeFilter={4}
						/>
					</Col>
				</Row>
				<Row className="breacrumbs-row-np">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
					</Col>
				</Row>
				<Row className="">
					<Col sm={4}>
						<span className="loan-title">{loan.title}</span>
						<Row className="loan-details">
							<Col sm={4}>Reference Person</Col>
							<Col sm={8}>
								{loan.referencePerson?.id
									? loan.referencePerson?.firstName +
									  " " +
									  loan.referencePerson?.lastName
									: ""}
							</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Department</Col>
							<Col sm={8}>{loan.department}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Company</Col>
							<Col sm={8}>{loan.company}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Delivery Address</Col>
							<Col sm={8}>{loan.deliveryAddress}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Email</Col>
							<Col sm={8}>{loan.email}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Phone</Col>
							<Col sm={8}>{loan.phone}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Return Date</Col>
							<Col sm={8}>
								{loan.returnDate &&
									new Date(loan.returnDate).toISOString().substring(0, 10)}
							</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4}>Notes</Col>
							<Col sm={8}>{loan.notes}</Col>
						</Row>
						<Row className="loan-details">
							<Col sm={4} style={{ textAlign: "center" }}>
								{!loan.accepted && (
									<img
										src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
										alt=""
										style={{ cursor: "pointer", width: "24px", height: "24px" }}
										data-for="default"
										data-tip={"Delete Loan Request"}
										onClick={() => {
											handleDelete();
										}}
									/>
								)}
							</Col>
							<Col sm={6}>
								{/*<img
									src={process.env.PUBLIC_URL + "/imgs/accept.png"}
									alt=""
									style={{ cursor: "pointer", width: "24px", height: "24px" }}
									data-for="default"
									data-tip={"Accept Loan Request"}
									onClick={() => {
										handleAccept();
									}}
								/> */}
								{!loan.accepted && (
									<Button
										name="confirm"
										label="Accept Loan Request"
										classes="btn-empty"
										onClick={() => {
											handleAccept();
										}}
									/>
								)}
							</Col>
						</Row>
					</Col>
					<Col sm={8}>
						<Row>
							{loan.Products.map((product: any) => {
								return (
									<Col key={product.id} sm={3} className="product-container">
										<Product
											product={product}
											hideActions={true}
											hideFolder={true}
											hideLoan={true}
											deleteHandler={deleteHandler}
											deleteText={"Remove from Loan Request"}
											loans={[]}
											folders={[]}
											loanHandler={() => {}}
											folderHandler={() => {}}
											generalHandler={generalHandlerStatus}
										/>
									</Col>
								);
							})}
						</Row>
					</Col>
				</Row>
				<Modal
					isOpen={modalViewIsOpen}
					onRequestClose={() => setViewIsOpen(false)}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<div style={{ height: 40 }}></div>
					<ProductModal productId={selectedViewProduct} />
					<div style={{ height: 40 }}></div>
				</Modal>
				<ReactTooltip
					id="delete"
					aria-haspopup="true"
					place="top"
					type="light"
					effect="solid"
					className="tooltip2"
					clickable={true}
				>
					<div style={{ textAlign: "center" }}>
						<span
							style={{ display: "block", fontWeight: 700, marginBottom: 10 }}
						>
							Are you sure?
						</span>
						<div style={{ display: "flex" }}>
							<span
								style={{ flex: 1, cursor: "pointer" }}
								onClick={() => removeProduct(true)}
							>
								Yes
							</span>
							<span
								style={{ flex: 1, cursor: "pointer" }}
								onClick={() => removeProduct(false)}
							>
								No
							</span>
						</div>
					</div>
				</ReactTooltip>
			</Container>
		</div>
	);
};

export default LoanPage;
