import axios from "axios";
import Config from "./config";

const { BASE_URL } = Config;

class AuthService {
	login(email, password) {
		return axios
			.post(BASE_URL + "users/authenticate", {
				email,
				password,
			})
			.then((response) => {
				if (response.data.token) {
					localStorage.setItem("user", JSON.stringify(response.data));
				}
				//console.log("AuthService -> Login -> Success");
				//console.log(response);
				return response.data;
			})
			.catch(function (error) {
				console.log("AuthService -> Login -> Error");
				console.log(error);
				throw new Error(error);
				//return error;
			});
	}

	//forgot password
	forgotPassword(email) {
		return axios
			.post(BASE_URL + "users/forgotPassword", {
				email,
			})
			.then((response) => {
				return response.data;
			})
			.catch(function (error) {
				//console.log("AuthService -> ForgotPassword -> Error");
				//console.log(error);
				throw new Error(error);
				//return error;
			});
	}

	checkResetPasswordToken(resetPasswordToken, email) {
		return axios
			.post(BASE_URL + "users/resetPassword", {
				resetPasswordToken,
				email
			})
			.then((response) => {
				return response.data;
			})
			.catch(function (error) {
				//console.log("AuthService -> ForgotPassword -> Error");
				//console.log(error);
				throw new Error(error);
				//return error;
			});
	}

	resetPassword(resetPasswordToken, email, password) {
		return axios
			.post(BASE_URL + "users/resetPassword", {
				resetPasswordToken,
				email,
				password
			})
			.then((response) => {
				return response.data;
			})
			.catch(function (error) {
				//console.log("AuthService -> ForgotPassword -> Error");
				//console.log(error);
				throw new Error(error);
				//return error;
			});
	}

	signup({ user }) {
		return axios
			.post(BASE_URL + "users/register", user)
			.then((response) => {
				if (response.data.token) {
					localStorage.setItem("user", JSON.stringify(response.data));
				}
				//console.log("AuthService -> Login -> Success");
				//console.log(response);
				return response.data;
			})
			.catch(function (error) {
				//console.log("AuthService -> Login -> Error");
				//console.log(error);
				throw new Error(error);
				//return error;
			});
	}

	logout() {
		localStorage.removeItem("user");
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem("user"));
	}
}
export default new AuthService();
