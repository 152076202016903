import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import FsLightbox from "fslightbox-react";

import { getProduct } from "../utils/apis/all";

import ProductTag from "./common/productTag";

const ProductPagePrint = ({
	setComponentRef,
	propsProductId,
	propsProduct,
}: any) => {
	const { productId }: any = useParams();

	const [loanStatus, setLoanStatus] = useState(false);
	const [folderStatus, setFolderStatus] = useState(false);

	const [toggler, setToggler] = useState(false);

	let loanRef: any;
	let folderRef: any;
	let productRef: any;

	const [selectedImg, setSelectedImg] = useState("");

	const [product, setProduct] = useState({
		productCode: "",
		Subcategory: { label: "" },
		Category: { label: "" },
		Brand: { label: "" },
		generalTags: [],
		technicalTags: [],
		managementTags: [],
		Medias: [],
		Items: [
			{
				InStock: {
					label: "",
				},
			},
		],
		descriptionNotes: "",
	});

	useEffect(() => {
		if (propsProduct) {
			setProduct(propsProduct);
			setSelectedImg(propsProduct?.Medias[0]?.url);
		}
	});

	let productName = product.Brand?.label;

	if (product.Category) {
		productName += " / " + product.Category?.label;
	}

	if (product.Subcategory) {
		if (product.Subcategory?.label)
			productName += " / " + product.Subcategory?.label;
	}

	return (
		<div
			ref={(ref: any) => (productRef = ref)}
			id="productToPrint"
			className="pagebreak productToPrint"
		>
			<Container style={{ paddingTop: 30, paddingLeft: 40, paddingRight: 50 }}>
				<Row className="">
					<Col sm={3}></Col>
					<Col sm={6}>
						<Row>
							<Col sm={3} className="single-product-images-print">
								{product.Medias.map((media: any) => {
									return (
										<img
											src={media.url}
											alt=""
											key={media.id}
											onClick={() => setSelectedImg(media.url)}
										/>
									);
								})}

								<FsLightbox
									toggler={toggler}
									sources={product.Medias.map((media: any) => media.url)}
									types={[...new Array(product.Medias?.length).fill("image")]}
								/>
							</Col>
							<Col sm={8} className="single-product-main-image">
								<img
									src={selectedImg}
									alt=""
									onClick={() => setToggler(true)}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{ paddingRight: 0 }}>
						<div className="other-tags print" style={{ marginTop: 0 }}>
							<span>MAIN DESCRIPTION</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						{product.generalTags.map((tag: any) => {
							if (!tag.tags || tag.tags.length == 0) return null;

							return (
								<Row style={{ marginRight: 0 }} key={tag.type}>
									<ProductTag
										key={tag.type}
										type={tag.type}
										tags={tag.tags}
										print={true}
									/>
								</Row>
							);
						})}
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{ paddingRight: 0 }}>
						<div className="other-tags print" style={{ paddingRight: 0 }}>
							<span>PHYSICAL DESCRIPTION</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						{product.technicalTags.map((tag: any) => {
							if (!tag.tags || tag.tags.length == 0) return null;

							return (
								<Row style={{ marginRight: 0 }} key={tag.type}>
									<ProductTag
										key={tag.type}
										type={tag.type}
										tags={tag.tags}
										print={true}
									/>
								</Row>
							);
						})}
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{ paddingRight: 0 }}>
						<div className="other-tags print" style={{ paddingRight: 0 }}>
							<span>MANAGEMENT NOTES</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						{product.managementTags.map((tag: any) => {
							if (!tag.tags || tag.tags.length == 0) return null;

							return (
								<Row style={{ marginRight: 0 }} key={tag.type}>
									<ProductTag
										key={tag.type}
										type={tag.type}
										tags={tag.tags}
										print={true}
									/>
								</Row>
							);
						})}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ProductPagePrint;
