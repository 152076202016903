import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

import "./App.css";

import routes from "./routes";

import Navbar from "./components/navbar";
import Login from "./components/login";
import Signup from "./components/signup";
import Logout from "./components/logout";
import ForgotPassword from "./components/forgotPassword";

interface Props {}

interface State {
	token: string;
	signup: boolean;
	forgotPassword: boolean;
	user: string;
	filtersModalIsOpen: boolean;
}

class App extends React.Component<Props, State> {
	state = {
		token: "",
		signup: false,
		forgotPassword: false,
		user: "",
		filtersModalIsOpen: false,
	};

	constructor(props: any) {
		super(props);

		this.firstOpen();
	}

	firstOpen = async () => {
		const userJSON = await localStorage.getItem("user");
		const user = JSON.parse(userJSON || "{}");
		this.setState({
			token: user.token,
			user: user.firstName + " " + user.lastName,
		});

		const language = await localStorage.getItem("language");
		if (!language) {
			localStorage.setItem("language", "en");
		}
	};

	setToken = (newToken: string) => {
		this.setState({ token: newToken });
	};

	setSignup = (signup: boolean) => {
		this.setState({ signup });
	};

	setForgotPassword = (forgotPassword: boolean) => {
		this.setState({ forgotPassword });
	};

	setFiltersModal = (filtersModalIsOpen: boolean) => {
		this.setState({ filtersModalIsOpen });
	};

	render() {
		if (!this.state.token && !this.state.signup && !this.state.forgotPassword) {
			return (
				<Login
					setToken={this.setToken}
					setSignup={this.setSignup}
					setForgotPassword={this.setForgotPassword}
				/>
			);
		} else if (this.state.signup) {
			return <Signup setSignup={this.setSignup} />;
		} else if (this.state.forgotPassword) {
			return <ForgotPassword setForgotPassword={this.setForgotPassword} />;
		}

		return (
			<React.Fragment>
				<ReactNotifications />
				<Switch>
					{routes.map(({ path, name, Component }: any, key: any) => (
						<Route
							exact
							path={path}
							key={key}
							render={(props: any) => {
								return (
									<div className="">
										<Navbar
											setFiltersModal={this.setFiltersModal}
											filterModal={this.state.filtersModalIsOpen}
										/>
										<Component
											{...props}
											setFiltersModal={this.setFiltersModal}
											filterModal={this.state.filtersModalIsOpen}
										/>
									</div>
								);
							}}
						/>
					))}

					<Route
						path="/logout"
						render={(props) => <Logout setToken={this.setToken} />}
					></Route>

					<Route
						path="/signup"
						render={(props) => <Signup setSignup={this.setSignup} />}
					></Route>

					<Route
						path="/forgotPassword"
						render={(props) => (
							<ForgotPassword setForgotPassword={this.setForgotPassword} />
						)}
					></Route>

					<Route
						path="/login"
						render={(props) => (
							<Login
								setToken={this.setToken}
								setSignup={this.setSignup}
								setForgotPassword={this.setForgotPassword}
							/>
						)}
					></Route>

					<Redirect exact from="/" to="/home" />
				</Switch>
				<ReactTooltip
					id="default"
					place="bottom"
					type="light"
					effect="solid"
					className="tooltip"
				/>
			</React.Fragment>
		);
	}
}

export default App;
