import * as React from "react";
import Table from "../common/table";
import { NavLink } from "react-router-dom";

interface Props {
	rows: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
}

interface State {}

class CustomTable extends React.Component<Props, State> {
	columns = [
		{ path: "label", label: "NAME (EN)" },
		{ path: "label_it", label: "Nome (IT)" },
		{
			key: "delete",
			content: (row: any) => (
				<div className="iconDiv">
					<span
						onClick={() => this.props.onEdit(row.id)}
						className="iconBtn2 edit"
					>
						<img src={process.env.PUBLIC_URL + "/imgs/edit.svg"} alt="" />
					</span>
					<span
						onClick={() => this.props.onDelete(row.id)}
						className="iconBtn2 delete"
					>
						<img
							src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
							alt=""
						/>
					</span>
				</div>
			),
		},
	];

	render() {
		const { rows, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={rows}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default CustomTable;
