import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";

import { NavLink } from "react-router-dom";

const Loan = ({ data, modalView, onEdit, onSend }: any) => {
	const [totalProducts, setTotalProducts] = useState(data.Products.length);

	useEffect(() => {});

	return (
		<div className="products-container">
			<Row className="products-container-header">
				<Col sm={10} className="left">
					<img
						src={process.env.PUBLIC_URL + "/imgs/folder_simple.png"}
						alt=""
					/>

					<NavLink className="bold" to={"/loan/" + data.id}>
						<span>{data.title}</span>
					</NavLink>
				</Col>
				<Col sm={2} className="right">
					{!data.isSentVerifcation && (
						<img
							src={process.env.PUBLIC_URL + "/imgs/edit.png"}
							alt=""
							style={{ marginRight: 10 }}
							data-for="default"
							data-tip="Edit"
							onClick={() => onEdit(data.id)}
						/>
					)}
					{!data.isSentVerifcation && (
						<img
							src={process.env.PUBLIC_URL + "/imgs/send.png"}
							alt=""
							data-for="default"
							data-tip="Send"
							onClick={onSend}
						/>
					)}
				</Col>
			</Row>
			<Row className="products-container-body">
				{data.Products.map((product: any, index: any) => {
					if (index < 11)
						return (
							<Col key={product.id} sm={1} className="container-product">
								<img
									src={product.Medias?.length ? product.Medias[0].url : ""}
									alt=""
									onClick={() => modalView(product.id)}
								/>
							</Col>
						);
					else if (index == 11)
						return (
							<Col sm={1} key={0} className="container-product-other">
								<div>
									<span>{"+ " + (totalProducts - index)}</span>
								</div>
							</Col>
						);
				})}
			</Row>
		</div>
	);
};

export default Loan;
