import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "./common/input";
import Select from "./common/select";
import Button from "./common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";
import AuthService from "../utils/auth.service";

import translations from "../translations.json";

import {
	getProduct,
	getAllModelTypes,
	getAllBrands,
	getAllSeasons,
	getAllCollections,
	getAllCategories,
	getAllSubcategories,
	getAllInStocks,
	getAllBrandThemes,
	getAllSpecificModels,
	getAllGenders,
	getAllYears,
	getAllQuantities,
	getAllMaterials,
	getAllColors,
	getAllHeelTypes,
	getAllHeelHeights,
	getAllDecorativeMotives,
	getAllDecorativeThemes,
	getAllMaterialProcessing,
	getAllSizes,
	getAllGaskets,
	getAllConservationStates,
	getAllCollocations,
	getAllAcquisitions,
	getAllVariants,
	getAllBrandCodes,
	productDeletePhoto,
	deleteProduct,
	getFolders,
	addProductToFolder,
} from "../utils/apis/all";

const inArchvioOptions = [
	{ id: 0, name: "Physical Prototype" },
	{ id: 1, name: "Physical Sample" },
	{ id: 2, name: "Physical Finished Product" },
	{ id: 3, name: "Digital Prototype" },
	{ id: 4, name: "Digital Sample" },
	{ id: 5, name: "Digital Finished Product" },
];

const ProductEditModal = ({ productId, onClose, onEdit, onDuplicate }: any) => {
	const [language, setLanguage]: any = useState("en");

	const [selectedSpecificModels, setSelectedSpecificModels]: any = useState([]);

	useEffect(() => {
		//ReactTooltip.rebuild();
		//setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		getFolders({}).then((res: any) => {
			setFolders(res[0]);
		});

		if (productId) {
			let actualLanguage = localStorage.getItem("language");
			getProduct({ productId, tags: false }).then((res: any) => {
				//console.log("test-");
				if (!res.Years[0]?.id) {
					res.Years = [{ id: -1, name: "-" }];
				} else if (!res.Years[1]?.id) {
					res.Years.push({ id: -1, name: "-" });
				}

				if (!res.Materials[0]?.id) {
					res.Materials = [{ id: -1, name: "-" }];
				} else if (!res.Materials[1]?.id) {
					res.Materials.push({ id: -1, name: "-" });
				} else if (!res.Materials[2]?.id) {
					res.Materials.push({ id: -1, name: "-" });
				}

				if (!res.Colors[0]?.id) {
					res.Colors = [{ id: -1, name: "-" }];
				} else if (!res.Colors[1]?.id) {
					res.Colors.push({ id: -1, name: "-" });
				} else if (!res.Colors[2]?.id) {
					res.Colors.push({ id: -1, name: "-" });
				}

				if (res.SpecificModels.length > 0) {
					setSelectedSpecificModels(
						res.SpecificModels.map((item: any) => item.id)
					);
				}

				setProduct({ ...res });
			});

			getAllModelTypes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setModelTypes(res[0]);
			});

			getAllBrands({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setBrands(res[0]);
			});

			getAllSeasons({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setSeasons(res[0]);
			});

			getAllCollections({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setCollections(res[0]);
			});

			getAllCategories({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setCategories(res[0]);
			});

			getAllSubcategories({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setSubcategories(res[0]);
			});

			getAllBrandCodes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setBrandCodes(res[0]);
			});

			getAllInStocks({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setInStocks(res[0]);
			});

			getAllBrandThemes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setBrandThemes(res[0]);
			});

			getAllSpecificModels({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setSpecificModels(res[0]);
			});

			getAllGenders({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setGenders(res[0]);
			});

			getAllCollocations({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setCollocations(res[0]);
			});

			getAllVariants({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setVariants(res[0]);
			});

			getAllAcquisitions({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setAcquisitions(res[0]);
			});

			getAllMaterialProcessing({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setMaterialProcessings(res[0]);
			});

			getAllGaskets({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setGaskets(res[0]);
			});

			getAllConservationStates({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setConservationStates(res[0]);
			});

			getAllSizes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setSizes(res[0]);
			});

			getAllDecorativeMotives({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setDecorativeMotives(res[0]);
			});

			getAllYears({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setYears(res[0]);
			});

			getAllQuantities({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setQuantities(res[0]);
			});

			getAllMaterials({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setMaterials(res[0]);
			});

			getAllColors({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setColors(res[0]);
			});

			getAllHeelTypes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setHeelTypes(res[0]);
			});

			getAllHeelHeights({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setHeelHeights(res[0]);
			});

			getAllDecorativeThemes({}).then((res: any) => {
				if (actualLanguage != "en") {
					res[0] = res[0].map((item: any) => {
						if (item.label_it) {
							item.name = item.label_it;
						}
						return item;
					});
				}
				res[0].unshift({ id: -1, name: "-" });
				setDecorativeThemes(res[0]);
			});
		}
	}, [productId]);

	let folderRef: any;

	const [modelTypes, setModelTypes] = useState([]);
	const [brands, setBrands] = useState([]);
	const [seasons, setSeasons] = useState([]);
	const [collections, setCollections] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subcategories, setSubcategories] = useState([]);
	const [inStocks, setInStocks] = useState([]);
	const [brandThemes, setBrandThemes] = useState([]);
	const [specificModels, setSpecificModels] = useState([]);
	const [conservationStates, setConservationStates] = useState([]);
	const [genders, setGenders] = useState([]);
	const [gaskets, setGaskets] = useState([]);
	const [sizes, setSizes] = useState([]);
	const [acquisitions, setAcquisitions] = useState([]);
	const [collocations, setCollocations] = useState([]);
	const [decorativeMotives, setDecorativeMotives] = useState([]);
	const [materialProcessings, setMaterialProcessings] = useState([]);
	const [years, setYears] = useState([]);
	const [variants, setVariants] = useState([]);
	const [brandCodes, setBrandCodes] = useState([]);
	const [quantities, setQuantities] = useState([]);
	const [materials, setMaterials]: any = useState([]);
	const [colors, setColors] = useState([]);
	const [heelTypes, setHeelTypes] = useState([]);
	const [heelHeights, setHeelHeights] = useState([]);
	const [decorativeThemes, setDecorativeThemes] = useState([]);

	const [selectedFiles, setSelectedFiles] = useState([]);

	const [folders, setFolders] = useState([]);
	const [folderStatus, setFolderStatus] = useState(false);

	const [selectedMaterials, setSelectedMaterials]: any = useState([]);
	const [selectedColors, setSelectedColors]: any = useState([]);

	const [page, setPage] = useState(0);

	const [randomRenderImages, setRandomRenderImages] = useState(100);
	const [randomRenderSelect, setRandomRenderSelect] = useState(100);

	const [user, setUser] = useState({
		id: 0,
		role: 0,
	});

	const [product, setProduct]: any = useState({
		Brand: { id: 0, label: "" },
		Category: { id: 0, label: "" },
		Subcategory: { id: 0, label: "" },
		Medias: [{ id: 0, url: "" }],
		SpecificModels: [{ id: 0, label: "" }],
		Gaskets: [{ id: 0, label: "" }],
		DecorativeThemes: [{ id: 0, label: "" }],
		MaterialProcessings: [{ id: 0, label: "" }],
		Materials: [
			{ id: 0, label: "" },
			{ id: 0, label: "" },
			{ id: 0, label: "" },
		],
		Colors: [
			{ id: 0, label: "" },
			{ id: 0, label: "" },
			{ id: 0, label: "" },
		],
		id: 0,
		productBrandCode: "",
		modelTypeId: 0,
		inStockId: 0,
		brandId: 0,
		yearId: 0,
		modelName: "",
		Years: [{ id: 0, label: "" }],
		seasonId: 0,
		collectionId: 0,
		categoryId: 0,
		subcategoryId: 0,
		brandThemeId: 0,
		Items: [
			{
				id: 0,
				acquisitionId: 0,
				quantityId: 0,
				sizeId: 0,
				conservationStateId: 0,
				collocationId: 0,
				inStockId: 0,
				type: 0,
			},
		],
		emptyId: 0,
		genderId: 0,
		heelTypeId: 0,
		heelHeightId: 0,
		variantId: 0,
		brandCodeId: 0,
		status: 0,
		showable: false,
		decorativeMotiveId: 0,
		LoadReqeusts: [],
		isLoanable: false,
		dataAcquisizione: "",
		costoAcquisizione: "",
		provenienza: "",
		noteConservazione: "",
		height: "",
	});

	let productName = product.Brand?.label;

	if (product.Category) {
		productName += " / " + product.Category?.label;
	}

	useEffect(() => {
		let language = localStorage.getItem("language")
			? localStorage.getItem("language")
			: "en";
		setLanguage(language);

		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);
	}, []);

	if (product.Subcategory) {
		if (product.Subcategory?.label)
			productName += " / " + product.Subcategory?.label;
	}

	const handleDeleteImage = (mediaId: any) => {
		productDeletePhoto({ productId: product.id, mediaIds: [mediaId] }).then(
			(res: any) => {
				const oldProduct: any = product;
				oldProduct.Medias = oldProduct.Medias.filter(
					(media: any) => media.id !== mediaId
				);
				//console.log(oldProduct.Medias);
				setProduct(oldProduct);

				setTimeout(function () {
					setRandomRenderImages(Math.random() * 10000);
				}, 200);
			}
		);
	};

	const addToFolder = (folderId: number) => {
		addProductToFolder(folderId, productId).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message: "L'item è stato aggiunto alla cartella correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item alla cartella",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		setFolderStatus(false);
		ReactTooltip.hide(folderRef);
	};

	const handleDelete = () => {
		if (window.confirm("Eliminare il prodotto selezionato?")) {
			deleteProduct(productId).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Entità eliminata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
				onClose();
			});
		}
	};

	const handleInput = (e: any) => {
		setProduct((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleInputItem = (e: any) => {
		const oldProduct: any = product;
		oldProduct.Items[0][e.target.name] = parseInt(e.target.value);
		setProduct(oldProduct);
		setTimeout(function () {
			setRandomRenderSelect(Math.random() * 10000);
		}, 100);
	};

	let color = "green";
	if (product.status == 0 || product.status == null) color = "orange";

	let color2 = "green";
	if (!product.showable) color2 = "red";

	return (
		<div>
			<Container>
				<Row>
					<Col sm={10} className="product-modal-title">
						<span className="single-product-name">{productName}</span>
					</Col>
					<Col
						sm={2}
						style={{ textAlign: "right" }}
						className="product-modal-title"
					></Col>
				</Row>
				<Row>
					<Col sm={3}>
						<Row style={{ gap: "15px 0px" }}>
							<Col sm={12}>
								<div className="product-modal-image">
									<img
										src={product.Medias[0]?.url}
										alt="product"
										className="product-modal-image"
									/>
								</div>
							</Col>
							{randomRenderImages &&
								product.Medias.map((media: any) => {
									return (
										<Col sm={4} key={media.id} className="product-images">
											<img
												src={media.url}
												alt=""
												className="product-modal-image"
											/>
											<span
												className="product-remove-image"
												onClick={() => handleDeleteImage(media.id)}
											>
												X
											</span>
										</Col>
									);
								})}
							<Col sm={4} style={{ cursor: "pointer", position: "relative" }}>
								<input
									type="file"
									className="product-modal-image-add"
									multiple
									onChange={(e: any) => {
										setSelectedFiles(Array.from(e.target.files));
									}}
								/>
								{selectedFiles.length > 0 && (
									<span className="files-number">
										{selectedFiles.length} files
									</span>
								)}
							</Col>
						</Row>
					</Col>
					<Col sm={9}>
						<Row>
							<Col sm={2}>
								<span className={"status-color status-color-" + color}></span>
								<span className="status-label">
									{product.status ? "Normalizzato" : "Da Normalizzare"}
								</span>
							</Col>
							<Col sm={2}>
								<span className={"status-color status-color-" + color2}></span>
								<span className="status-label">
									{product.showable ? "Pubblicato" : "Da Pubblicare"}
								</span>
							</Col>
							<Col sm={8} style={{ textAlign: "right" }}>
								<span className="status-label">
									{product.lastEditor
										? product.lastEditor.firstName +
										  " " +
										  product.lastEditor.lastName
										: ""}
								</span>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_nInventario"][
												language as keyof typeof translations["p_nInventario"]
											]
										}
									</span>
									<Input
										name="id"
										placeholder=""
										disabled={true}
										onChange={handleInput}
										value={product.id.toString()}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_codiceArticolo"][
												language as keyof typeof translations["p_codiceArticolo"]
											]
										}
									</span>
									<Select
										name="modelTypeId"
										options={modelTypes}
										selectedItem={product.modelTypeId}
										placeholder="Cod. Articolo Modello"
										onChange={handleInput}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_codiceVariante"][
												language as keyof typeof translations["p_codiceVariante"]
											]
										}
									</span>
									<Select
										name="variantId"
										options={variants}
										selectedItem={product.variantId}
										placeholder="Codice Variante"
										onChange={handleInput}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_codiceArticoloBrand"][
												language as keyof typeof translations["p_codiceArticoloBrand"]
											]
										}
									</span>
									<Select
										name="brandCodeId"
										options={brandCodes}
										selectedItem={product.brandCodeId}
										placeholder="Codice Articolo Brand"
										onChange={handleInput}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_inArchivio"][
												language as keyof typeof translations["p_inArchivio"]
											]
										}
									</span>
									<Select
										name="inStockId"
										options={inStocks}
										selectedItem={product.Items[0]?.inStockId}
										placeholder="In Archvio"
										renderKey={randomRenderSelect}
										onChange={handleInputItem}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										&nbsp;
									</span>
									<Select
										name="type"
										options={[
											{ id: 1, name: "Digitale" },
											{ id: 2, name: "Fisico" },
										]}
										selectedItem={product.Items[0]?.type}
										placeholder="Digitale"
										renderKey={randomRenderSelect}
										onChange={handleInputItem}
									/>
								</div>
							</Col>
							<Col sm={3}>
								<div>
									<span
										className="text-small"
										style={{
											display: "block",
											marginTop: 15,
											marginBottom: 3,
											fontWeight: 600,
										}}
									>
										{
											translations["p_status"][
												language as keyof typeof translations["p_status"]
											]
										}
									</span>
									<Select
										name="status"
										options={[
											{ id: 1, name: "Da Normalizzare" },
											{ id: 2, name: "Normalizzato" },
										]}
										selectedItem={product.status + 1}
										placeholder="Status"
										onChange={(e: any) => {
											setProduct((prev: any) => ({
												...prev,
												[e.target.name]: e.target.value - 1,
											}));
										}}
									/>
								</div>
							</Col>
							{user.role != 4 && (
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_visibilita"][
													language as keyof typeof translations["p_visibilita"]
												]
											}
										</span>
										<Select
											name="showable"
											options={[
												{ id: 1, name: "Da pubblicare" },
												{ id: 2, name: "Pubblicato" },
											]}
											selectedItem={+product.showable + 1}
											placeholder=""
											onChange={(e: any) => {
												setProduct((prev: any) => ({
													...prev,
													showable: Boolean(parseInt(e.target.value) - 1),
												}));
											}}
										/>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col sm={12} style={{ marginTop: 40, marginBottom: 20 }}>
								<span
									className={
										page === 0 ? "product-page-tag active" : "product-page-tag"
									}
									onClick={() => setPage(0)}
								>
									<span className="text-small">Product Identity</span>
								</span>
								<span
									className={
										page === 1 ? "product-page-tag active" : "product-page-tag"
									}
									onClick={() => setPage(1)}
								>
									<span className="text-small">Phisical Description</span>
								</span>
								<span
									className={
										page === 2 ? "product-page-tag active" : "product-page-tag"
									}
									onClick={() => setPage(2)}
								>
									<span className="text-small">Management Notes</span>
								</span>
							</Col>
						</Row>
						{page === 0 && (
							<Row>
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_brand"][
													language as keyof typeof translations["p_brand"]
												]
											}
										</span>
										<Select
											name="brandId"
											options={brands}
											selectedItem={product.brandId}
											placeholder="Brand"
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={3}>
									<Row style={{}}>
										<Col sm={6} style={{ paddingRight: 5 }}>
											<div>
												<span
													className="text-small"
													style={{
														display: "block",
														marginTop: 15,
														marginBottom: 3,
														fontWeight: 600,
													}}
												>
													{
														translations["p_year"][
															language as keyof typeof translations["p_year"]
														]
													}
												</span>
												<Select
													name="year0"
													options={years}
													selectedItem={
														product?.Years[0]?.id ? product.Years[0].id : 0
													}
													placeholder=""
													onChange={(e: any) => {
														let newProduct = product;
														if (e.target.value != "-1" && e.target.value != "0")
															newProduct.Years[0].id = parseInt(e.target.value);
														setProduct({ ...newProduct });
													}}
												/>
											</div>
										</Col>
										<Col sm={6} style={{ paddingLeft: 5 }}>
											<div>
												<span
													className="text-small"
													style={{
														display: "block",
														marginTop: 15,
														marginBottom: 3,
														fontWeight: 600,
													}}
												>
													{
														translations["p_year"][
															language as keyof typeof translations["p_year"]
														]
													}
												</span>
												<Select
													name="year1"
													options={years}
													selectedItem={product.Years[1]?.id}
													placeholder=""
													onChange={(e: any) => {
														let newProduct = product;
														newProduct.Years[1].id = parseInt(e.target.value);
														setProduct({ ...newProduct });
													}}
												/>
											</div>
										</Col>
									</Row>
								</Col>
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_season"][
													language as keyof typeof translations["p_season"]
												]
											}
										</span>
										<Select
											name="seasonId"
											options={seasons}
											selectedItem={product.seasonId}
											placeholder="Stagione"
											onChange={handleInput}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_collection"][
													language as keyof typeof translations["p_collection"]
												]
											}
										</span>
										<Select
											name="collectionId"
											options={collections}
											selectedItem={product.collectionId}
											placeholder="Collezione"
											onChange={handleInput}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_gender"][
													language as keyof typeof translations["p_gender"]
												]
											}
										</span>
										<Select
											name="genderId"
											options={genders}
											selectedItem={product.genderId}
											placeholder="Genere"
											onChange={handleInput}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_category"][
													language as keyof typeof translations["p_category"]
												]
											}
										</span>
										<Select
											name="categoryId"
											options={categories}
											selectedItem={product.categoryId}
											placeholder="Categoria"
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_subCategory"][
													language as keyof typeof translations["p_subCategory"]
												]
											}
										</span>
										<Select
											name="subcategoryId"
											options={subcategories}
											selectedItem={product.subcategoryId}
											placeholder="Classe"
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={6}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 12,
												fontWeight: 600,
											}}
										>
											{
												translations["p_specificaModello"][
													language as keyof typeof translations["p_specificaModello"]
												]
											}
										</span>
										<Row
											style={{
												gap: "10px 15px",
												maxHeight: 130,
												height: 130,
												marginLeft: 0,
												marginRight: 0,
												marginBottom: 10,
												border: "1px solid rgb(0 0 0 / 19%)",
												padding: 10,
												borderRadius: 6,
												overflowY: "scroll",
											}}
										>
											{specificModels?.map((model: any) => {
												if (model.id === -1) return null;
												return (
													<div key={model.id}>
														<div
															className={
																selectedSpecificModels.includes(model.id)
																	? "tag active"
																	: "tag"
															}
															onClick={() => {
																if (selectedSpecificModels.includes(model.id)) {
																	setSelectedSpecificModels(
																		selectedSpecificModels.filter(
																			(item: any) => item !== model.id
																		)
																	);
																} else {
																	setSelectedSpecificModels([
																		...selectedSpecificModels,
																		model.id,
																	]);
																}
															}}
														>
															<span className="text-small">
																{model.label ? model.label : model.id}
															</span>
														</div>
													</div>
												);
											})}
										</Row>
										{/*
											<Select
											name="SpecificModels[0]"
											options={specificModels}
											selectedItem={product.SpecificModels[0]?.id}
											placeholder="Specifica Modello"
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.SpecificModels[0] = {
													id: parseInt(e.target.value),
												};
												setProduct({ ...newProduct });
											}}
										/>
										*/}
									</div>
								</Col>
							</Row>
						)}
						{page === 1 && (
							<Row>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_materials"][
													language as keyof typeof translations["p_materials"]
												]
											}
										</span>
										<Select
											name="material0"
											options={materials}
											selectedItem={product.Materials[0]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Materials[0].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											&#160;
										</span>
										<Select
											name="material1"
											options={materials}
											selectedItem={product.Materials[1]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Materials[1].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											&#160;
										</span>
										<Select
											name="material2"
											options={materials}
											selectedItem={product.Materials[2]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Materials[2].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_colors"][
													language as keyof typeof translations["p_colors"]
												]
											}
										</span>
										<Select
											name="color0"
											options={colors}
											selectedItem={product.Colors[0]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Colors[0].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											&#160;
										</span>
										<Select
											name="color1"
											options={colors}
											selectedItem={product.Colors[1]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Colors[1].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>
								<Col sm={2}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											&#160;
										</span>
										<Select
											name="color2"
											options={colors}
											selectedItem={product.Colors[2]?.id}
											placeholder=""
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Colors[2].id = parseInt(e.target.value);
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_lavorazione"][
													language as keyof typeof translations["p_lavorazione"]
												]
											}
										</span>
										<Select
											name="MaterialProcessings[0]"
											options={materialProcessings}
											selectedItem={product.MaterialProcessings[0]?.id}
											placeholder="Lavorazione"
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.MaterialProcessings[0].id = {
													id: parseInt(e.target.value),
												};
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_lineaTemaBrand"][
													language as keyof typeof translations["p_lineaTemaBrand"]
												]
											}
										</span>
										<Select
											name="DecorativeThemes[0]"
											options={decorativeThemes}
											selectedItem={product.decorativeMotiveId}
											placeholder="Nome Linea/Tema"
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.DecorativeThemes[0] = {
													id: parseInt(e.target.value),
												};
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_motivoDecorativo"][
													language as keyof typeof translations["p_motivoDecorativo"]
												]
											}
										</span>
										<Select
											name="DecorativeThemes[0]"
											options={decorativeMotives}
											selectedItem={product.DecorativeThemes[0]?.id}
											placeholder="Motivo Decorativo"
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.DecorativeThemes[0] = {
													id: parseInt(e.target.value),
												};
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_guarnizione"][
													language as keyof typeof translations["p_guarnizione"]
												]
											}
										</span>
										<Select
											name="Gaskets[0]"
											options={gaskets}
											selectedItem={product.Gaskets[0]?.id}
											placeholder="Guarnizione"
											onChange={(e: any) => {
												let newProduct = product;
												newProduct.Gaskets[0] = {
													id: parseInt(e.target.value),
												};
												setProduct({ ...newProduct });
											}}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_altezzaTacco"][
													language as keyof typeof translations["p_altezzaTacco"]
												]
											}
										</span>
										<Select
											name="heelHeightId"
											options={heelHeights}
											selectedItem={product.heelHeightId}
											placeholder="Altezza Tacco"
											onChange={handleInput}
										/>
									</div>
								</Col>

								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_tipologiaTacco"][
													language as keyof typeof translations["p_tipologiaTacco"]
												]
											}
										</span>
										<Select
											name="heelTypeId"
											options={heelTypes}
											selectedItem={product.heelTypeId}
											placeholder="Tipologia Tacco"
											onChange={handleInput}
										/>
									</div>
								</Col>
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_altezzaTaccoMm"][
													language as keyof typeof translations["p_altezzaTaccoMm"]
												]
											}
										</span>
										<Input
											name="height"
											placeholder=""
											onChange={handleInput}
											value={product.height}
										/>
									</div>
								</Col>
								<Col sm={3}>
									<div>
										<span
											className="text-small"
											style={{
												display: "block",
												marginTop: 15,
												marginBottom: 3,
												fontWeight: 600,
											}}
										>
											{
												translations["p_taglia"][
													language as keyof typeof translations["p_taglia"]
												]
											}
										</span>
										<Select
											name="sizeId"
											options={sizes}
											selectedItem={product.Items[0]?.sizeId}
											placeholder="Misura"
											onChange={handleInputItem}
										/>
									</div>
								</Col>
							</Row>
						)}
						{page === 2 && (
							<>
								<Row>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_statoConservazione"][
														language as keyof typeof translations["p_statoConservazione"]
													]
												}
											</span>
											<Select
												name="conservationStateId"
												options={conservationStates}
												selectedItem={product.Items[0]?.conservationStateId}
												placeholder="Stato Conservazione"
												onChange={handleInputItem}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_noteConservazione"][
														language as keyof typeof translations["p_noteConservazione"]
													]
												}
											</span>

											<Input
												name="noteConservazione"
												placeholder=""
												onChange={handleInput}
												value={product.noteConservazione}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_collocazione"][
														language as keyof typeof translations["p_collocazione"]
													]
												}
											</span>
											<Select
												name="collocationId"
												options={collocations}
												selectedItem={product.Items[0]?.collocationId}
												placeholder="Collocazione"
												onChange={handleInputItem}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_tipologiaAcquisizione"][
														language as keyof typeof translations["p_tipologiaAcquisizione"]
													]
												}
											</span>
											<Select
												name="acquisitionId"
												options={acquisitions}
												selectedItem={product.Items[0]?.acquisitionId}
												placeholder="Acquisizione"
												onChange={handleInputItem}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_provenienza"][
														language as keyof typeof translations["p_provenienza"]
													]
												}
											</span>
											<Input
												name="provenienza"
												placeholder=""
												onChange={handleInput}
												value={product.provenienza}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_dataAcquisizione"][
														language as keyof typeof translations["p_dataAcquisizione"]
													]
												}
											</span>
											<Input
												name="dataAcquisizione"
												onChange={handleInput}
												placeholder="gg/mm/aaaa"
												value={product.dataAcquisizione}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_costoAcquisizione"][
														language as keyof typeof translations["p_costoAcquisizione"]
													]
												}
											</span>
											<Input
												name="costoAcquisizione"
												placeholder=""
												onChange={handleInput}
												value={product.costoAcquisizione}
											/>
										</div>
									</Col>
									<Col sm={3}>
										<div>
											<span
												className="text-small"
												style={{
													display: "block",
													marginTop: 15,
													marginBottom: 3,
													fontWeight: 600,
												}}
											>
												{
													translations["p_isLoanable"][
														language as keyof typeof translations["p_isLoanable"]
													]
												}
											</span>
											<Select
												name="verified"
												options={[
													{ id: 1, name: "No" },
													{ id: 2, name: "Yes" },
												]}
												selectedItem={+product.isLoanable + 1}
												placeholder=""
												onChange={(e: any) => {
													setProduct((prev: any) => ({
														...prev,
														isLoanable: Boolean(parseInt(e.target.value) - 1),
													}));
												}}
											/>
										</div>
									</Col>
								</Row>
								{product?.LoadReqeusts?.length > 0 && (
									<Row style={{ marginTop: 30 }}>
										<Col sm={12}>
											<table className="newTable">
												<thead>
													<tr>
														<th>Nome</th>
														<th>Company</th>
														<th>Delivery Address</th>
														<th>Department</th>
														<th>Email</th>
														<th>Phone</th>
													</tr>
												</thead>
												<tbody>
													{product.LoadReqeusts.map((item: any) => (
														<tr key={item.id}>
															<td>{item.title}</td>
															<td>{item.company}</td>
															<td>{item.deliveryAddress}</td>
															<td>{item.department}</td>
															<td>{item.email}</td>
															<td>{item.phone}</td>
														</tr>
													))}
												</tbody>
											</table>
										</Col>
									</Row>
								)}
							</>
						)}
					</Col>
				</Row>
				<Row style={{ marginTop: product.Medias[0]?.url ? 25 : 30 }}>
					<Col sm={12} style={{ textAlign: "right" }}>
						{productId > 0 && (
							<span
								ref={(ref) => (folderRef = ref)}
								data-tip
								data-for="folders"
								data-event="blur"
								style={{ verticalAlign: "sub" }}
							>
								<img
									src={process.env.PUBLIC_URL + "/new_icons/FOLDER_ADD.svg"}
									alt=""
									style={{ marginTop: 5, width: 22, cursor: "pointer" }}
									data-for="default"
									data-tip="Add To Folder"
									onClick={() => {
										if (!folderStatus) ReactTooltip.show(folderRef);
										else ReactTooltip.hide(folderRef);

										setFolderStatus(!folderStatus);
									}}
								/>
							</span>
						)}
						{productId > 0 && (
							<Button
								name="confirm"
								label="DUPLICA"
								classes="btn-empty"
								style={{ width: 150, marginLeft: 20 }}
								onClick={() => {
									onDuplicate(product);
								}}
							/>
						)}
						<Button
							name="confirm"
							label={
								localStorage.getItem("language") === "it" ? "SALVA" : "SAVE"
							}
							classes="btn-empty"
							style={{ width: 150, marginLeft: 20 }}
							onClick={() => {
								var formData = new FormData();
								selectedFiles.forEach(function (file: any) {
									formData.append("images", file);
								});

								const newProduct = { ...product };

								if (newProduct.Brand?.id == -1 || newProduct.Brand?.id == 0)
									newProduct.Brand.id = null as any;
								if (newProduct.variantId == -1 || newProduct.variantId == 0)
									newProduct.variantId = null as any;
								if (newProduct.brandCodeId == -1 || newProduct.brandCodeId == 0)
									newProduct.brandCodeId = null as any;
								if (
									newProduct.Category?.id == -1 ||
									newProduct.Category?.id == 0
								)
									newProduct.Category.id = null as any;
								if (
									newProduct.Subcategory?.id == -1 ||
									newProduct.Subcategory?.id == 0
								)
									newProduct.Subcategory.id = null as any;
								if (
									newProduct.Medias[0]?.id == -1 ||
									newProduct.Medias[0]?.id == 0
								)
									newProduct.Medias[0].id = null as any;
								if (
									newProduct.SpecificModels[0]?.id == -1 ||
									newProduct.SpecificModels[0]?.id == 0
								)
									newProduct.SpecificModels[0].id = null as any;
								if (
									newProduct.Gaskets[0]?.id == -1 ||
									newProduct.Gaskets[0]?.id == 0
								)
									newProduct.Gaskets[0].id = null as any;
								if (
									newProduct.DecorativeThemes[0]?.id == -1 ||
									newProduct.DecorativeThemes[0]?.id == 0
								)
									newProduct.DecorativeThemes[0].id = null as any;
								if (
									newProduct.MaterialProcessings[0]?.id == -1 ||
									newProduct.MaterialProcessings[0]?.id == 0
								)
									newProduct.MaterialProcessings[0].id = null as any;
								if (newProduct.modelTypeId == -1 || newProduct.modelTypeId == 0)
									newProduct.modelTypeId = null as any;
								if (newProduct.inStockId == -1 || newProduct.inStockId == 0)
									newProduct.inStockId = null as any;
								if (newProduct.brandId == -1 || newProduct.brandId == 0)
									newProduct.brandId = null as any;
								if (newProduct.yearId == -1 || newProduct.yearId == 0)
									newProduct.yearId = null as any;
								if (
									newProduct.Years[0]?.id == -1 ||
									newProduct.Years[0]?.id == 0
								)
									newProduct.Years[0].id = null as any;

								if (
									newProduct.Years[1]?.id == -1 ||
									newProduct.Years[1]?.id == 0
								)
									newProduct.Years[1].id = null as any;

								if (newProduct.seasonId == -1 || newProduct.seasonId == 0)
									newProduct.seasonId = null as any;
								if (
									newProduct.collectionId == -1 ||
									newProduct.collectionId == 0
								)
									newProduct.collectionId = null as any;
								if (newProduct.categoryId == -1 || newProduct.categoryId == 0)
									newProduct.categoryId = null as any;
								if (
									newProduct.subcategoryId == -1 ||
									newProduct.subcategoryId == 0
								)
									newProduct.subcategoryId = null as any;
								if (
									newProduct.brandThemeId == -1 ||
									newProduct.brandThemeId == 0
								)
									newProduct.brandThemeId = null as any;
								if (newProduct.genderId == -1 || newProduct.genderId == 0)
									newProduct.genderId = null as any;
								if (newProduct.heelTypeId == -1 || newProduct.heelTypeId == 0)
									newProduct.heelTypeId = null as any;
								if (
									newProduct.heelHeightId == -1 ||
									newProduct.heelHeightId == 0
								)
									newProduct.heelHeightId = null as any;
								if (newProduct.status == -1 || newProduct.status == 0)
									newProduct.status = null as any;
								if (
									newProduct.decorativeMotiveId == -1 ||
									newProduct.decorativeMotiveId == 0
								)
									newProduct.decorativeMotiveId = null as any;
								if (
									newProduct.Items[0]?.acquisitionId == -1 ||
									newProduct.Items[0]?.acquisitionId == 0
								)
									newProduct.Items[0].acquisitionId = null as any;
								if (
									newProduct.Items[0]?.quantityId == -1 ||
									newProduct.Items[0]?.quantityId == 0
								)
									newProduct.Items[0].quantityId = null as any;
								if (
									newProduct.Items[0]?.sizeId == -1 ||
									newProduct.Items[0]?.sizeId == 0
								)
									newProduct.Items[0].sizeId = null as any;
								if (
									newProduct.Items[0]?.conservationStateId == -1 ||
									newProduct.Items[0]?.conservationStateId == 0
								)
									newProduct.Items[0].conservationStateId = null as any;
								if (
									newProduct.Items[0]?.collocationId == -1 ||
									newProduct.Items[0]?.collocationId == 0
								)
									newProduct.Items[0].collocationId = null as any;
								if (
									newProduct.Items[0]?.inStockId == -1 ||
									newProduct.Items[0]?.inStockId == 0
								)
									newProduct.Items[0].inStockId = null as any;
								if (
									newProduct.Items[0]?.type == -1 ||
									newProduct.Items[0]?.type == 0
								)
									newProduct.Items[0].type = null as any;

								if (
									newProduct.Materials[0]?.id == -1 ||
									newProduct.Materials[0]?.id == 0
								) {
									newProduct.Materials = [];
								} else {
									newProduct.Materials = newProduct.Materials.map(
										(material: any) => {
											if (material.id == -1 || material.id == 0) return false;

											return { id: material.id };
										}
									);

									newProduct.Materials = newProduct.Materials.filter(Boolean);
								}

								if (newProduct.Colors[0]?.id == -1) {
									newProduct.Colors = [];
								} else {
									newProduct.Colors = newProduct.Colors.map((color: any) => {
										if (color.id == -1 || color.id == 0) return false;

										return { id: color.id };
									});

									newProduct.Colors = newProduct.Colors.filter(Boolean);
								}

								//per l'add
								if (newProduct.Brand?.id == null) newProduct.Brand = null;
								if (newProduct.Category?.id == null) newProduct.Category = null;
								if (newProduct.Colors?.length == 0) newProduct.Colors = null;
								if (newProduct.DecorativeThemes[0]?.id == null)
									newProduct.DecorativeThemes = null;
								if (newProduct.Gaskets[0]?.id == null)
									newProduct.Gaskets = null;
								//if (newProduct.LoadRequest.length == 0) newProduct.LoadRequest = null;
								if (newProduct.Materials?.length == 0)
									newProduct.Materials = null;
								if (newProduct.MaterialProcessings[0]?.id == null)
									newProduct.MaterialProcessings = null;
								if (newProduct.SpecificModels[0]?.id == null)
									newProduct.SpecificModels = null;
								if (newProduct.Subcategory?.id == null)
									newProduct.Subcategory = null;
								if (newProduct.Years[0]?.id == null) newProduct.Years = null;

								if (selectedSpecificModels.length > 0) {
									newProduct.SpecificModels = selectedSpecificModels.map(
										(model: any) => {
											return { id: model };
										}
									);
								}

								onEdit(newProduct, formData);
							}}
						/>
						{productId > 0 && (
							<Button
								name="confirm"
								label="ELIMINA"
								classes="btn-empty"
								style={{ width: 150, marginLeft: 20 }}
								onClick={handleDelete}
							/>
						)}
					</Col>
				</Row>
			</Container>
			<ReactTooltip
				id="folders"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{folders.map((folder: any) => {
						return (
							<li key={folder.id} onClick={() => addToFolder(folder.id)}>
								{folder.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default ProductEditModal;
