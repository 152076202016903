import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import {
	getProduct,
	getLoansNotSentVerification,
	getFolders,
	addProductToLoan,
	addProductToFolder,
	getProductsByCategory,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import ProductTag from "./common/productTag";
import Product from "./product";

const ProductModal = ({ productId }: any) => {
	const history = useHistory();

	const [loanStatus, setLoanStatus] = useState(false);
	const [folderStatus, setFolderStatus] = useState(false);

	let loanRef: any;
	let folderRef: any;

	const [loans, setLoans] = useState([]);
	const [folders, setFolders] = useState([]);

	const [filters] = useState(defaultData.filters);
	const [selectedImg, setSelectedImg] = useState(
		process.env.PUBLIC_URL + "/imgs/products/001.png"
	);

	const [relatedItems, setRelatedItems] = useState([]);

	const [product, setProduct] = useState({
		productCode: "",
		Subcategory: { label: "" },
		Brand: { label: "" },
		generalTags: [],
		technicalTags: [],
		managementTags: [],
		Medias: [],
	});

	const [crumbs] = useState([
		{ name: "Home", path: "/home" },
		{ name: "Product", path: "/productPage" },
	]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		getLoansNotSentVerification({}).then((res: any) => {
			setLoans(res[0]);
		});
		getFolders({}).then((res: any) => {
			setFolders(res[0]);
		});

		getProductsByCategory({ categoryId: 1 }).then((res: any) => {
			setRelatedItems(res[0].slice(0, 4));
		});

		setLoanStatus(false);
		setFolderStatus(false);

		window.scrollTo(0, 0);
	}, [productId]);

	useEffect(() => {
		getProduct({ productId: productId }).then((res: any) => {
			setProduct(res);
		});
	}, []);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const addToLoan = (loanId: number) => {
		addProductToLoan(loanId, parseInt(productId)).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message:
						"L'item è stato aggiunto alla richiesta di prestito correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item al prestito",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		ReactTooltip.hide(loanRef);
		setLoanStatus(false);
	};

	const addToFolder = (folderId: number) => {
		addProductToFolder(folderId, parseInt(productId)).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message: "L'item è stato aggiunto alla cartella correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item alla cartella",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		ReactTooltip.hide(folderRef);
		setFolderStatus(false);
	};

	let productName = product.Subcategory?.label;
	if (product.Brand?.label) productName += ", " + product.Brand?.label;

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={setActiveFilter}
							activeFilter={0}
						/>
					</Col>
				</Row>
				<Row className="breacrumbs-row-np">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
					</Col>
				</Row>
				<Row className="">
					<Col sm={6}>
						<Row>
							<Col sm={2} className="single-product-images">
								{product.Medias.map((media: any) => {
									return (
										<img
											src={media.url}
											alt=""
											onClick={() => setSelectedImg(media.url)}
										/>
									);
								})}
							</Col>
							<Col sm={9} className="single-product-main-image">
								<img src={selectedImg} alt="" />
							</Col>
						</Row>
					</Col>
					<Col sm={6}>
						<Row>
							<Col sm={8} style={{ paddingRight: 0 }}>
								<div className="other-tags" style={{ marginTop: 0 }}>
									<span>MAIN DESCRIPTION</span>
								</div>
							</Col>
							<Col sm={4} className="single-product-actions">
								<span>
									<img
										src={process.env.PUBLIC_URL + "/imgs/print.png"}
										alt=""
										data-tip="Print"
										data-for="default"
										onClick={() => {}}
									/>
								</span>
								<span
									ref={(ref) => (folderRef = ref)}
									data-tip
									data-for="folders"
									data-event="blur"
								>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/FOLDER_ADD.svg"}
										alt=""
										data-tip="Add to Folder"
										data-for="default"
										onClick={() => {
											if (folderStatus) {
												ReactTooltip.hide(folderRef);
											} else {
												ReactTooltip.hide(loanRef);
												ReactTooltip.show(folderRef);
											}

											setFolderStatus(!folderStatus);
											setLoanStatus(false);
										}}
									/>
								</span>
								<span
									ref={(ref) => (loanRef = ref)}
									data-tip
									data-for="loans"
									data-event="blur"
								>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/ADD_TO_BOX.svg"}
										alt=""
										data-tip="Add To Loan Request"
										data-for="default"
										onClick={() => {
											if (loanStatus) {
												ReactTooltip.hide(loanRef);
											} else {
												ReactTooltip.hide(folderRef);
												ReactTooltip.show(loanRef);
											}

											setLoanStatus(!loanStatus);
											setFolderStatus(false);
										}}
									/>
								</span>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.generalTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<div className="other-tags">
									<span>PHYSICAL DESCRIPTION</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.technicalTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<div className="other-tags">
									<span>MANAGEMENT NOTES</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								{product.managementTags.map((tag: any) => {
									if (!tag.tags || tag.tags.length == 0) return null;

									return (
										<Row>
											<ProductTag
												key={tag.type}
												type={tag.type}
												tags={tag.tags}
											/>
										</Row>
									);
								})}
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="related-items">
					<Col sm={12}>
						<span>RELATED ITEMS</span>
					</Col>
				</Row>
				<Row className="related-items-content">
					{relatedItems.map((product: any) => {
						return (
							<Col key={product.id} sm={3} className="product-container">
								<Product
									product={product}
									loans={loans}
									folders={folders}
									hideActions={true}
									loanHandler={() => {}}
									folderHandler={() => {}}
									generalHandler={() => {}}
								/>
							</Col>
						);
					})}
				</Row>
			</Container>
			<ReactTooltip
				id="loans"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{loans.map((loan: any) => {
						return (
							<li key={loan.id} onClick={() => addToLoan(loan.id)}>
								{loan.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
			<ReactTooltip
				id="folders"
				aria-haspopup="true"
				place="bottom"
				type="light"
				effect="solid"
				className="tooltip"
				clickable={true}
			>
				<ul className="tooltip-list">
					{folders.map((folder: any) => {
						return (
							<li key={folder.id} onClick={() => addToFolder(folder.id)}>
								{folder.title}
							</li>
						);
					})}
				</ul>
			</ReactTooltip>
		</div>
	);
};

export default ProductModal;
