import * as React from "react";
import Table from "./common/table";
import { NavLink } from "react-router-dom";

import translations from "../translations.json";

interface Props {
	products: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
	onCheck: any;
}

interface State {}

class ProductsTable extends React.Component<Props, State> {
	columns = [
		{
			path: "id",
			label: "",
			content: (product: any) => {
				let color = "#00A84D";
				if (product.status === 0 || product.status == null) color = "#ff5e00";
				if (product.status === 1 && product.showable === false)
					color = "#ffc800";
				if (product.showable === true) color = "#00A84D";

				//console.log("status " + product.status);

				return (
					<div>
						<input
							type="checkbox"
							name="vehicle1"
							value=""
							className="product-select-checkbox"
							onClick={() => {
								this.props.onCheck(product.id);
							}}
						/>
						<span
							style={{
								width: 14,
								height: 14,
								marginRight: 20,
								display: "inline-block",
								backgroundColor: color,
								borderRadius: "50%",
								verticalAlign: "middle",
							}}
						></span>
						<img
							src={product.Medias[0]?.url}
							alt=""
							style={{ width: 40, verticalAlign: "middle" }}
						/>
					</div>
				);
			},
		},
		{
			path: "productCode",
			label:
				localStorage.getItem("language") === "it"
					? "CODICE ART."
					: "PRODUCT CODE",
		},
		{
			path: "Brand",
			label: "BRAND",
			sortValue: "label",
			content: (product: any) => product.Brand?.label,
		},
		{
			path: "Season",
			label: localStorage.getItem("language") === "it" ? "STAGIONE" : "SEASON",
			sortValue: "label",
			content: (product: any) =>
				localStorage.getItem("language") === "it" && product.Season?.label_it
					? product.Season?.label_it
					: product.Season?.label,
		},
		{
			path: "Year",
			label: localStorage.getItem("language") === "it" ? "ANNO" : "YEAR",
			sortValue: "label",
			content: (product: any) =>
				localStorage.getItem("language") === "it" && product.Years[0]?.label_it
					? product.Years[0]?.label_it
					: product.Years[0]?.label,
		},
		{
			path: "Subcategory.label",
			label:
				localStorage.getItem("language") === "it" ? "CLASSE" : "SUB CATEGORY",
			content: (product: any) =>
				localStorage.getItem("language") === "it" &&
				product.Subcategory?.label_it
					? product.Subcategory?.label_it
					: product.Subcategory?.label,
		},
		/* { path: "ModelType.label", label: "MODELLO" }, */
		{
			path: "updatedAt",
			label:
				localStorage.getItem("language") === "it"
					? "ULTIMA MODIFICA"
					: "LAST EDIT",
			content: (product: any) => {
				let date = new Date(product.updatedAt);
				let result =
					(date.getDate() < 10 ? "0" : "") +
					date.getDate() +
					"/" +
					(date.getMonth() < 10 ? "0" : "") +
					(date.getMonth() + 1) +
					"/" +
					date.getFullYear() +
					" " +
					(date.getHours() < 10 ? "0" : "") +
					date.getHours() +
					":" +
					(date.getMinutes() < 10 ? "0" : "") +
					date.getMinutes();

				if (product.lastEditor) {
					result +=
						" (" +
						product.lastEditor?.firstName +
						" " +
						product.lastEditor?.lastName +
						")";
				}

				return result;
			},
		},

		{
			key: "delete",
			content: (product: any) => (
				<div className="iconDiv">
					<span
						onClick={() => this.props.onEdit(product.id)}
						className="iconBtn2 edit"
					>
						<img src={process.env.PUBLIC_URL + "/imgs/edit.svg"} alt="" />
					</span>
					<span
						onClick={() => this.props.onDelete(product.id)}
						className="iconBtn2 delete"
					>
						<img
							src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
							alt=""
						/>
					</span>
				</div>
			),
		},
	];

	render() {
		const { products, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={products}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default ProductsTable;
