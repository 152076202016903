import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { Store } from "react-notifications-component";

import { getAllAdmin, addLoan, editLoan } from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import Loan from "./loan2";
import ProductModal from "./productModal";
import LoanEditModal from "./loanEditModal";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const MyLoans = () => {
	const history = useHistory();

	const [filters] = useState(defaultData.filters);
	const [modalViewIsOpen, setViewIsOpen] = useState(false);
	const [modalEditIsOpen, setEditIsOpen] = useState(false);

	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedEditLoan, setSelectedEditLoan] = useState(0);

	const [crumbs] = useState([
		{ name: "HOME", path: "/home" },
		{ name: "MY LOAN REQUESTS", path: "/myLoans" },
	]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		getAllAdmin({ isSentVerification: true, isAdmin: true }).then(
			(res: any) => {
				setLoans(res[0]);
			}
		);
	}, []);

	const [loans, setLoans] = useState([]);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const handleOpenViewModal = (productId: number) => {
		setSelectedViewProduct(productId);
		setViewIsOpen(true);
	};

	const handleOpenEditModal = (loanId: number) => {
		setSelectedEditLoan(loanId);
		setEditIsOpen(true);
	};

	const handleAccept = (loanId: number) => {
		editLoan(loanId, { accepted: true })
			.then((res: any) => {
				if (!res) {
					Store.addNotification({
						title: "Errore!",
						message:
							"C'è stato un errore nell'accettazione della richiesta di prestito",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				} else {
					Store.addNotification({
						title: "Successo!",
						message: "La richiesta di prestito è stata accettata correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});

					getAllAdmin({}).then((res: any) => {
						setLoans(res[0]);
					});
				}
			})
			.catch((err: any) => {
				//console.log(err);
				Store.addNotification({
					title: "Errore!",
					message: err.response.data.message,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			});
	};

	const handleModalOnSave = (loanId: any, loan: any) => {
		setEditIsOpen(false);

		if (loanId) {
			editLoan(loanId, loan).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La richiesta di prestito è stata modificata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getAllAdmin({}).then((res: any) => {
					setLoans(res[0]);
				});
			});
		} else {
			addLoan(loan).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La richiesta di prestito è stata creata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getAllAdmin({}).then((res: any) => {
					setLoans(res[0]);
				});
			});
		}
	};

	return (
		<div>
			<Row className="page-header">
				<Col sm={10}>
					<span className="page-title">
						{localStorage.getItem("language") === "it"
							? "RICHIESTE DI PRESTITO"
							: "LOAN REQUESTS"}
					</span>
				</Col>
				<Col sm={2} style={{ textAlign: "right" }}></Col>
			</Row>
			<Row className="page-content">
				<Col sm={12}>
					{loans.map((loan: any) => {
						return (
							<Loan
								key={loan.id}
								data={loan}
								modalView={handleOpenViewModal}
								onEdit={handleOpenEditModal}
								onAccept={handleAccept}
							/>
						);
					})}
				</Col>
			</Row>
			<Modal
				isOpen={modalViewIsOpen}
				onRequestClose={() => setViewIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setViewIsOpen(false)}
					/>
				</div>
				<ProductModal productId={selectedViewProduct} />
				<div style={{ height: 40 }}></div>
			</Modal>
			<Modal
				isOpen={modalEditIsOpen}
				onRequestClose={() => setEditIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setEditIsOpen(false)}
					/>
				</div>
				<LoanEditModal loanId={selectedEditLoan} onSave={handleModalOnSave} />
			</Modal>
		</div>
	);
};

export default MyLoans;
