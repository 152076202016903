import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "./common/input";
import Select from "./common/select";
import Button from "./common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import {
	getAllCompanies,
	getAllDepartments,
	addUser,
	getUser,
	editUser,
	getAllBrands,
	getAllYears,
	editUserReplaceBrand,
	editUserReplaceYear,
} from "../utils/apis/all";

const UserEditModal = ({ userId, onClose }: any) => {
	const history = useHistory();

	const [companies, setCompanies] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [brands, setBrands] = useState([]);
	const [years, setYears] = useState([]);
	const [selectedBrands, setSelectedBrands]: any = useState([]);
	const [selectedYears, setSelectedYears]: any = useState([]);

	const [emailClasses, setEmailClasses] = useState("");

	function ValidateEmail(mail: string) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		if (userId) {
			getUser(userId).then((res: any) => {
				setUser(res);
				setSelectedBrands(res.Brands.map((brand: any) => brand.id));
				setSelectedYears(res.Years.map((year: any) => year.id));
			});
		}

		getAllCompanies({}).then((res: any) => {
			setCompanies(res[0]);
		});

		getAllDepartments({}).then((res: any) => {
			setDepartments(res[0]);
		});

		getAllBrands({}).then((res: any) => {
			setBrands(res[0]);
		});

		getAllYears({}).then((res: any) => {
			setYears(res[0]);
		});
	}, [userId]);

	const [user, setUser] = useState({
		firstName: "",
		lastName: "",
		username: "",
		role: 0,
		verified: false,
		password: "",
		email: "",
		departmentString: "",
		companyString: "",
		loanRequestAllowed: false,
		Brands: [{ id: 0, name: "" }],
		Years: [{ id: 0, name: "" }],
	});

	const handleInput = (e: any) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		if (!ValidateEmail(user.email)) {
			setEmailClasses("error");
			return;
		} else {
			setEmailClasses("");
		}

		if (user.role == 3 || user.role == 4) {
			user.loanRequestAllowed = false;
		}

		if (userId == 0) {
			addUser(user)
				.then((res: any) => {
					if (res?.status === 201) {
						Store.addNotification({
							title: "Successo!",
							message: "Utente creato correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la creazione dell'utente",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la creazione dell'utente",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		} else {
			editUser(userId, user)
				.then((res: any) => {
					if (res == 200) {
						editUserReplaceBrand(userId, selectedBrands).then((res: any) => {
							Store.addNotification({
								title: "Successo!",
								message: "Utente modificato correttamente",
								type: "success",
								insert: "top",
								container: "top-right",
								animationIn: ["animate__animated", "animate__fadeIn"],
								animationOut: ["animate__animated", "animate__fadeOut"],
								dismiss: {
									duration: 5000,
								},
							});
							onClose();
						});
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la modifica dell'utente",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'utente",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});

			editUserReplaceYear(userId, selectedYears).then((res: any) => {});
		}
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{userId ? "Modifica Utente" : "Nuovo Utente"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it"
									? "Nome"
									: "First Name"}
							</span>
							<Input
								name="firstName"
								placeholder=""
								onChange={handleInput}
								value={user.firstName}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it"
									? "Cognome"
									: "Last Name"}
							</span>
							<Input
								name="lastName"
								placeholder=""
								onChange={handleInput}
								value={user.lastName}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it"
									? "Azienda"
									: "Company"}
							</span>
							<Input
								name="companyString"
								placeholder=""
								onChange={handleInput}
								value={user.companyString}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it"
									? "Dipartimento"
									: "Department"}
							</span>
							<Input
								name="departmentString"
								placeholder=""
								onChange={handleInput}
								value={user.departmentString}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Email
							</span>
							<Input
								name="email"
								placeholder=""
								onChange={handleInput}
								value={user.email}
								classes={emailClasses}
							/>
						</div>
						{
							<div>
								<span
									className="text-small"
									style={{ display: "block", marginTop: 15, marginBottom: 3 }}
								>
									{localStorage.getItem("language") === "it"
										? "Ruolo/Profilo"
										: "Role/Profile"}
								</span>
								<Select
									name="role"
									options={[
										{ id: 1, name: "Admin" },
										{ id: 2, name: "Top User" },
										{ id: 3, name: "Guest User" },
										{ id: 4, name: "Archive Manager" },
										{ id: 5, name: "Archivist" },
									]}
									selectedItem={user.role + 1}
									placeholder=""
									onChange={(e: any) => {
										setUser({
											...user,
											role: parseInt(e.target.value) - 1,
										});
									}}
								/>
							</div>
						}
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it"
									? "Verificato"
									: "Verified"}
							</span>
							<Select
								name="verified"
								options={[
									{ id: 1, name: "No" },
									{ id: 2, name: "Yes" },
								]}
								selectedItem={+user.verified + 1}
								placeholder=""
								onChange={(e: any) => {
									//console.log(Boolean(parseInt(e.target.value) - 1));
									setUser({
										...user,
										verified: Boolean(parseInt(e.target.value) - 1),
									});
								}}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Password
							</span>
							<Input
								name="password"
								placeholder=""
								type="password"
								onChange={handleInput}
								value={user.password}
							/>
						</div>
						{user.role != 3 && user.role != 4 && (
							<div>
								<span
									className="text-small"
									style={{ display: "block", marginTop: 15, marginBottom: 3 }}
								>
									{localStorage.getItem("language") === "it"
										? "Richiesta di prestito"
										: "Allow Loan Request"}
								</span>
								<Select
									name="loanRequestAllowed"
									options={[
										{ id: 1, name: "No" },
										{ id: 2, name: "Yes" },
									]}
									selectedItem={+user.loanRequestAllowed + 1}
									placeholder=""
									onChange={(e: any) => {
										setUser({
											...user,
											loanRequestAllowed: Boolean(parseInt(e.target.value) - 1),
										});
									}}
								/>
							</div>
						)}
						{user.role == 2 && (
							<div>
								<span
									className="text-small"
									style={{ display: "block", marginTop: 15, marginBottom: 3 }}
								>
									Brands
								</span>
								<Row
									style={{
										gap: "10px 15px",
										maxHeight: 350,
										overflowY: "scroll",
										marginLeft: 0,
										marginRight: 0,
									}}
								>
									{brands.map((brand: any) => {
										return (
											<div key={brand.id}>
												<div
													className={
														selectedBrands?.includes(brand.id)
															? "tag active"
															: "tag"
													}
													onClick={() => {
														//console.log(selectedBrands);
														if (selectedBrands?.includes(brand.id)) {
															setSelectedBrands(
																selectedBrands.filter(
																	(b: any) => b !== brand.id
																)
															);
														} else {
															setSelectedBrands([...selectedBrands, brand.id]);
														}
													}}
												>
													<span className="text-small">
														{brand.label ? brand.label : brand.id}
													</span>
												</div>
											</div>
										);
									})}
								</Row>
							</div>
						)}
						{user.role == 2 && (
							<div>
								<span
									className="text-small"
									style={{ display: "block", marginTop: 15, marginBottom: 3 }}
								>
									Years
								</span>
								<Row
									style={{
										gap: "10px 15px",
										maxHeight: 350,
										overflowY: "scroll",
										marginLeft: 0,
										marginRight: 0,
									}}
								>
									{years.map((year: any) => {
										return (
											<div key={year.id}>
												<div
													className={
														selectedYears?.includes(year.id)
															? "tag active"
															: "tag"
													}
													onClick={() => {
														//console.log(selectedYears);
														if (selectedYears?.includes(year.id)) {
															setSelectedYears(
																selectedYears.filter((b: any) => b !== year.id)
															);
														} else {
															setSelectedYears([...selectedYears, year.id]);
														}
													}}
												>
													<span className="text-small">
														{year.label ? year.label : year.id}
													</span>
												</div>
											</div>
										);
									})}
								</Row>
							</div>
						)}
						<div style={{ marginTop: 15 }}>
							<Button
								name="confirm"
								label={
									localStorage.getItem("language") === "it" ? "SALVA" : "SAVE"
								}
								classes="btn-empty btn-full"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default UserEditModal;
