import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import { addSize, getSize, editSize } from "../../utils/apis/all";

const EditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addSize;
	const getRow = getSize;
	const editRow = editSize;

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);
			});
		}
	}, [editId]);

	const [row, setRow] = useState({
		label: "",
	});

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		if (editId == 0) {
			//console.log("test");
			addRow(row)
				.then((res: any) => {
					//console.log(res);
					if (res?.status === 201) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità creata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la creazione dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la creazione dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		} else {
			editRow(editId, row)
				.then((res: any) => {
					if (res == 200) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità modificata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la modifica dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					//console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		}
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{editId
								? localStorage.getItem("language") === "it"
									? "Modifica record"
									: "Edit record"
								: localStorage.getItem("language") === "it"
								? "Nuovo record"
								: "New record"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								{localStorage.getItem("language") === "it" ? "Nome" : "Name"}
							</span>
							<Input
								name="label"
								placeholder=""
								onChange={handleInput}
								value={row.label}
							/>
						</div>

						<div style={{ marginTop: 15 }}>
							<Button
								name="confirm"
								label={
									localStorage.getItem("language") === "it" ? "SALVA" : "SAVE"
								}
								classes="btn-empty btn-full"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default EditModal;
