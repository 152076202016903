import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import AuthService from "../utils/auth.service";
import { useHistory, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import translations from "../translations.json";

const Navbar = (props: any) => {
	//set const [language, setLanguage] =  get from local storage
	const [language, setLanguage]: any = useState("en");
	const history = useHistory();

	const [user, setUser] = useState({
		id: 0,
		firstName: "",
		role: 0,
		loanRequestAllowed: false,
	});
	const [backend, setBackend] = useState(false);
	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);

		const location = window.location.href;
		if (location.indexOf("backend") > -1) setBackend(true);
		else setBackend(false);
	});

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language") || "en");
	});

	useEffect(() => {
		if (user.role === 4) {
			setUser({ ...user, loanRequestAllowed: false });
		}
	}, []);

	const handleBackend = () => {
		history.push(backend ? "/home" : "/backend/home");
	};

	const handleLogout = () => {
		history.push("/logout");
	};

	return (
		<div className="navbar">
			<Container>
				<Row>
					<Col sm={4}>
						<NavLink
							className=""
							to="home"
							onClick={() => {
								!backend
									? window.location.replace("/home")
									: window.location.replace("/backend/home");
							}}
						>
							<img
								src={process.env.PUBLIC_URL + "/new_icons/LIVING_ARCHIVE.svg"}
								id="main-logo"
							/>
						</NavLink>
					</Col>
					<Col sm={4} style={{ textAlign: "center" }}>
						<NavLink
							className=""
							to="home"
							onClick={() => {
								!backend
									? window.location.replace("/home")
									: window.location.replace("/backend/home");
							}}
						>
							<img
								src={process.env.PUBLIC_URL + "/imgs/logo2.png"}
								id="secondary-logo"
							/>
						</NavLink>
					</Col>
					<Col sm={4}>
						{user.id !== 0 && (
							<div className="menu-icon-container">
								<div
									id="account"
									className="menu-icon"
									data-for="account"
									data-event="click"
									data-tip="test"
									style={{ marginTop: 12 }}
								>
									<img
										src={process.env.PUBLIC_URL + "/new_icons/SINGLE_USER.svg"}
									/>
									<span className="text-extra-small">{user.firstName}</span>
								</div>

								<NavLink
									className=""
									to={backend ? "/home" : "/backend/home"}
								></NavLink>

								{!backend && window.location.href.indexOf("filters") <= -1 && (
									<NavLink className="" to="/filters">
										<div
											className="menu-icon"
											data-tip={
												translations["searchAndFilters"][
													language as keyof typeof translations["searchAndFilters"]
												]
											}
											data-for="default"
										>
											<img
												src={process.env.PUBLIC_URL + "/new_icons/LENTE.svg"}
											/>
										</div>
									</NavLink>
								)}
								{!backend && window.location.href.indexOf("filters") > -1 && (
									<a
										className=""
										href="#"
										onClick={() => {
											props.setFiltersModal(!props.filtersModal);
										}}
									>
										<div
											className="menu-icon"
											data-tip={
												translations["searchAndFilters"][
													language as keyof typeof translations["searchAndFilters"]
												]
											}
											data-for="default"
										>
											<img
												src={process.env.PUBLIC_URL + "/new_icons/LENTE.svg"}
											/>
										</div>
									</a>
								)}
								{!backend && (
									<NavLink className="" to="/myFolders">
										<div
											className="menu-icon"
											data-tip={
												translations["myFolders"][
													language as keyof typeof translations["myFolders"]
												]
											}
											data-for="default"
										>
											<img src={process.env.PUBLIC_URL + "/imgs/folder.png"} />
										</div>
									</NavLink>
								)}
								{!backend && user.loanRequestAllowed && (
									<NavLink className="" to="/myLoans">
										<div
											className="menu-icon"
											data-tip={
												translations["myLoans"][
													language as keyof typeof translations["myLoans"]
												]
											}
											data-for="default"
										>
											<img
												src={
													process.env.PUBLIC_URL + "/new_icons/CLOSED_BOX.svg"
												}
											/>
										</div>
									</NavLink>
								)}
								<a
									style={{
										marginLeft: 15,
										fontWeight: 600,
										cursor: "pointer",
										textTransform: "uppercase",
									}}
									onClick={() => {
										const newLanguage = language == "en" ? "it" : "en";
										localStorage.setItem("language", newLanguage);
										setLanguage(newLanguage);
										window.location.reload();
									}}
								>
									<span className="text-medium">{language}</span>
								</a>
							</div>
						)}
					</Col>
				</Row>
				<ReactTooltip
					id="account"
					aria-haspopup="true"
					place="bottom"
					type="light"
					effect="solid"
					className="tooltip"
					clickable={true}
				>
					<ul className="tooltip-list">
						{(user.role == 0 || user.role == 3 || user.role == 4) && (
							<li onClick={handleBackend}>
								{backend ? "Frontend" : "Backend"}
							</li>
						)}
						<li onClick={handleLogout}>Logout</li>
					</ul>
				</ReactTooltip>
			</Container>
		</div>
	);
};

export default Navbar;
