import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import Input from "./common/input";
import ReactTooltip from "react-tooltip";

const SearchFiltersModal = ({
	defaultFilters,
	selectedFilters,
	setSelectedFilters,
	selectedFiltersApp,
	setSelectedFiltersApp,
	searchText,
	setSearchText,
	searchTextApp,
	setSearchTextApp,
	hideSearchBar,
}: any) => {
	const [selectedType, setSelectedType] = useState("brandId");

	const [language, setLanguage]: any = useState("en");

	useEffect(() => {
		ReactTooltip.rebuild();
		ReactTooltip.hide();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		setSelectedFiltersApp(selectedFilters);
		setSearchTextApp(searchText);
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<Container>
				{hideSearchBar === false && (
					<Row>
						<Col sm={12} style={{ marginBottom: 20 }}>
							<div style={{ marginBottom: 0 }}>
								<Input
									name="search"
									placeholder="Search..."
									style={{ fontSize: 24, paddingLeft: 30 }}
									onKeyDown={(e: any) => {
										if (e.key === "Enter") {
											setSearchText(e.target.value);
										}
									}}
									onChange={(e: any) => {
										setSearchTextApp(e.target.value);
									}}
									value={searchTextApp}
								/>
							</div>
						</Col>
					</Row>
				)}
				<Row style={{ marginBottom: 20 }}>
					<Col sm={12}>
						<div style={{ marginTop: 15 }}>
							{Object.keys(selectedFiltersApp).map((key: any) => {
								if (selectedFiltersApp[key].length > 0)
									return selectedFiltersApp[key].map((filter: any, i: any) => {
										if (!defaultFilters[key]) return null;

										return (
											<span className="filter-tag" key={i}>
												<span className="text-small">
													{
														defaultFilters[key].find(
															(x: any) => x.id === filter
														)?.label
													}{" "}
												</span>
											</span>
										);
									});
							})}
						</div>
					</Col>
				</Row>
				<Row className="">
					<Col sm={3}>
						<div
							className={
								selectedType === "brandId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("brandId")}
						>
							<span>Brand</span>
						</div>
						<div
							className={
								selectedType === "categoryId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("categoryId")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Categoria"
									: "Category"}
							</span>
						</div>
						<div
							className={
								selectedType === "subcategoryId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("subcategoryId")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Sottocategoria"
									: "Subcategory"}
							</span>
						</div>
						<div
							className={
								selectedType === "years" ? "filter-type active" : "filter-type"
							}
							onClick={() => setSelectedType("years")}
						>
							<span>
								{localStorage.getItem("language") === "it" ? "Anno" : "Year"}
							</span>
						</div>
						<div
							className={
								selectedType === "seasonId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("seasonId")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Stagione"
									: "Season"}
							</span>
						</div>
						<div
							className={
								selectedType === "collectionId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("collectionId")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Collezione"
									: "Collection"}
							</span>
						</div>
						<div
							className={
								selectedType === "specificModelId"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("specificModelId")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Dettagli Modello"
									: "Model Details"}
							</span>
						</div>
						<div
							className={
								selectedType === "materials"
									? "filter-type active"
									: "filter-type"
							}
							onClick={() => setSelectedType("materials")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Materiali"
									: "Materials"}
							</span>
						</div>
						<div
							className={
								selectedType === "colors" ? "filter-type active" : "filter-type"
							}
							onClick={() => setSelectedType("colors")}
						>
							<span>
								{localStorage.getItem("language") === "it"
									? "Colori"
									: "Colors"}
							</span>
						</div>
					</Col>
					<Col sm={9}>
						<Row
							style={{ gap: "10px 15px", maxHeight: 350, overflowY: "scroll" }}
						>
							{defaultFilters[selectedType]?.map((filter: any) => {
								if (selectedType === "colors") return;
								return (
									<div key={filter.id}>
										<div
											className={
												selectedFiltersApp[selectedType]?.includes(filter.id)
													? "tag active"
													: "tag"
											}
											onClick={() => {
												if (
													selectedFiltersApp[selectedType]?.includes(filter.id)
												) {
													setSelectedFiltersApp({
														...selectedFiltersApp,
														[selectedType]: selectedFiltersApp[
															selectedType
														].filter((id: any) => id !== filter.id),
													});
												} else {
													setSelectedFiltersApp({
														...selectedFiltersApp,
														[selectedType]: [
															...selectedFiltersApp[selectedType],
															filter.id,
														],
													});
												}
											}}
										>
											<span className="text-small">
												{language == "en" || !filter.label_it
													? filter.label
													: filter.label_it}
											</span>
										</div>
									</div>
								);
							})}

							{selectedType === "colors" &&
								defaultFilters[selectedType]?.map((filter: any) => {
									//console.log(filter.code);
									return (
										<div key={filter.id}>
											<div
												className={
													selectedFiltersApp[selectedType]?.includes(filter.id)
														? "color-tag active"
														: "color-tag"
												}
												style={{
													backgroundColor: filter.code,
												}}
												onClick={() => {
													if (
														selectedFiltersApp[selectedType].includes(filter.id)
													) {
														setSelectedFiltersApp({
															...selectedFiltersApp,
															[selectedType]: selectedFiltersApp[
																selectedType
															].filter((id: any) => id !== filter.id),
														});
													} else {
														setSelectedFiltersApp({
															...selectedFiltersApp,
															[selectedType]: [
																...selectedFiltersApp[selectedType],
																filter.id,
															],
														});
													}
												}}
											></div>
										</div>
									);
								})}
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SearchFiltersModal;
