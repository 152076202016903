import React from "react";

export interface Props {
	name: any;
	label: any;
	onClick?: any;
	classes?: any;
	icon?: any;
	style?: any;
	divStyle?: any;
	disabled?: any;
}

export interface State {}

const Button: React.FunctionComponent<Props> = ({
	name,
	label,
	onClick,
	classes,
	icon,
	style,
	divStyle,
	disabled,
}) => {
	classes = "btn " + classes;

	let iconUrl = "";

	if (icon) iconUrl = process.env.PUBLIC_URL + "/imgs/" + icon;

	return (
		<button
			disabled={disabled}
			style={style}
			name={name}
			id={name}
			className={classes}
			onClick={onClick}
		>
			{icon && <img src={iconUrl} alt="" />}
			<span>{label}</span>
		</button>
	);
};

export default Button;
