import { useState, useEffect } from "react";
import MainFilterItem from "./mainFilterItem";

const MainFilter = ({ filters, activeFilter, setActiveFilter }: any) => {
	useEffect(() => {
		////console.log("Home rendered successfully");
	});

	const filterClick = (id: number) => {
		setActiveFilter(id);
	};

	return (
		<div className="main-filter">
			{filters.map((filter: any) => {
				return (
					<MainFilterItem
						key={filter.id}
						label={filter.label}
						image={filter.image}
						active={activeFilter == filter.id}
						onClick={() => filterClick(filter.id)}
					/>
				);
			})}
		</div>
	);
};

export default MainFilter;
