import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import defaultData from "../data.json";
import { getProductFilters, getProductsFiltered } from "../utils/apis/all";
import Modal from "react-modal";

import MainFilter from "./filters/mainFilter";
import TagFilter from "./filters/tagFilter";
import Breadcrumbs from "./breadcrumbs";
import Products from "./products";
import Pagination from "./pagination";
import SearchFiltersModal from "./searchFiltersModal";

import translations from "../translations.json";

/*
const customStyles = {
	content: {
		top: "45%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "1540px",
		minWidth: "520px",
		width: "90%",
		border: "none",
	},
	overlay: {
		backgroundColor: "rgba(255, 255, 255, 1)",
	},
};
*/

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "1080px",
		width: "1080px",
		minWidth: "540px",
		maxHeight: "800px",
		overflow: "visible",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const resettedFilters = {
	brandId: [],
	brandThemeId: [],
	categoryId: [],
	collectionId: [],
	genderId: [],
	heelHeightId: [],
	heelTypeId: [],
	seasonId: [],
	subcategoryId: [],
	years: [],
};

const Filters = (props: any) => {
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);

	const [language, setLanguage]: any = useState("en");

	const filterBrandId = searchParams.get("brandId");
	const filterYear = searchParams.get("year");
	const filterSeasonId = searchParams.get("seasonId");
	const filterCategoryId = searchParams.get("categoryId");

	const { idActiveFilter, idActiveTag }: any = useParams();

	const [filters, setFilters]: any = useState([
		{
			id: 1,
			label:
				translations["cat1Frontend"][
					language as keyof typeof translations["cat1Frontend"]
				],
			image: "shoes/flat_shoes.png",
		},
		{
			id: 2,
			label:
				translations["cat2Frontend"][
					language as keyof typeof translations["cat2Frontend"]
				],
			image: "shoes/high_heel.png",
		},
		{
			id: 3,
			label:
				translations["cat3Frontend"][
					language as keyof typeof translations["cat3Frontend"]
				],
			image: "shoes/sandals.png",
		},
		{
			id: 4,
			label:
				translations["cat4Frontend"][
					language as keyof typeof translations["cat4Frontend"]
				],
			image: "shoes/boots.png",
		},
		{
			id: 6,
			label:
				translations["cat6Frontend"][
					language as keyof typeof translations["cat6Frontend"]
				],
			image: "shoes/sneakers.png",
		},
	]);
	const [searchText, setSearchText] = useState("");
	const [searchTextApp, setSearchTextApp] = useState("");

	const [selectedFilters, setSelectedFilters]: any = useState({
		brandId: [],
		brandThemeId: [],
		categoryId: [],
		collectionId: [],
		genderId: [],
		heelHeightId: [],
		heelTypeId: [],
		seasonId: [],
		subcategoryId: [],
		specificModelId: [],
		years: [],
		materials: [],
		colors: [],
	});
	const [selectedFiltersApp, setSelectedFiltersApp]: any = useState({
		brandId: [],
		brandThemeId: [],
		categoryId: [],
		collectionId: [],
		genderId: [],
		heelHeightId: [],
		heelTypeId: [],
		seasonId: [],
		subcategoryId: [],
		specificModelId: [],
		years: [],
		materials: [],
		colors: [],
	});
	const [defaultFilters, setDefaultFilters]: any = useState({});

	const [products, setProducts]: any = useState([]);
	const [crumbs, setCrumbs] = useState([
		{ name: "HOME", path: "/home" },
		{ name: "FILTERS", path: "/filters" },
	]);

	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState({
		path: "productCode",
		order: "ASC",
	});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		setFilters([
			{
				id: 1,
				label:
					translations["cat1Frontend"][
						language as keyof typeof translations["cat1Frontend"]
					],
				image: "shoes/flat_shoes.png",
			},
			{
				id: 2,
				label:
					translations["cat2Frontend"][
						language as keyof typeof translations["cat2Frontend"]
					],
				image: "shoes/high_heel.png",
			},
			{
				id: 3,
				label:
					translations["cat3Frontend"][
						language as keyof typeof translations["cat3Frontend"]
					],
				image: "shoes/sandals.png",
			},
			{
				id: 4,
				label:
					translations["cat4Frontend"][
						language as keyof typeof translations["cat4Frontend"]
					],
				image: "shoes/boots.png",
			},
			{
				id: 6,
				label:
					translations["cat6Frontend"][
						language as keyof typeof translations["cat6Frontend"]
					],
				image: "shoes/sneakers.png",
			},
		]);
	}, [language]);

	useEffect(() => {
		getProductFilters().then((res) => {
			setDefaultFilters(res);
		});

		searchParams.forEach((value, key) => {
			setSelectedFilters({
				...selectedFilters,
				[key]: [Number(value)],
			});
			setSelectedFiltersApp({
				...selectedFiltersApp,
				[key]: [Number(value)],
			});
			props.setFiltersModal(false);
		});

		ReactTooltip.rebuild();

		props.setFiltersModal(true);
	}, []);

	useEffect(() => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		if ((obj && Object.keys(obj).length > 0) || searchText) {
			props.setFiltersModal(false);
			getProductsFiltered({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: currentPage - 1,
				textSearch: searchText,
				filters: obj,
				showable: "true",
			}).then((res: any) => {
				setProducts(res[0]);
				setTotal(res[1]);
			});
		} else {
			setProducts([]);
			setTotal(0);
		}
	}, [JSON.stringify(selectedFilters)]);

	useEffect(() => {
		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		if ((obj && Object.keys(obj).length > 0) || searchText) {
			props.setFiltersModal(false);
			getProductsFiltered({
				sortBy: sortColumn["path"],
				sortDirection: sortColumn["order"],
				perPage: pageSize,
				page: currentPage - 1,
				textSearch: searchText,
				filters: obj,
				showable: "true",
			}).then((res: any) => {
				setProducts(res[0]);
				setTotal(res[1]);
			});
		} else {
			setProducts([]);
			setTotal(0);
		}
	}, [searchText]);

	const handlePageChange = (page: any) => {
		setCurrentPage(page);

		let obj: any = {};
		for (const [key, value] of Object.entries(selectedFilters)) {
			if (Array.isArray(value) && value.length > 0) {
				obj[key] = value;
			}
		}

		getProductsFiltered({
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
			perPage: pageSize,
			page: page - 1,
			filters: obj,
			showable: "true",
		}).then((res: any) => {
			setProducts(res[0]);
			setTotal(res[1]);
		});
	};

	const handleActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const removeFilterTag = (key: any, value: any) => {
		let obj = { ...selectedFilters };
		obj[key] = obj[key].filter((item: any) => item !== value);
		setSelectedFilters(obj);
	};

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = currentPage * pageSize;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={handleActiveFilter}
							activeFilter={0}
						/>
					</Col>
				</Row>

				<Row className="breacrumbs-row">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
						<div style={{ marginTop: 15 }}>
							{Object.keys(selectedFilters).map((key: any) => {
								if (selectedFilters[key].length > 0)
									return selectedFilters[key].map((filter: any, i: any) => {
										if (!defaultFilters[key]) return null;

										return (
											<span className="filter-tag" key={i}>
												<span className="text-small">
													{localStorage.getItem("language") != "en"
														? defaultFilters[key].find(
																(x: any) => x.id === filter
														  )?.label_it
														: defaultFilters[key].find(
																(x: any) => x.id === filter
														  )?.label}{" "}
													<span
														style={{
															position: "absolute",
															top: 5,
															right: 5,
															cursor: "pointer",
														}}
														onClick={() => removeFilterTag(key, filter)}
													>
														x
													</span>
												</span>
											</span>
										);
									});
							})}
						</div>
					</Col>
					<Col sm={2} style={{ textAlign: "right" }}>
						<button
							className="btn btn-empty btn-sm"
							onClick={() => props.setFiltersModal(true)}
						>
							Filters
						</button>
					</Col>
				</Row>

				{total === 0 && (
					<p>I filtri selezionati non hanno presentato risultati.</p>
				)}
				{total !== 0 && <Products products={products} />}

				<Row className="products-table-infos">
					<Col sm={4} style={{ verticalAlign: "middle" }}>
						<span className="text-small">
							<b>{tableInfo1}</b> to <b>{tableInfo2}</b> of <b>{total}</b>
						</span>
					</Col>
					<Col sm={8} style={{ textAlign: "right" }}>
						<Pagination
							itemsCount={total}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={handlePageChange}
						/>
					</Col>
				</Row>
				<Modal
					isOpen={props.filterModal}
					onRequestClose={() => props.setFiltersModal(false)}
					ariaHideApp={false}
					style={customStyles}
				>
					<div>
						<div></div>
						<div
							style={{
								height: 30,
								textAlign: "right",
								marginBottom: 50,
							}}
						>
							<img
								src={process.env.PUBLIC_URL + "/imgs/close.png"}
								alt=""
								style={{ width: 20, cursor: "pointer" }}
								onClick={() => props.setFiltersModal(false)}
							/>
						</div>
					</div>
					<SearchFiltersModal
						selectedFilters={selectedFilters}
						setSelectedFilters={setSelectedFilters}
						defaultFilters={defaultFilters}
						selectedFiltersApp={selectedFiltersApp}
						setSelectedFiltersApp={setSelectedFiltersApp}
						searchText={searchText}
						setSearchText={setSearchText}
						searchTextApp={searchTextApp}
						setSearchTextApp={setSearchTextApp}
						hideSearchBar={false}
					/>
					<div style={{ textAlign: "right", marginTop: 50 }}>
						<button
							className="btn btn-empty btn-sm"
							style={{ marginTop: 15, marginRight: 15 }}
							onClick={() => setSelectedFiltersApp(resettedFilters)}
						>
							{localStorage.getItem("language") === "it"
								? "RESETTA FILTRI"
								: "RESET FILTERS"}
						</button>
						<button
							type="button"
							className="btn btn-empty "
							style={{ minWidth: 100, marginTop: 15, marginRight: 15 }}
							onClick={() => {
								props.setFiltersModal(false);
								setCurrentPage(1);
								setSelectedFilters(selectedFiltersApp);
								setSearchText(searchTextApp);
							}}
						>
							{localStorage.getItem("language") === "it" ? "APPLICA" : "APPLY"}
						</button>
					</div>
				</Modal>
			</Container>
		</div>
	);
};

export default Filters;
