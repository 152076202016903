import { useState, useEffect } from "react";
import Tag from "../common/tag";

const TagFilter = ({
	tags,
	activeTag,
	setActiveTag,
	uppercase = false,
}: any) => {
	useEffect(() => {
		////console.log("Home rendered successfully");
	});

	const [language, setLanguage]: any = useState("en");

	useEffect(() => {
		setLanguage(localStorage.getItem("language"));
	});

	const filterClick = (id: number) => {
		setActiveTag(id);
	};

	return (
		<div className="tags-filter">
			{tags.map((tag: any) => {
				return (
					<Tag
						key={tag.id}
						label={language == "en" ? tag.label : tag.label_it}
						image={tag.image}
						active={activeTag === tag.id}
						onClick={() => filterClick(tag.id)}
						uppercase={uppercase}
					/>
				);
			})}
		</div>
	);
};

export default TagFilter;
