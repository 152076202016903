import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }: any) => {
	// Don't render a single breadcrumb.
	if (!crumbs) {
		return null;
	}

	if (crumbs.length < 1) {
		return null;
	}

	return (
		<div className="breadcrumbs">
			{crumbs.map(({ name, path }: any, key: any) =>
				key + 1 === crumbs.length ? (
					<span key={key} className="">
						{name}
					</span>
				) : (
					<div className="" key={key}>
						<Link className="" to={path}>
							{name}
						</Link>
						<span className="">/</span>
					</div>
				)
			)}
		</div>
	);
};

export default Breadcrumbs;
