import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import { Store } from "react-notifications-component";
import AuthService from "../utils/auth.service";

import { getLoans, addLoan, editLoan } from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import Loan from "./loan";
import ProductModal from "./productModal";
import LoanEditModal from "./loanEditModal";

import translations from "../translations.json";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const MyLoans = () => {
	const history = useHistory();

	const [language, setLanguage]: any = useState("en");

	const [user, setUser] = useState({
		id: 0,
		firstName: "",
		role: 0,
		loanRequestAllowed: false,
	});

	const [filters, setFilters]: any = useState([
		{
			id: 1,
			label:
				translations["cat1Frontend"][
					language as keyof typeof translations["cat1Frontend"]
				],
			image: "shoes/flat_shoes.png",
		},
		{
			id: 2,
			label:
				translations["cat2Frontend"][
					language as keyof typeof translations["cat2Frontend"]
				],
			image: "shoes/high_heel.png",
		},
		{
			id: 3,
			label:
				translations["cat3Frontend"][
					language as keyof typeof translations["cat3Frontend"]
				],
			image: "shoes/sandals.png",
		},
		{
			id: 4,
			label:
				translations["cat4Frontend"][
					language as keyof typeof translations["cat4Frontend"]
				],
			image: "shoes/boots.png",
		},
		{
			id: 6,
			label:
				translations["cat6Frontend"][
					language as keyof typeof translations["cat6Frontend"]
				],
			image: "shoes/sneakers.png",
		},
	]);

	const [modalViewIsOpen, setViewIsOpen] = useState(false);
	const [modalEditIsOpen, setEditIsOpen] = useState(false);

	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedEditLoan, setSelectedEditLoan] = useState(0);

	const [crumbs, setCrumbs]: any = useState([]);

	useEffect(() => {
		ReactTooltip.rebuild();

		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		setFilters([
			{
				id: 1,
				label:
					translations["cat1Frontend"][
						language as keyof typeof translations["cat1Frontend"]
					],
				image: "shoes/flat_shoes.png",
			},
			{
				id: 2,
				label:
					translations["cat2Frontend"][
						language as keyof typeof translations["cat2Frontend"]
					],
				image: "shoes/high_heel.png",
			},
			{
				id: 3,
				label:
					translations["cat3Frontend"][
						language as keyof typeof translations["cat3Frontend"]
					],
				image: "shoes/sandals.png",
			},
			{
				id: 4,
				label:
					translations["cat4Frontend"][
						language as keyof typeof translations["cat4Frontend"]
					],
				image: "shoes/boots.png",
			},
			{
				id: 6,
				label:
					translations["cat6Frontend"][
						language as keyof typeof translations["cat6Frontend"]
					],
				image: "shoes/sneakers.png",
			},
		]);
	}, [language]);

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);

		getLoans({}).then((res: any) => {
			setLoans(res[0]);

			ReactTooltip.rebuild();
		});

		if (localStorage.getItem("language") === "it") {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MIE RICHIESTE DI PRESTITO", path: "/myFolders" },
			]);
		} else {
			setCrumbs([
				{ name: "HOME", path: "/home" },
				{ name: "MY LOAN REQUESTS", path: "/myFolders" },
			]);
		}
	}, []);

	const [loans, setLoans] = useState([]);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const handleOpenViewModal = (productId: number) => {
		//setSelectedViewProduct(productId);
		//setViewIsOpen(true);
	};

	const handleOpenEditModal = (loanId: number) => {
		setSelectedEditLoan(loanId);
		setEditIsOpen(true);
	};

	const handleSend = (loanId: number) => {
		editLoan(loanId, { isSentVerifcation: true })
			.then((res: any) => {
				if (!res) {
					Store.addNotification({
						title: "Errore!",
						message:
							"Tutti i campi della richiesta di prestito devono essere compilati per poterla inviare",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				} else {
					Store.addNotification({
						title: "Successo!",
						message: "La richiesta di prestito è stata inviata correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});

					getLoans({}).then((res: any) => {
						setLoans(res[0]);
					});
				}
			})
			.catch((err: any) => {
				//console.log(err);
				Store.addNotification({
					title: "Errore!",
					message: err.response.data.message,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			});
	};

	const handleModalOnSave = (loanId: any, loan: any) => {
		setEditIsOpen(false);

		if (loanId) {
			editLoan(loanId, loan).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La richiesta di prestito è stata modificata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getLoans({}).then((res: any) => {
					setLoans(res[0]);
				});
			});
		} else {
			addLoan(loan).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "La richiesta di prestito è stata creata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				getLoans({}).then((res: any) => {
					setLoans(res[0]);
				});
			});
		}
	};

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<MainFilter
							filters={filters}
							setActiveFilter={setActiveFilter}
							activeFilter={0}
						/>
					</Col>
				</Row>
				<Row className="breacrumbs-row-np">
					<Col sm={10} style={{ paddingLeft: 0 }}>
						<Breadcrumbs crumbs={crumbs} />
					</Col>
				</Row>
				<Row className="page-header">
					<Col sm={10}>
						<span className="page-title">
							{localStorage.getItem("language") === "it"
								? "MIE RICHIESTE DI PRESTITO"
								: "MY LOAN REQUESTS"}
						</span>
					</Col>
					<Col sm={2} style={{ textAlign: "right" }}>
						{user.loanRequestAllowed && (
							<img
								src={process.env.PUBLIC_URL + "/imgs/add.png"}
								alt=""
								data-for="default"
								data-tip="Create new loan"
								style={{ width: 30, cursor: "pointer" }}
								onClick={() => handleOpenEditModal(0)}
							/>
						)}
					</Col>
				</Row>
				<Row className="page-content">
					<Col sm={12}>
						{loans.map((loan: any) => {
							return (
								<Loan
									key={loan.id}
									data={loan}
									modalView={handleOpenViewModal}
									onEdit={handleOpenEditModal}
									onSend={() => handleSend(loan.id)}
								/>
							);
						})}
					</Col>
				</Row>
				<Modal
					isOpen={modalViewIsOpen}
					onRequestClose={() => setViewIsOpen(false)}
					ariaHideApp={false}
					style={customStyles}
				>
					<div style={{ height: 40, textAlign: "right" }}>
						<img
							src={process.env.PUBLIC_URL + "/imgs/close.png"}
							alt=""
							style={{ width: 20, cursor: "pointer" }}
							onClick={() => setViewIsOpen(false)}
						/>
					</div>
					<ProductModal productId={selectedViewProduct} />
					<div style={{ height: 40 }}></div>
				</Modal>
				<Modal
					isOpen={modalEditIsOpen}
					onRequestClose={() => setEditIsOpen(false)}
					ariaHideApp={false}
					style={customStyles}
				>
					<div style={{ height: 40, textAlign: "right" }}>
						<img
							src={process.env.PUBLIC_URL + "/imgs/close.png"}
							alt=""
							style={{ width: 20, cursor: "pointer" }}
							onClick={() => setEditIsOpen(false)}
						/>
					</div>
					<LoanEditModal loanId={selectedEditLoan} onSave={handleModalOnSave} />
				</Modal>
			</Container>
		</div>
	);
};

export default MyLoans;
