import { useState, useEffect } from "react";

const MainFilterItem = ({ label, image, active, onClick }: any) => {
	useEffect(() => {
		////console.log("Home rendered successfully");
	});

	let classes = "main-filter-item";
	if (active) classes += " active";

	return (
		<div className={classes} onClick={onClick}>
			<img src={process.env.PUBLIC_URL + "/imgs/" + image} alt="" />
			<span className="text-small">{label}</span>
		</div>
	);
};

export default MainFilterItem;
