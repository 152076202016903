import axios from "axios";
import Config from "./config";

const { BASE_URL } = Config;

const api = axios.create({
	baseURL: BASE_URL,
});

//api.defaults.headers.common["Authorization"] = auth();

export default api;
