// @ts-nocheck
import api from "../api";
import { handleParams, handleSuccess, handleError } from "../utils";
import { authHeader } from "../auth.header";

const API_Categories = "categories/";
const API_SubCategories = "subcategories/";
const API_Products = "products/";
const API_Loans = "loanRequest/";
const API_Folders = "folders/";
const API_Users = "users/";
const API_Companies = "companies/";
const API_Departments = "departments/";
const API_Brands = "brands/";
const API_Collections = "collections/";
const API_Seasons = "seasons/";

const API_Acquisitions = "acquisitions/";
const API_BrandThemes = "brandThemes/";
const API_Collocations = "collocations/";
const API_ConservationStates = "conservationStates/";
const API_DecorativeThemes = "decorativeThemes/";
const API_Gaskets = "gaskets/";
const API_Genders = "genders/";
const API_HeelHeights = "heelHeights/";
const API_HeelTypes = "heelTypes/";
const API_MaterialProcessings = "materialProcessings/";
const API_Materials = "materials/";
const API_MaterialTypes = "materialTypes/";
const API_Sizes = "sizes/";
const API_Colors = "colors/";
const API_InStocks = "inStocks/";

const API_Quantities = "quantities/";
const API_DecorativeMotive = "decorativeMotives/";
const API_ModelTypes = "modelTypes/";
const API_Years = "years/";

const API_SpecificModels = "specificModels/";
const API_Variants = "variants/";
const API_BrandCodes = "brandCodes/";

export function getSubCategories({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	//console.log(API_SubCategories + "?" + params);

	return api
		.get(API_SubCategories + "?" + params, config)
		.then((res) => {
			handleSuccess("getSubCategories()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSubCategories()", error);
		});
}

export function getSubCategoriesByCategory({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
	categoryId = 0,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(
			API_SubCategories + "getByCategory/ " + categoryId + "?" + params,
			config
		)
		.then((res) => {
			handleSuccess("getSubCategories()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSubCategories()", error);
		});
}

export function getProductsByCategory({
	sortBy = "productCode",
	sortDirection = "ASC",
	perPage = 12,
	page = 0,
	textSearch = "",
	categoryId = 0,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Products + "getByCategory/" + categoryId + "?" + params, config)
		.then((res) => {
			handleSuccess("gerProductsByCategory()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("gerProductsByCategory()", error);
		});
}

export function getProductsBySubcategory({
	sortBy = "productCode",
	sortDirection = "ASC",
	perPage = 12,
	page = 0,
	textSearch = "",
	subcategoryId = 0,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(
			API_Products + "getBySubcategory/" + subcategoryId + "?" + params,
			config
		)
		.then((res) => {
			handleSuccess("getProductsBySubcategory()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getProductsBySubcategory()", error);
		});
}

export function getProducts({
	sortBy = "productCode",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Products + "?" + params, config)
		.then((res) => {
			handleSuccess("getProducts()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getProducts()", error);
		});
}

export function getRelatedProducts({ productId, limit }) {
	const config = { headers: authHeader() };

	const params = handleParams({
		limit,
	});

	return api
		.get(
			API_Products + "getRelatedProducts/" + productId + "?" + params,
			config
		)
		.then((res) => {
			handleSuccess("getRelatedProducts()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getRelatedProducts()", error);
		});
}

export function getProductFilters() {
	const config = { headers: authHeader() };

	return api
		.get(API_Products + "filters/getAll/", config)
		.then((res) => {
			handleSuccess("getProductFilters()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getProductFilters()", error);
		});
}
/*
export function getProductsFiltered({
	sortBy = "productCode",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
	filters,
}) {
	const config = { headers: authHeader() };

	let data = filters;

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.post(API_Products + "getFilteredProducts?" + params, data, config)
		.then((res) => {
			handleSuccess("getProductsFiltered()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getProductsFiltered()", error);
		});
}
*/

export function getProductsFiltered({
	sortBy = "productCode",
	sortValue = "",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
	filters,
	showable = "",
}) {
	const config = { headers: authHeader() };

	let data = filters;

	const params = handleParams({
		sortBy,
		sortValue,
		sortDirection,
		perPage,
		page,
		textSearch,
		showable,
	});

	return api
		.post(API_Products + "getFilteredProducts?" + params, data, config)
		.then((res) => {
			handleSuccess("getProductsFilteredBackend()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getProductsFilteredBackend()", error);
		});
}

export function getFilteredProductsAdmin({
	sortBy = "productCode",
	sortValue = "",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
	filters,
	showable = "",
}) {
	const config = { headers: authHeader() };

	let data = filters;

	const params = handleParams({
		sortBy,
		sortValue,
		sortDirection,
		perPage,
		page,
		textSearch,
		showable,
	});

	return api
		.post(API_Products + "getFilteredProductsAdmin?" + params, data, config)
		.then((res) => {
			handleSuccess("getFilteredProductsAdmin()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getFilteredProductsAdmin()", error);
		});
}

export function publishBulkProducts({ productIds, showable }) {
	const config = { headers: authHeader() };

	let data = {
		productIds,
		showable,
	};

	return api
		.post(API_Products + "publishBulk", data, config)
		.then((res) => {
			handleSuccess("publishBulkProducts()", res);
			return res;
		})
		.catch(function (error) {
			handleError("publishBulkProducts()", error);
		});
}

export function getProduct({ productId, tags = true }) {
	const config = { headers: authHeader() };

	const params = handleParams({
		tags,
	});

	return api
		.get(API_Products + productId + "?" + params, config)
		.then((res) => {
			handleSuccess("getProduct()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getProduct()", error);
		});
}

export function duplicateProduct({ productId }) {
	const config = { headers: authHeader() };

	return api
		.get(API_Products + "duplicateProduct/" + productId, config)
		.then((res) => {
			handleSuccess("duplicateProduct()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("duplicateProduct()", error);
		});
}

export function addProduct(product) {
	const config = { headers: authHeader() };

	let data = product;

	return api
		.post(API_Products + "create", data, config)
		.then((res) => {
			handleSuccess("addProduct()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("addProduct()", error);
		});
}

export function editProduct(productId, product) {
	const config = { headers: authHeader() };

	let data = product;

	return api
		.put(API_Products + productId, data, config)
		.then((res) => {
			handleSuccess("editProduct()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editProduct()", error);
		});
}

export function deleteProduct(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Products + id, config)
		.then((res) => {
			handleSuccess("deleteProduct()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteProduct()", error);
		});
}

export function productDeletePhoto({ productId, mediaIds }) {
	const config = { headers: authHeader() };

	let data = {
		id: productId,
		mediasId: mediaIds,
	};

	return api
		.post(API_Products + "deletePhotos", data, config)
		.then((res) => {
			handleSuccess("productDeletePhoto()", res);
			return res;
		})
		.catch(function (error) {
			handleError("productDeletePhoto()", error);
		});
}

export function deleteMultipleProducts(productIds) {
	const config = { headers: authHeader() };

	let data = {
		productIds,
	};

	return api
		.put(API_Products + "deleteMultiple", data, config)
		.then((res) => {
			handleSuccess("deleteMultipleProducts()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteMultipleProducts()", error);
		});
}

export function getLoans({
	sortBy = "title",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
	isSentVerification = true,
	isAdmin = false,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
		isSentVerification,
		isAdmin,
	});

	return api
		.get(API_Loans + "?" + params, config)
		.then((res) => {
			handleSuccess("getLoans()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getLoans()", error);
		});
}

export function getLoansNotSentVerification({}) {
	const config = { headers: authHeader() };

	return api
		.get(API_Loans + "getAllNotSentVerification", config)
		.then((res) => {
			handleSuccess("getLoansNotSentVerification()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getLoansNotSentVerification()", error);
		});
}

export function getAllAdmin({
	sortBy = "accepted",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
	isSentVerification = true,
	isAdmin = true,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
		isSentVerification,
		isAdmin,
	});

	return api
		.get(API_Loans + "getAllAdmin?" + params, config)
		.then((res) => {
			handleSuccess("getLoans()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getLoans()", error);
		});
}

export function getLoan({ loanId }) {
	const config = { headers: authHeader() };

	return api
		.get(API_Loans + loanId, config)
		.then((res) => {
			handleSuccess("getLoan()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getLoan()", error);
		});
}

export function deleteLoan(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Loans + id, config)
		.then((res) => {
			handleSuccess("deleteLoan()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteLoan()", error);
		});
}

export function addLoan(loan) {
	const config = { headers: authHeader() };

	if (loan.referencePersonId === 0) loan.referencePersonId = null;

	let data = loan;

	return api
		.post(API_Loans + "create", data, config)
		.then((res) => {
			handleSuccess("addLoan()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addLoan()", error);
		});
}

export function editLoan(loanId, loan) {
	const config = { headers: authHeader() };

	let data = loan;

	return api
		.put(API_Loans + loanId, data, config)
		.then((res) => {
			handleSuccess("editLoan()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editLoan()", error);
		});
}

export function addProductToLoan(loanId, productId) {
	const config = { headers: authHeader() };

	let data = {
		productId: productId,
	};

	return api
		.post(API_Loans + "addProduct/" + loanId, data, config)
		.then((res) => {
			handleSuccess("addProductToLoan()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("addProductToLoan()", error);
		});
}

export function removeProductFromLoan(loanId, productId) {
	const config = { headers: authHeader() };

	let data = {
		productId: productId,
	};

	return api
		.post(API_Loans + "removeProduct/" + loanId, data, config)
		.then((res) => {
			handleSuccess("removeProductFromLoan()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("removeProductFromLoan()", error);
		});
}

export function getFolders({
	sortBy = "title",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Folders + "?" + params, config)
		.then((res) => {
			handleSuccess("getFolders()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getFolders()", error);
		});
}

export function getFolder({ folderId }) {
	const config = { headers: authHeader() };

	return api
		.get(API_Folders + folderId, config)
		.then((res) => {
			handleSuccess("getFolder()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getFolder()", error);
		});
}

export function addFolder(folder) {
	const config = { headers: authHeader() };

	let data = folder;

	return api
		.post(API_Folders + "create", data, config)
		.then((res) => {
			handleSuccess("addFolder()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addFolder()", error);
		});
}

export function shareFolder({ folderId, userId }) {
	const config = { headers: authHeader() };

	let data = {
		userId: userId,
		folderId: folderId,
	};

	return api
		.post(API_Folders + "share", data, config)
		.then((res) => {
			handleSuccess("shareFolder()", res);
			return res;
		})
		.catch(function (error) {
			handleError("shareFolder()", error);
		});
}

export function deleteFolder(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Folders + id, config)
		.then((res) => {
			handleSuccess("deleteFolder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteFolder()", error);
		});
}

export function editFolder(folderId, folder) {
	const config = { headers: authHeader() };

	let data = folder;

	return api
		.put(API_Folders + folderId, data, config)
		.then((res) => {
			handleSuccess("editFolder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editFolder()", error);
		});
}

export function addProductToFolder(folderId, productId) {
	const config = { headers: authHeader() };

	let data = {
		productId: productId,
	};

	return api
		.post(API_Folders + "addProduct/" + folderId, data, config)
		.then((res) => {
			handleSuccess("addProductToFolder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("addProductToFolder()", error);
		});
}

export function removeProductFromFolder(folderId, productId) {
	const config = { headers: authHeader() };

	let data = {
		productId: productId,
	};

	return api
		.post(API_Folders + "removeProduct/" + folderId, data, config)
		.then((res) => {
			handleSuccess("removeProductFromFolder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("removeProductFromFolder()", error);
		});
}

export function getUsers({
	sortBy = "firstName",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Users + "?" + params, config)
		.then((res) => {
			handleSuccess("getUsers()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getUsers()", error);
		});
}

export function getUser(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Users + id, config)
		.then((res) => {
			handleSuccess("getUser()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getUser()", error);
		});
}

export function addUser(user) {
	const config = { headers: authHeader() };

	let data = user;

	return api
		.post(API_Users + "register", data, config)
		.then((res) => {
			handleSuccess("addUser()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addUser()", error);
		});
}

export function editUser(id, user) {
	const config = { headers: authHeader() };

	let data = user;

	return api
		.put(API_Users + id, data, config)
		.then((res) => {
			handleSuccess("editUser()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editUser()", error);
		});
}

export function editUserReplaceBrand(id, brandIds) {
	const config = { headers: authHeader() };

	let data = {
		brandIds: brandIds,
	};

	return api
		.put(API_Users + "replaceBrand/" + id, data, config)
		.then((res) => {
			handleSuccess("editUserReplaceBrand()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editUserReplaceBrand()", error);
		});
}

export function editUserReplaceYear(id, yearIds) {
	const config = { headers: authHeader() };

	let data = {
		yearIds: yearIds,
	};

	return api
		.put(API_Users + "replaceYear/" + id, data, config)
		.then((res) => {
			handleSuccess("editUserReplaceYears()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editUserReplaceYears()", error);
		});
}

export function deleteUser(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Users + id, config)
		.then((res) => {
			handleSuccess("deleteUser()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteUser()", error);
		});
}

export function getCompanies({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Companies + "?" + params, config)
		.then((res) => {
			handleSuccess("getCompanies()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getCompanies()", error);
		});
}

export function getAllCompanies({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Companies + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllCompanies()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllCompanies()", error);
		});
}

export function getAllDepartments({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Departments + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllDepartments()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllDepartments()", error);
		});
}

export function getAllModelTypes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get("modelTypes/?" + params, config)
		.then((res) => {
			handleSuccess("getAllModelTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllModelTypes()", error);
		});
}

export function getAllBrands({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Brands + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllBrands()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllBrands()", error);
		});
}

export function getAllSeasons({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Seasons + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllSeasons()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllSeasons()", error);
		});
}

export function getAllCollections({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Collections + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllCollections()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllCollections()", error);
		});
}

export function getAllCategories({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Categories + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllCategories()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllCategories()", error);
		});
}

export function getAllColors({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Colors + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllColors()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllColors()", error);
		});
}

export function getAllHeelTypes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_HeelTypes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllHeelTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllHeelTypes()", error);
		});
}

export function getAllHeelHeights({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_HeelHeights + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllHeelHeights()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllHeelHeights()", error);
		});
}

export function getAllSubcategories({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_SubCategories + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllSubcategories()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllSubcategories()", error);
		});
}

export function getAllMaterials({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Materials + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllMaterials()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllMaterials()", error);
		});
}

//

export function getUsersFromCompany({
	sortBy = "firstName",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Users + "getUsersFromCompany?" + params, config)
		.then((res) => {
			handleSuccess("getUsersFromCompany()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getUsersFromCompany()", error);
		});
}

export function getAllUsers({
	sortBy = "firstName",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Users + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllUsers()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllUsers()", error);
		});
}

export function getAllInStocks({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_InStocks + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllInStocks()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllInStocks()", error);
		});
}

export function getAllBrandThemes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_BrandThemes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllBrandThemes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllBrandThemes()", error);
		});
}

export function getAllSpecificModels({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_SpecificModels + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllSpecificModels()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllSpecificModels()", error);
		});
}

export function getAllGenders({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Genders + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllGenders()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllGenders()", error);
		});
}

export function getAllVariants({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Variants + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllVariants()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllVariants()", error);
		});
}

export function getAllBrandCodes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_BrandCodes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllBrandCodes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllBrandCodes()", error);
		});
}

export function getAllAcquisitions({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Acquisitions + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllAcquisitions()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllAcquisitions()", error);
		});
}

export function getAllCollocations({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Collocations + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllCollocations()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllCollocations()", error);
		});
}

export function getAllConservationStates({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_ConservationStates + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllConservationStates()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllConservationStates()", error);
		});
}

export function getAllGaskets({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Gaskets + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllGaskets()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllGaskets()", error);
		});
}

export function getAllSizes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Sizes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllSizes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllSizes()", error);
		});
}

export function getAllMaterialProcessing({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_MaterialProcessings + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllMaterialProcessing()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllMaterialProcessing()", error);
		});
}

export function getAllDecorativeMotives({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_DecorativeMotive + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllDecorativeMotives()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllDecorativeMotives()", error);
		});
}

export function getAllQuantities({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Quantities + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllQuantities()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllQuantities()", error);
		});
}

export function getAllYears({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 1,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Years + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllYears()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllYears()", error);
		});
}

/* BRANDS */

export function getBrands({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Brands + "?" + params, config)
		.then((res) => {
			handleSuccess("getBrands()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getBrands()", error);
		});
}

export function deleteBrand(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Brands + id, config)
		.then((res) => {
			handleSuccess("deleteBrand()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteBrand()", error);
		});
}

export function getBrand(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Brands + id, config)
		.then((res) => {
			handleSuccess("getBrand()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getBrand()", error);
		});
}

export function addBrand(brand) {
	const config = { headers: authHeader() };

	let data = brand;

	return api
		.post(API_Brands + "create", data, config)
		.then((res) => {
			handleSuccess("addBrand()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addBrand()", error);
		});
}

export function editBrand(id, brand) {
	const config = { headers: authHeader() };

	let data = brand;

	return api
		.put(API_Brands + id, data, config)
		.then((res) => {
			handleSuccess("editBrand()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editBrand()", error);
		});
}

/* BRANDS */

/* COLLEZIONI */

export function getCollections({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Collections + "?" + params, config)
		.then((res) => {
			handleSuccess("getCollections()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getBrangetCollectionsds()", error);
		});
}

export function deleteCollection(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Collections + id, config)
		.then((res) => {
			handleSuccess("deleteCollection()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteCollection()", error);
		});
}

export function getCollection(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Collections + id, config)
		.then((res) => {
			handleSuccess("getCollection()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getCollection()", error);
		});
}

export function addCollection(collection) {
	const config = { headers: authHeader() };

	let data = collection;

	return api
		.post(API_Collections + "create", data, config)
		.then((res) => {
			handleSuccess("addCollection()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addCollection()", error);
		});
}

export function editCollection(id, collection) {
	const config = { headers: authHeader() };

	let data = collection;

	return api
		.put(API_Collections + id, data, config)
		.then((res) => {
			handleSuccess("editCollection()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editCollection()", error);
		});
}

/* COLLEZIONI */

/* STAGIONI */

export function getSeasons({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Seasons + "?" + params, config)
		.then((res) => {
			handleSuccess("getSeason()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSeason()", error);
		});
}

export function deleteSeason(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Seasons + id, config)
		.then((res) => {
			handleSuccess("deleteSeason()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteSeason()", error);
		});
}

export function getSeason(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Seasons + id, config)
		.then((res) => {
			handleSuccess("getSeason()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getSeason()", error);
		});
}

export function addSeason(season) {
	const config = { headers: authHeader() };

	let data = season;

	return api
		.post(API_Seasons + "create", data, config)
		.then((res) => {
			handleSuccess("addSeason()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addSeason()", error);
		});
}

export function editSeason(id, season) {
	const config = { headers: authHeader() };

	let data = season;

	return api
		.put(API_Seasons + id, data, config)
		.then((res) => {
			handleSuccess("editSeason()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editSeason()", error);
		});
}

/* STAGIONI */

/* API_Acquisitions */

export function getAcquisitions({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Acquisitions + "?" + params, config)
		.then((res) => {
			handleSuccess("getAcquisitions()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("geAcquisitions()", error);
		});
}

export function deleteAcquisition(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Acquisitions + id, config)
		.then((res) => {
			handleSuccess("deleteAcquisition()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteAcquisition()", error);
		});
}

export function getAcquisition(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Acquisitions + id, config)
		.then((res) => {
			handleSuccess("getAcquisition()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getAcquisition()", error);
		});
}

export function addAcquisition(acquisition) {
	const config = { headers: authHeader() };

	let data = acquisition;

	return api
		.post(API_Acquisitions + "create", data, config)
		.then((res) => {
			handleSuccess("addAcquisition()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addAcquisition()", error);
		});
}

export function editAcquisition(id, acquisition) {
	const config = { headers: authHeader() };

	let data = acquisition;

	return api
		.put(API_Acquisitions + id, data, config)
		.then((res) => {
			handleSuccess("editAcquisition()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editAcquisition()", error);
		});
}

/* API_Acquisitions */

/* API_BrandThemes */

export function getBrandThemes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_BrandThemes + "?" + params, config)
		.then((res) => {
			handleSuccess("getBrandThemes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getBrandThemes()", error);
		});
}

export function deleteBrandTheme(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_BrandThemes + id, config)
		.then((res) => {
			handleSuccess("deleteBrandTheme()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteBrandTheme()", error);
		});
}

export function getBrandTheme(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_BrandThemes + id, config)
		.then((res) => {
			handleSuccess("getBrandTheme()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getBrandTheme()", error);
		});
}

export function addBrandTheme(brandTheme) {
	const config = { headers: authHeader() };

	let data = brandTheme;

	return api
		.post(API_BrandThemes + "create", data, config)
		.then((res) => {
			handleSuccess("addBrandTheme()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addBrandTheme()", error);
		});
}

export function editBrandTheme(id, brandTheme) {
	const config = { headers: authHeader() };

	let data = brandTheme;

	return api
		.put(API_BrandThemes + id, data, config)
		.then((res) => {
			handleSuccess("editBrandTheme()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editBrandTheme()", error);
		});
}

/* API_BrandThemes */

/* API_Collocations */

export function getCollocations({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Collocations + "?" + params, config)
		.then((res) => {
			handleSuccess("getCollocation()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getCollocation()", error);
		});
}

export function deleteCollocation(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Collocations + id, config)
		.then((res) => {
			handleSuccess("deleteCollocation()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteCollocation()", error);
		});
}

export function getCollocation(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Collocations + id, config)
		.then((res) => {
			handleSuccess("getCollocation()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getCollocation()", error);
		});
}

export function addCollocation(collocation) {
	const config = { headers: authHeader() };

	let data = collocation;

	return api
		.post(API_Collocations + "create", data, config)
		.then((res) => {
			handleSuccess("addCollocation()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addCollocation()", error);
		});
}

export function editCollocation(id, collocation) {
	const config = { headers: authHeader() };

	let data = collocation;

	return api
		.put(API_Collocations + id, data, config)
		.then((res) => {
			handleSuccess("editCollocation()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editCollocation()", error);
		});
}

/* API_Collocations */

/* API_ConservationStates */

export function getConservationStates({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_ConservationStates + "?" + params, config)
		.then((res) => {
			handleSuccess("getConservationStates()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getConservationStates()", error);
		});
}

export function deleteConservationState(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_ConservationStates + id, config)
		.then((res) => {
			handleSuccess("deleteConservationState()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteConservationState()", error);
		});
}

export function getConservationState(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_ConservationStates + id, config)
		.then((res) => {
			handleSuccess("getConservationState()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getConservationState()", error);
		});
}

export function addConservationState(conservationState) {
	const config = { headers: authHeader() };

	let data = conservationState;

	return api
		.post(API_ConservationStates + "create", data, config)
		.then((res) => {
			handleSuccess("addConservationState()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addConservationState()", error);
		});
}

export function editConservationState(id, conservationState) {
	const config = { headers: authHeader() };

	let data = conservationState;

	return api
		.put(API_ConservationStates + id, data, config)
		.then((res) => {
			handleSuccess("editConservationState()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editConservationState()", error);
		});
}

/* API_ConservationStates */

/* API_DecorativeThemes */

export function getAllDecorativeThemes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_DecorativeThemes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllDecorativeThemes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllDecorativeThemes()", error);
		});
}

export function getDecorativeThemes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_DecorativeThemes + "?" + params, config)
		.then((res) => {
			handleSuccess("getDecorativeThemes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getDecorativeThemes()", error);
		});
}

export function deleteDecorativeTheme(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_DecorativeThemes + id, config)
		.then((res) => {
			handleSuccess("deleteDecorativeTheme()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteDecorativeTheme()", error);
		});
}

export function getDecorativeTheme(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_DecorativeThemes + id, config)
		.then((res) => {
			handleSuccess("getDecorativeTheme()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getDecorativeTheme()", error);
		});
}

export function addDecorativeTheme(decorativeTheme) {
	const config = { headers: authHeader() };

	let data = decorativeTheme;

	return api
		.post(API_DecorativeThemes + "create", data, config)
		.then((res) => {
			handleSuccess("addDecorativeTheme()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addDecorativeTheme()", error);
		});
}

export function editDecorativeTheme(id, decorativeTheme) {
	const config = { headers: authHeader() };

	let data = decorativeTheme;

	return api
		.put(API_DecorativeThemes + id, data, config)
		.then((res) => {
			handleSuccess("editDecorativeTheme()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editDecorativeTheme()", error);
		});
}

/* API_DecorativeThemes */

/* API_Gaskets */

export function getGaskets({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Gaskets + "?" + params, config)
		.then((res) => {
			handleSuccess("getGaskets()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getGaskets()", error);
		});
}

export function deleteGasket(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Gaskets + id, config)
		.then((res) => {
			handleSuccess("deleteGasket()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteGasket()", error);
		});
}

export function getGasket(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Gaskets + id, config)
		.then((res) => {
			handleSuccess("getGasket()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getGasket()", error);
		});
}

export function addGasket(gasket) {
	const config = { headers: authHeader() };

	let data = gasket;

	return api
		.post(API_Gaskets + "create", data, config)
		.then((res) => {
			handleSuccess("addGasket()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addGasket()", error);
		});
}

export function editGasket(id, gasket) {
	const config = { headers: authHeader() };

	let data = gasket;

	return api
		.put(API_Gaskets + id, data, config)
		.then((res) => {
			handleSuccess("editGasket()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editGasket()", error);
		});
}

/* API_Gaskets */

/* API_Genders */

export function getGenders({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Genders + "?" + params, config)
		.then((res) => {
			handleSuccess("getGenders()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getGenders()", error);
		});
}

export function deleteGender(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Genders + id, config)
		.then((res) => {
			handleSuccess("deleteGender()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteGender()", error);
		});
}

export function getGender(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Genders + id, config)
		.then((res) => {
			handleSuccess("getGender()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getGender()", error);
		});
}

export function addGender(gender) {
	const config = { headers: authHeader() };

	let data = gender;

	return api
		.post(API_Genders + "create", data, config)
		.then((res) => {
			handleSuccess("addGender()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addGender()", error);
		});
}

export function editGender(id, gender) {
	const config = { headers: authHeader() };

	let data = gender;

	return api
		.put(API_Genders + id, data, config)
		.then((res) => {
			handleSuccess("editGender()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editGender()", error);
		});
}

/* API_Genders */

/* API_SpecificModels */

export function getSpecificModels({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_SpecificModels + "?" + params, config)
		.then((res) => {
			handleSuccess("getSpecificModels()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSpecificModels()", error);
		});
}

export function deleteSpecificModel(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_SpecificModels + id, config)
		.then((res) => {
			handleSuccess("deleteSpecificModel()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteSpecificModel()", error);
		});
}

export function getSpecificModel(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_SpecificModels + id, config)
		.then((res) => {
			handleSuccess("getSpecificModel()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getSpecificModel()", error);
		});
}

export function addSpecificModel(specificModel) {
	const config = { headers: authHeader() };

	let data = specificModel;

	return api
		.post(API_SpecificModels + "create", data, config)
		.then((res) => {
			handleSuccess("addSpecificModel()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addSpecificModel()", error);
		});
}

export function editSpecificModel(id, specificModel) {
	const config = { headers: authHeader() };

	let data = specificModel;

	return api
		.put(API_SpecificModels + id, data, config)
		.then((res) => {
			handleSuccess("editSpecificModel()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editSpecificModel()", error);
		});
}

/* API_SpecificModels */

/* API_Years */

export function getYears({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Years + "?" + params, config)
		.then((res) => {
			handleSuccess("getYears()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getYears()", error);
		});
}

export function deleteYear(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Years + id, config)
		.then((res) => {
			handleSuccess("deleteYear()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteYear()", error);
		});
}

export function getYear(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Years + id, config)
		.then((res) => {
			handleSuccess("getYear()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getYear()", error);
		});
}

export function addYear(year) {
	const config = { headers: authHeader() };

	let data = year;

	return api
		.post(API_Years + "create", data, config)
		.then((res) => {
			handleSuccess("addYear()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addYear()", error);
		});
}

export function editYear(id, year) {
	const config = { headers: authHeader() };

	let data = year;

	return api
		.put(API_Years + id, data, config)
		.then((res) => {
			handleSuccess("editYear()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editYear()", error);
		});
}

/* API_Years */

/* API_ModelTypes */

export function getModelTypes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_ModelTypes + "?" + params, config)
		.then((res) => {
			handleSuccess("getModelTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getModelTypes()", error);
		});
}

export function deleteModelTypes(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_ModelTypes + id, config)
		.then((res) => {
			handleSuccess("deleteModelTypes()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteModelTypes()", error);
		});
}

export function getModelType(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_ModelTypes + id, config)
		.then((res) => {
			handleSuccess("getModelType()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getModelType()", error);
		});
}

export function addModelType(modelType) {
	const config = { headers: authHeader() };

	let data = modelType;

	return api
		.post(API_ModelTypes + "create", data, config)
		.then((res) => {
			handleSuccess("addModelType()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addModelType()", error);
		});
}

export function editModelType(id, modelType) {
	const config = { headers: authHeader() };

	let data = modelType;

	return api
		.put(API_ModelTypes + id, data, config)
		.then((res) => {
			handleSuccess("editModelType()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editModelType()", error);
		});
}

/* API_ModelTypes */

/* API_DecorativeMotive */

export function getDecorativeMotives({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_DecorativeMotive + "?" + params, config)
		.then((res) => {
			handleSuccess("getDecorativeMotives()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getDecorativeMotives()", error);
		});
}

export function deleteDecorativeMotive(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_DecorativeMotive + id, config)
		.then((res) => {
			handleSuccess("deleteDecorativeMotive()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteDecorativeMotive()", error);
		});
}

export function getDecorativeMotive(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_DecorativeMotive + id, config)
		.then((res) => {
			handleSuccess("getDecorativeMotive()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getDecorativeMotive()", error);
		});
}

export function addDecorativeMotive(decorativeMotive) {
	const config = { headers: authHeader() };

	let data = decorativeMotive;

	return api
		.post(API_DecorativeMotive + "create", data, config)
		.then((res) => {
			handleSuccess("addDecorativeMotive()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addDecorativeMotive()", error);
		});
}

export function editDecorativeMotive(id, decorativeMotive) {
	const config = { headers: authHeader() };

	let data = decorativeMotive;

	return api
		.put(API_DecorativeMotive + id, data, config)
		.then((res) => {
			handleSuccess("editDecorativeMotive()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editDecorativeMotive()", error);
		});
}

/* API_DecorativeMotive */

/* API_Quantities */

export function getQuantities({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Quantities + "?" + params, config)
		.then((res) => {
			handleSuccess("getQuantities()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getQuantities()", error);
		});
}

export function deleteQuantity(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Quantities + id, config)
		.then((res) => {
			handleSuccess("deleteQuantity()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteQuantity()", error);
		});
}

export function getQuantity(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Quantities + id, config)
		.then((res) => {
			handleSuccess("getQuantity()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getQuantity()", error);
		});
}

export function addQuantity(quantity) {
	const config = { headers: authHeader() };

	let data = quantity;

	return api
		.post(API_Quantities + "create", data, config)
		.then((res) => {
			handleSuccess("addQuantity()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addQuantity()", error);
		});
}

export function editQuantity(id, quantity) {
	const config = { headers: authHeader() };

	let data = quantity;

	return api
		.put(API_Quantities + id, data, config)
		.then((res) => {
			handleSuccess("editQuantity()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editQuantity()", error);
		});
}

/* API_Quantities */

/* API_SubCategories */

export function getSubCategoriesTable({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_SubCategories + "?" + params, config)
		.then((res) => {
			handleSuccess("getSubCategories()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSubCategories()", error);
		});
}

export function deleteSubCategory(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_SubCategories + id, config)
		.then((res) => {
			handleSuccess("deleteSubCategory()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteSubCategory()", error);
		});
}

export function getSubCategory(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_SubCategories + id, config)
		.then((res) => {
			handleSuccess("getSubCategory()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getSubCategory()", error);
		});
}

export function addSubCategory(subCategory) {
	const config = { headers: authHeader() };

	let data = subCategory;

	return api
		.post(API_SubCategories + "create", data, config)
		.then((res) => {
			handleSuccess("addSubCategory()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addSubCategory()", error);
		});
}

export function editSubCategory(id, subCategory) {
	const config = { headers: authHeader() };

	let data = subCategory;

	return api
		.put(API_SubCategories + id, data, config)
		.then((res) => {
			handleSuccess("editSubCategory()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editSubCategory()", error);
		});
}

/* API_SubCategories */

/* API_HeelHeights */

export function getHeelHeights({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_HeelHeights + "?" + params, config)
		.then((res) => {
			handleSuccess("getHeelHeights()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getHeelHeights()", error);
		});
}

export function deleteHeelHeight(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_HeelHeights + id, config)
		.then((res) => {
			handleSuccess("deleteHeelHeight()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteHeelHeight()", error);
		});
}

export function getHeelHeight(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_HeelHeights + id, config)
		.then((res) => {
			handleSuccess("getHeelHeight()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getHeelHeight()", error);
		});
}

export function addHeelHeight(heelHeight) {
	const config = { headers: authHeader() };

	let data = heelHeight;

	return api
		.post(API_HeelHeights + "create", data, config)
		.then((res) => {
			handleSuccess("addHeelHeight()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addHeelHeight()", error);
		});
}

export function editHeelHeight(id, heelHeight) {
	const config = { headers: authHeader() };

	let data = heelHeight;

	return api
		.put(API_HeelHeights + id, data, config)
		.then((res) => {
			handleSuccess("editHeelHeight()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editHeelHeight()", error);
		});
}

/* API_HeelHeights */

/* API_HeelTypes */

export function getHeelTypes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_HeelTypes + "?" + params, config)
		.then((res) => {
			handleSuccess("getHeelTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getHeelTypes()", error);
		});
}

export function deleteHeelType(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_HeelTypes + id, config)
		.then((res) => {
			handleSuccess("deleteHeelType()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteHeelType()", error);
		});
}

export function getHeelType(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_HeelTypes + id, config)
		.then((res) => {
			handleSuccess("getHeelType()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getHeelType()", error);
		});
}

export function addHeelType(heelType) {
	const config = { headers: authHeader() };

	let data = heelType;

	return api
		.post(API_HeelTypes + "create", data, config)
		.then((res) => {
			handleSuccess("addHeelType()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addHeelType()", error);
		});
}

export function editHeelType(id, heelType) {
	const config = { headers: authHeader() };

	let data = heelType;

	return api
		.put(API_HeelTypes + id, data, config)
		.then((res) => {
			handleSuccess("editHeelType()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editHeelType()", error);
		});
}

/* API_HeelTypes */

/* API_MaterialProcessings */

export function getMaterialProcessings({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_MaterialProcessings + "?" + params, config)
		.then((res) => {
			handleSuccess("getMaterialProcessings()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getMaterialProcessings()", error);
		});
}

export function deleteMaterialProcessing(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_MaterialProcessings + id, config)
		.then((res) => {
			handleSuccess("deleteMaterialProcessing()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteMaterialProcessing()", error);
		});
}

export function getMaterialProcessing(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_MaterialProcessings + id, config)
		.then((res) => {
			handleSuccess("getMaterialProcessing()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getMaterialProcessing()", error);
		});
}

export function addMaterialProcessing(materialProcessing) {
	const config = { headers: authHeader() };

	let data = materialProcessing;

	return api
		.post(API_MaterialProcessings + "create", data, config)
		.then((res) => {
			handleSuccess("addMaterialProcessing()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addMaterialProcessing()", error);
		});
}

export function editMaterialProcessing(id, materialProcessing) {
	const config = { headers: authHeader() };

	let data = materialProcessing;

	return api
		.put(API_MaterialProcessings + id, data, config)
		.then((res) => {
			handleSuccess("editMaterialProcessing()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editMaterialProcessing()", error);
		});
}

/* API_MaterialProcessings */

/* API_Materials */

export function getMaterials({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Materials + "?" + params, config)
		.then((res) => {
			handleSuccess("getMaterial()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getMaterial()", error);
		});
}

export function deleteMaterial(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Materials + id, config)
		.then((res) => {
			handleSuccess("deleteMaterial()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteMaterial()", error);
		});
}

export function getMaterial(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Materials + id, config)
		.then((res) => {
			handleSuccess("getMaterial()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getMaterial()", error);
		});
}

export function addMaterial(material) {
	const config = { headers: authHeader() };

	let data = material;

	return api
		.post(API_Materials + "create", data, config)
		.then((res) => {
			handleSuccess("addMaterial()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addMaterial()", error);
		});
}

export function editMaterial(id, material) {
	const config = { headers: authHeader() };

	let data = material;

	return api
		.put(API_Materials + id, data, config)
		.then((res) => {
			handleSuccess("editMaterial()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editMaterial()", error);
		});
}

/* API_Materials */

/* API_MaterialTypes */

export function getMaterialTypes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_MaterialTypes + "?" + params, config)
		.then((res) => {
			handleSuccess("getMaterialTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getMaterialTypes()", error);
		});
}

export function deleteMaterialType(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_MaterialTypes + id, config)
		.then((res) => {
			handleSuccess("deleteMaterialType()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteMaterialType()", error);
		});
}

export function getMaterialType(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_MaterialTypes + id, config)
		.then((res) => {
			handleSuccess("getMaterialType()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getMaterialType()", error);
		});
}

export function addMaterialType(materialType) {
	const config = { headers: authHeader() };

	let data = materialType;

	return api
		.post(API_MaterialTypes + "create", data, config)
		.then((res) => {
			handleSuccess("addMaterialType()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addMaterialType()", error);
		});
}

export function editMaterialType(id, materialType) {
	const config = { headers: authHeader() };

	let data = materialType;

	return api
		.put(API_MaterialTypes + id, data, config)
		.then((res) => {
			handleSuccess("editMaterialType()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editMaterialType()", error);
		});
}

/* API_MaterialTypes */

/* API_Sizes */

export function getSizes({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Sizes + "?" + params, config)
		.then((res) => {
			handleSuccess("getSizes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getSizes()", error);
		});
}

export function deleteSize(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Sizes + id, config)
		.then((res) => {
			handleSuccess("deleteSize()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteSize()", error);
		});
}

export function getSize(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Sizes + id, config)
		.then((res) => {
			handleSuccess("getSize()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getSize()", error);
		});
}

export function addSize(size) {
	const config = { headers: authHeader() };

	let data = size;

	return api
		.post(API_Sizes + "create", data, config)
		.then((res) => {
			handleSuccess("addSize()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addSize()", error);
		});
}

export function editSize(id, size) {
	const config = { headers: authHeader() };

	let data = size;

	return api
		.put(API_Sizes + id, data, config)
		.then((res) => {
			handleSuccess("editSize()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editSize()", error);
		});
}

/* API_Sizes */

/* API_Colors */

export function getColors({
	sortBy = "label",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Colors + "?" + params, config)
		.then((res) => {
			handleSuccess("getColors()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getColors()", error);
		});
}

export function deleteColor(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Colors + id, config)
		.then((res) => {
			handleSuccess("deleteColor()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteColor()", error);
		});
}

export function getColor(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Colors + id, config)
		.then((res) => {
			handleSuccess("getColor()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getColor()", error);
		});
}

export function addColor(color) {
	const config = { headers: authHeader() };

	let data = color;

	return api
		.post(API_Colors + "create", data, config)
		.then((res) => {
			handleSuccess("addColor()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addColor()", error);
		});
}

export function editColor(id, color) {
	const config = { headers: authHeader() };

	let data = color;

	return api
		.put(API_Colors + id, data, config)
		.then((res) => {
			handleSuccess("editColor()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editColor()", error);
		});
}

/* API_Colors */
