export function authHeader() {
	const userJSON = localStorage.getItem("user");
	const user = JSON.parse(userJSON || "{}");

	if (user && user.token) {
		return { Authorization: "Bearer " + user.token };
	} else {
		window.location.replace("/login");
		return {};
	}
}
