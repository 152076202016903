import { useState } from "react";
import AuthService from "../utils/auth.service";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { NavLink } from "react-router-dom";

import Input from "./common/input";
import Button from "./common/button";

import Navbar from "./navbar";

interface Props {
	setToken: any;
	setSignup: any;
	setForgotPassword: any;
}

const Login = ({ setToken, setSignup, setForgotPassword }: Props) => {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [message, setMessage] = useState("");
	const history = useHistory();

	const handleLogin = async (e: any) => {
		AuthService.login(email, password).then(
			(res) => {
				setToken(res.token);
				history.push("/home");
			},
			(error) => {
				setMessage("Login failed!");
			}
		);
	};

	const handleKeyDown = (event: any) => {
		if (event.key === "Enter") {
			handleLogin(event);
		}
	};

	return (
		<div className="login">
			<Navbar />
			<div className="login-container">
				<img
					src={process.env.PUBLIC_URL + "/slides/b_17.jpg"}
					id="login-background"
				/>
				<Row>
					<Col sm={8}></Col>
					<Col sm={4} className="login-form-column">
						<div className="login-form">
							<span className="text-extra-large">Login</span>
							{message !== "" && <p className="login-error">{message}</p>}
							<Input
								name="username"
								placeholder="Email"
								onChange={(evt: any) => setEmail(evt.target.value)}
								onKeyDown={handleKeyDown}
								icon="mail"
							/>
							<Input
								name="password"
								placeholder="Password"
								type="password"
								onChange={(evt: any) => setPassword(evt.target.value)}
								onKeyDown={handleKeyDown}
								icon="key"
							/>

							<NavLink
								className=""
								to="/forgotPassword"
								onClick={() => setForgotPassword(true)}
							>
								<span className="text-small">Forgot password?</span>
							</NavLink>

							<Button
								name="confirm"
								label="LOGIN"
								classes="btn-empty btn-full"
								onClick={handleLogin}
							/>

							<span className="text-small">
								If you don't have an account, please{" "}
								<NavLink
									className="bold"
									to="/signup"
									onClick={() => setSignup(true)}
								>
									SIGNUP
								</NavLink>
							</span>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Login;
