import * as React from "react";
import Table from "./common/table";
import { NavLink } from "react-router-dom";
import translations from "../translations.json";
var moment = require("moment");

interface Props {
	users: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
}

interface State {}

class UsersTable extends React.Component<Props, State> {
	columns = [
		{
			path: "firstName",
			label: localStorage.getItem("language") === "it" ? "UTENTE" : "USER",
			content: (user: any) => {
				return user.firstName + " " + user.lastName;
			},
		},
		{
			path: "companyString",
			label: localStorage.getItem("language") === "it" ? "AZIENDA" : "COMPANY",
		},
		{
			path: "departmentString",
			label:
				localStorage.getItem("language") === "it"
					? "DIPARTIMENTO"
					: "DEPARTMENT",
		},
		{
			path: "role",
			label:
				localStorage.getItem("language") === "it"
					? "RUOLO/PROFILO"
					: "ROLE/PROFILE",
			content: (user: any) => {
				switch (user.role) {
					case 0:
						return "Admin";
					case 1:
						return "Top User";
					case 2:
						return "Guest User";
					case 3:
						return "Archive Manager";
					case 4:
						return "Archivist";
					default:
						return "Top User";
				}
			},
		},
		{
			path: "status",
			label:
				localStorage.getItem("language") === "it"
					? "STATO RICHIESTA"
					: "STATUS",
			content: (user: any) => {
				if (user.verified)
					return (
						<span className="badge badge-success">
							{localStorage.getItem("language") === "it"
								? "Approvato"
								: "Approved"}
						</span>
					);
				else
					return (
						<span className="badge badge-danger">
							{localStorage.getItem("language") === "it"
								? "Da approvare"
								: "To be approved"}
						</span>
					);
			},
		},
		{
			path: "Access.updatedAt",
			label:
				localStorage.getItem("language") === "it"
					? "ULTIMO ACCESSO"
					: "LAST ACCESS",
			content: (user: any) => {
				if (user.Access) {
					let date = moment.parseZone(user.Access?.updatedAt).local().format();
					let newDate = moment(date).format("DD/MM/YYYY HH:mm");

					return newDate;
				}

				return "";
			},
		},
		{
			path: "Access.loginNumber",
			label:
				localStorage.getItem("language") === "it"
					? "NUMERO ACCESSI"
					: "NUMBER OF ACCES",
			content: (user: any) => {
				return user.Access?.loginNumber;
			},
		},
		{
			key: "delete",
			content: (user: any) => (
				<div className="iconDiv">
					<span
						onClick={() => this.props.onEdit(user.id)}
						className="iconBtn2 edit"
					>
						<img src={process.env.PUBLIC_URL + "/imgs/edit.svg"} alt="" />
					</span>
					<span
						onClick={() => this.props.onDelete(user.id)}
						className="iconBtn2 delete"
					>
						<img
							src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
							alt=""
						/>
					</span>
				</div>
			),
		},
	];

	render() {
		const { users, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={users}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default UsersTable;
