import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import { getMaterialTypes, deleteMaterialType } from "../../utils/apis/all";
import CustomTable from "./table";
import Pagination from "../pagination";
import Button from "../common/button";
import Modal from "react-modal";
import EditModal from "./editModal";
import { Store } from "react-notifications-component";

import translations from "../../translations.json";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		minWidth: "520px",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const Main = () => {
	const { idActiveFilter, idActiveTag }: any = useParams();

	const getRows = getMaterialTypes;
	const deleteRow = deleteMaterialType;

	const [modalEditIsOpen, setEditIsOpen] = useState(false);
	const [editId, setEditId] = useState(0);

	const [rows, setRows] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortColumn, setSortColumn] = useState({
		path: "label",
		order: "asc",
	});
	const [total, setTotal] = useState(0);

	const [language, setLanguage]: any = useState("en");
	useEffect(() => {
		setLanguage(localStorage.getItem("language"));
	});

	useEffect(() => {
		getRows({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
		}).then((res: any) => {
			setRows(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}, [idActiveFilter]);

	function handlePageChange(page: any) {
		setCurrentPage(page);
		getRows({
			page: page - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
		}).then((res: any) => {
			setRows(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}

	function handleSort(sort: any) {
		getRows({
			page: 0,
			perPage: pageSize,
			sortBy: sort["path"],
			sortDirection: sort["order"],
		}).then((res: any) => {
			setRows(res[0]);
			setTotal(res[1]);
			setCurrentPage(1);
		});

		setSortColumn(sort);
	}

	function updateRows() {
		getRows({
			page: currentPage - 1,
			perPage: pageSize,
			sortBy: sortColumn["path"],
			sortDirection: sortColumn["order"],
		}).then((res: any) => {
			setRows(res[0]);
			setTotal(res[1]);
			//console.log(res);
		});
	}

	function handleEdit(id: any) {
		setEditId(id);
		setEditIsOpen(true);
	}

	function handleDelete(id: any) {
		if (window.confirm("Eliminare l'entità selezionata?")) {
			deleteRow(id).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Entità eliminata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});

				updateRows();
			});
		}
	}

	let tableInfo1 = pageSize * (currentPage - 1) + 1;
	let tableInfo2 = currentPage * pageSize;
	if (tableInfo2 > total) tableInfo2 = total;

	return (
		<div>
			<Row>
				<Col sm={8}></Col>
				<Col sm={4} style={{ textAlign: "right" }}>
					<Button
						name="confirm"
						label={
							translations["addModelType"][
								language as keyof typeof translations["addModelType"]
							]
						}
						classes="btn-empty"
						onClick={() => {
							setEditId(0);
							setEditIsOpen(true);
						}}
					/>
				</Col>
			</Row>
			<CustomTable
				rows={rows}
				sortColumn={sortColumn}
				onEdit={handleEdit}
				onDelete={handleDelete}
				onSort={handleSort}
			/>
			<div className="tableControls">
				<p className="tableInfo">
					<b>{tableInfo1}</b> to <b>{tableInfo2}</b> of <b>{total}</b>
				</p>
				<Pagination
					itemsCount={total}
					pageSize={pageSize}
					currentPage={currentPage}
					onPageChange={handlePageChange}
				/>
			</div>
			<Modal
				isOpen={modalEditIsOpen}
				onRequestClose={() => setEditIsOpen(false)}
				ariaHideApp={false}
				style={customStyles}
			>
				<div style={{ height: 40, textAlign: "right" }}>
					<img
						src={process.env.PUBLIC_URL + "/imgs/close.png"}
						alt=""
						style={{ width: 20, cursor: "pointer" }}
						onClick={() => setEditIsOpen(false)}
					/>
				</div>
				<EditModal
					editId={editId}
					onClose={() => {
						setEditIsOpen(false);
						updateRows();
					}}
				/>
			</Modal>
		</div>
	);
};

export default Main;
