import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import defaultData from "../data.json";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import Product from "./product";
import { Store } from "react-notifications-component";

import {
	getFolder,
	removeProductFromFolder,
	getLoansNotSentVerification,
	addProductToLoan,
	getUsersFromCompany,
	shareFolder,
	deleteFolder,
} from "../utils/apis/all";

import MainFilter from "./filters/mainFilter";
import Breadcrumbs from "./breadcrumbs";
import ProductModal from "./productModal";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "0px",
		padding: "16px",
		maxWidth: "80%",
		maxHeight: "800px",
	},
	overlay: {
		backgroundColor: "rgba(51, 51, 51, 0.9)",
	},
};

const FolderPagePrint = ({ setComponentRef, propsFolderId }: any) => {
	const history = useHistory();

	let productRef: any;

	const { folderId }: any = useParams();

	const [filters] = useState(defaultData.filters);
	const [modalViewIsOpen, setViewIsOpen] = useState(false);

	const [loans, setLoans] = useState([]);

	const [selectedProductId, setSelectedProductId] = useState(0);
	const [selectedViewProduct, setSelectedViewProduct] = useState(0);
	const [selectedDeleteProduct, setSelectedDeleteProduct] = useState(0);

	const [generalHandlerStatus, setGeneralHandlerStatus] = useState(false);

	const [users, setUsers] = useState([]);
	let loanRef: any;
	let shareRef: any;

	const [folder, setFolder] = useState({
		id: 0,
		title: "",
		notes: "",
		createdAt: "",
		Products: [],
	});

	const [crumbs, setCrumbs] = useState([
		{ name: "HOME", path: "/home" },
		{ name: "MY FOLDERS", path: "/myFolders" },
	]);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		let newFolderId = folderId;
		if (!folderId) {
			newFolderId = propsFolderId;
		}

		getFolder({ folderId: newFolderId }).then((res: any) => {
			setFolder(res);

			const breadcrumbs: any = [
				{ name: "HOME", path: "/" },
				{ name: "MY FOLDERS", path: "/myFolders" },
			];
			const folderCrumb = {
				name: res.title.toUpperCase(),
				path: "",
			};
			breadcrumbs.push(folderCrumb);
			setCrumbs(breadcrumbs);
		});

		getLoansNotSentVerification({}).then((res: any) => {
			setLoans(res[0]);
		});

		getUsersFromCompany({}).then((res: any) => {
			res = res.map((user: any) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			});
			setUsers(res);
		});

		setComponentRef(productRef);
	}, [folderId]);

	const setActiveFilter = (activeFilter: any) => {
		history.push("/home/" + activeFilter);
	};

	const handleOpenModal = (productId: number) => {
		setSelectedViewProduct(productId);
		setViewIsOpen(true);
	};

	const handleShare = (id: any) => {
		shareFolder({ folderId: folderId, userId: id }).then((res: any) => {
			//console.log(res);
			ReactTooltip.hide(shareRef);
			Store.addNotification({
				title: "Success",
				message: "Folder shared successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});
		});
	};

	function handleDelete() {
		if (window.confirm("Eliminare la folder selezionata?")) {
			deleteFolder(folderId).then((res: any) => {
				Store.addNotification({
					title: "Successo!",
					message: "Entità eliminata correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
				history.push("/myFolders");
			});
		}
	}

	const deleteHandler = (
		deleteRef: any,
		productId: number,
		status: boolean
	) => {
		//console.log(status);
		if (status) {
			ReactTooltip.hide(deleteRef);
			setSelectedDeleteProduct(0);
		} else {
			ReactTooltip.show(deleteRef);
			setSelectedDeleteProduct(productId);
		}
	};

	const loanHandler = (
		loanRef: any,
		folderRef: any,
		productId: number,
		status: boolean
	) => {
		if (status) {
			ReactTooltip.hide(loanRef);
			setSelectedProductId(0);
		} else {
			ReactTooltip.show(loanRef);
			setSelectedProductId(productId);
		}
	};

	const removeProduct = (status: boolean) => {
		if (status) {
			removeProductFromFolder(folderId, selectedDeleteProduct).then(
				(res: any) => {
					if (res === 200) {
						Store.addNotification({
							title: "Successo!",
							message: "L'item è stato rimosso dalla cartella correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});

						setTimeout(function () {
							history.go(0);
						}, 1000);
					} else {
						Store.addNotification({
							title: "Errore!",
							message:
								"C'è stato un errore durante la rimozione dell'item dalla cartella",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}

					setGeneralHandlerStatus(!generalHandlerStatus);
				}
			);
		} else {
			setGeneralHandlerStatus(!generalHandlerStatus);
		}
	};

	const addToLoan = (loanId: number) => {
		addProductToLoan(loanId, selectedProductId).then((res: any) => {
			if (res === 200) {
				Store.addNotification({
					title: "Successo!",
					message:
						"L'item è stato aggiunto alla richiesta di prestito correttamente",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			} else {
				Store.addNotification({
					title: "Errore!",
					message:
						"C'è stato un errore durante l'aggiunta dell'item al prestito",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		});

		setGeneralHandlerStatus(!generalHandlerStatus);
	};

	return (
		<div
			ref={(ref: any) => (productRef = ref)}
			id="productToPrint"
			className="pagebreak productToPrint"
		>
			<Container style={{ marginTop: 30 }}>
				<Row
					className=""
					style={{ borderBottom: "1px solid black", paddingBottom: 8 }}
				>
					<Col sm={8}>
						<span
							style={{
								fontSize: "var(--text-medium)",
								fontWeight: 700,
								textTransform: "uppercase",
							}}
						>
							{folder.title}
							{" / "}
							{folder.createdAt &&
								new Date(folder.createdAt).toISOString().substring(0, 10)}
						</span>
					</Col>
				</Row>
				<Row style={{ marginTop: 15 }}>
					<Col sm={12}>{folder.notes}</Col>
				</Row>
				<Row style={{ marginTop: 50, marginBottom: 100 }}>
					{folder.Products.map((product: any) => {
						return (
							<Col key={product.id} sm={3} className="product-container">
								<Product
									product={product}
									hideActions={true}
									deleteHandler={deleteHandler}
									deleteText={"Remove from Folder"}
									loans={loans}
									folders={[]}
									loanHandler={loanHandler}
									folderHandler={() => {}}
									generalHandler={generalHandlerStatus}
								/>
							</Col>
						);
					})}
				</Row>
				<Modal
					isOpen={modalViewIsOpen}
					onRequestClose={() => setViewIsOpen(false)}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<div style={{ height: 40 }}></div>
					<ProductModal productId={selectedViewProduct} />
					<div style={{ height: 40 }}></div>
				</Modal>
				<ReactTooltip
					id="delete"
					aria-haspopup="true"
					place="top"
					type="light"
					effect="solid"
					className="tooltip2"
					clickable={true}
				>
					<div style={{ textAlign: "center" }}>
						<span
							style={{ display: "block", fontWeight: 700, marginBottom: 10 }}
						>
							Are you sure?
						</span>
						<div style={{ display: "flex" }}>
							<span
								style={{ flex: 1, cursor: "pointer" }}
								onClick={() => removeProduct(true)}
							>
								Yes
							</span>
							<span
								style={{ flex: 1, cursor: "pointer" }}
								onClick={() => removeProduct(false)}
							>
								No
							</span>
						</div>
					</div>
				</ReactTooltip>
				<ReactTooltip
					id="loans"
					aria-haspopup="true"
					place="bottom"
					type="light"
					effect="solid"
					className="tooltip"
					clickable={true}
				>
					<ul className="tooltip-list">
						{loans.map((loan: any) => {
							return (
								<li key={loan.id} onClick={() => addToLoan(loan.id)}>
									{loan.title}
								</li>
							);
						})}
					</ul>
				</ReactTooltip>
				<ReactTooltip
					id="shares"
					aria-haspopup="true"
					place="bottom"
					type="light"
					effect="solid"
					className="tooltip"
					clickable={true}
				>
					<ul className="tooltip-list">
						{users.map((user: any) => {
							return (
								<li key={user.id} onClick={() => handleShare(user.id)}>
									{user.name}
								</li>
							);
						})}
					</ul>
				</ReactTooltip>
			</Container>
		</div>
	);
};

export default FolderPagePrint;
