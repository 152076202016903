import { useState, useEffect } from "react";

const Tag = ({ label, active, onClick, uppercase }: any) => {
	useEffect(() => {
		////console.log("Home rendered successfully");
	});

	let classes = "tag";
	if (active) classes += " active";
	if (uppercase) classes += " uppercase";

	return (
		<div className={classes} onClick={onClick}>
			<span className="text-small">{label}</span>
		</div>
	);
};

export default Tag;
