import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "./common/input";
import Button from "./common/button";
import ReactTooltip from "react-tooltip";

import { getFolder } from "../utils/apis/all";

const FolderEditModal = ({ folderId, onSave }: any) => {
	const history = useHistory();

	useEffect(() => {
		if (folderId != 0) {
			getFolder({ folderId }).then((res: any) => {
				setFolder(res);
			});
		}
	}, [folderId]);

	const [folder, setFolder] = useState({
		title: "",
		notes: "",
	});

	const handleInput = (e: any) => {
		setFolder((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{folderId ? "EDIT FOLDER" : "CREATE A FOLDER"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Title
							</span>
							<Input
								name="title"
								placeholder=""
								onChange={handleInput}
								value={folder.title}
							/>
						</div>
						<div style={{ marginTop: 15 }}>
							<textarea
								onChange={handleInput}
								name="notes"
								style={{
									padding: 12,
									backgroundColor: "var(--grey)",
									border: "none",
									width: "100%",
									borderRadius: 12,
									resize: "vertical",
									fontSize: "var(--text-medium)",
								}}
								rows={5}
								value={folder.notes}
							></textarea>
						</div>
						<div style={{ marginTop: 15 }}>
							<Button
								name="confirm"
								label="SAVE"
								classes="btn-empty btn-full"
								onClick={() => onSave(folderId, folder)}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default FolderEditModal;
